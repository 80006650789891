import styled from 'styled-components'

export const ScrollToSeeMoreStyle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 5;
  transition: all .3s ease-out;

  &.scroll-arrow-hide {
    opacity: 0;
    visibility: hidden;
  }

  .scroll-arrow {
    font-size: 1.5rem;
    display: flex;
    width: 40px;
    height: 80px;
    border-radius: 150px 0 0 150px;
    justify-content: flex-start;
    align-items: center;
    color: ${({ theme }) => theme.scrollToSeeMoreArrowColor};
    background-color: ${({ theme }) => theme.scrollToSeeMoreArrowBkg};
    padding-left: 1rem;
  }
`
