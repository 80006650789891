import React from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserAvatarStyle } from './style'
import { selectProfilePicture } from '../../redux/user/user.selectors'

const UserAvatar = () => {
  const profilePicture = useSelector(selectProfilePicture)

  return (
    <UserAvatarStyle>
      {profilePicture ?
        <div className="user-avatar" style={{ backgroundImage: `url(${profilePicture})` }}></div>
        :
        <span className="default-user">
          <FontAwesomeIcon icon={['fas', 'user']} />
        </span>
      }
    </UserAvatarStyle>
  )
}

export default UserAvatar
