import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoginStepStyle from './style'
import { resetLoginForm } from '../../redux/user/user.actions'
import { selectSignUpStep } from '../../redux/user/user.selectors'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'

const LoginStep = ({ children }) => {
  const dispatch = useDispatch()
  const signUpStep = useSelector(selectSignUpStep)

  return (
    <LoginStepStyle>
      <header>
        <Logo className="ab-logo" />
        {signUpStep > 1 &&
          <Button className="close-btn" outline color="link" onClick={() => dispatch(resetLoginForm())} title="Torna alla schermata di login">
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        }
      </header>
      <main>
        {children}
      </main>
    </LoginStepStyle>
  )
}

export default LoginStep
