import React from 'react'
import { useSelector } from 'react-redux'
import { TransitionGroup, CSSTransition } from "react-transition-group"

import LoginPageStyle from './style'
import UserLoginSteps from './loginSteps'
import { selectSignUpStep, selectIsFetching } from '../../redux/user/user.selectors'
import SignInStep from '../../components/login/SignInStep/SignInStep.component'
import UserDataStep from '../../components/login/UserDataStep/UserDataStep.component'
import UserPasswordStep from '../../components/login/UserPasswordStep/UserPasswordStep.component'
import UserClientCodeStep from '../../components/login/UserClientCodeStep/UserClientCodeStep.component'
import UserOtpTypeStep from '../../components/login/UserOtpTypeStep/UserOtpTypeStep.component'
import UserOtpSignStep from '../../components/login/UserOtpSignStep/UserOtpSignStep.component'
import WithSpinner from '../../components/WithSpinner/WithSpinner.component'
import UserModal from '../../components/UserModal/UserModal.component'

const SignInStepWithSpinner = WithSpinner(SignInStep)

const LoginPage = () => {
  const signUpStep = useSelector(selectSignUpStep)
  const isFetching = useSelector(selectIsFetching)

  return (
    <LoginPageStyle>
      <TransitionGroup component={null}>
        {signUpStep === UserLoginSteps.SIGN_IN_STEP &&
          <CSSTransition classNames="step-content" timeout={500}>
            <SignInStepWithSpinner isLoading={isFetching} />
          </CSSTransition>
        }
        {signUpStep === UserLoginSteps.USER_PASSWORD_STEP &&
          <CSSTransition classNames="step-content" timeout={500}>
            <UserPasswordStep />
          </CSSTransition>
        }
        {signUpStep === UserLoginSteps.USER_DATA_STEP &&
          <CSSTransition classNames="step-content" timeout={500}>
            <UserDataStep />
          </CSSTransition>
        }
        {signUpStep === UserLoginSteps.USER_CLIENT_CODE &&
          <CSSTransition classNames="step-content" timeout={500}>
            <UserClientCodeStep />
          </CSSTransition>
        }
        {signUpStep === UserLoginSteps.OTP_TYPE_STEP &&
          <CSSTransition classNames="step-content" timeout={500}>
            <UserOtpTypeStep />
          </CSSTransition>
        }
        {signUpStep === UserLoginSteps.USER_OTP_SIGN_STEP &&
          <CSSTransition classNames="step-content" timeout={500}>
            <UserOtpSignStep />
          </CSSTransition>
        }
      </TransitionGroup>
      <UserModal />
    </LoginPageStyle>
  )
}

export default LoginPage
