import styled from 'styled-components'

export const BrandLogoStyle = styled.div`
  padding: 1rem;

  a {
    color: inherit;
    transition: 1.5s ease;
    display: block;

    &:hover {
      transform: scale(.9);
    }
  }

  .logo {
    width: 160px;
    display: block;
    max-width: 100%;
  }
`
