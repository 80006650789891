import styled from 'styled-components'
import { FormGroup } from 'reactstrap'

const CustomNumberFieldStyle = styled(FormGroup)`
  position: relative;

  .increment-btn {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: .15rem;
    color: #fff;
    font-size: 1.5rem;

    &.add {
      bottom: 100%;
    }
    &.subtract {
      top: 100%;
    }

  }
`

export default CustomNumberFieldStyle