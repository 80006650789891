import React from 'react'

import CustomTooltipStyle from './style'

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    let totalConsumption = 0
    let measuringUnit = ''

    return (
      <CustomTooltipStyle>
        <p className="label">
          {label}
        </p>
        {payload?.map((item, i) => {
          totalConsumption += item.value
          measuringUnit = item.payload.unit
          return (
            <div className="item-data" key={i}>
              <p className="item">
                <span className="data-label">{item.dataKey}</span>{' '}
                <span className="data-value">{item.value}{item.payload.unit}</span>
              </p>
            </div>
          )
        })}
        {payload?.length > 1 &&
          <p className="item">
            <span className="data-label">Consumo totale:</span>{' '}
            <span className="data-value">{totalConsumption.toFixed(2)} {measuringUnit}</span>
          </p>
        }
      </CustomTooltipStyle>
    )
  }

  return null
}

export default CustomTooltip
