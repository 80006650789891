import React, { useState, useEffect } from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'

import EnergyChartViewStyle from './style'
import { buildChartData } from './utils'
import CustomTooltip from '../CustomTooltip/CustomTooltip.component'

const EnergyChartView = ({ consumptionData }) => {
  //Chart width state
  const [ data, setData ] = useState([])
  useEffect(() => {
    setData(buildChartData(consumptionData))
  }, [consumptionData])

  return (
    <EnergyChartViewStyle>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="F1" fill="#16845F" />
          <Bar dataKey="F2" fill="#3F9F4A" />
          <Bar dataKey="F3" fill="#67BA34" />
        </BarChart>
      </ResponsiveContainer>
    </EnergyChartViewStyle>
  )
}

export default EnergyChartView
