import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { withRouter } from '../../AppRouter/utils'
import ResetPasswordStyle from './style'
import { selectResetPasswordDone } from '../../../redux/user/user.selectors'
import LoginStep from '../../LoginStep/LoginStep.component'
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm.component'

const FirebaseAuthPage = ({ router: { navigate } }) => {
  const resetPasswordDone = useSelector(selectResetPasswordDone)

  return (
    <LoginStep>
      <ResetPasswordStyle>
        <h1 className="step-title">
          Reset password
        </h1>
        {resetPasswordDone ? (
          <div className="reset-feedback">
            <h5 className="title">Ora puoi tornare alla home page ed effettuare il log in con la nuova password.</h5>
          </div>
        ) : (
          <ResetPasswordForm />
        )}
        <Button
          className="ab-button outline back-to-login"
          outline
          onClick={() => navigate("/")}
        >
          Torna al login
        </Button>
      </ResetPasswordStyle>
    </LoginStep>
  )
}

export default withRouter(FirebaseAuthPage)
