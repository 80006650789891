import styled from 'styled-components'

export const WithSpinnerStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  &.full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => theme.loginPageBkg};
    flex-direction: column;

    svg {
      max-width: 160px;
      margin-bottom: 1rem;
      height: auto;
    }
  }

  .loading-icon {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;

    svg {
      path:nth-child(1) {
        fill: ${({ theme }) => theme.loaderOne};
      }
      path:nth-child(2) {
        fill: ${({ theme }) => theme.loaderTwo};
      }
      path:nth-child(3) {
        fill: ${({ theme }) => theme.loaderThree};
      }
      path:nth-child(4) {
        fill: ${({ theme }) => theme.loaderThree};
      }
    }
  }
`
