import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback } from 'reactstrap'

import { UserOtpTypeFormStyle } from './style'
import { required } from './utils'
import { selectIsSubmitting, selectOtpMethods, selectSignUpClientCode } from '../../../redux/user/user.selectors'
import { sendOtpRequestStart } from '../../../redux/user/user.actions'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

const UserOtpTypeForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const otpMethods = useSelector(selectOtpMethods)
  const signUpClientCode = useSelector(selectSignUpClientCode)
  const onSubmit = (values) => dispatch(sendOtpRequestStart(values))

  return(
    <Form mutators={{updateFieldValue}} onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="clientCode"
            name="clientCode"
            type="hidden"
            defaultValue={signUpClientCode}
          />
          <CustomFormField
            id="signUpMethodDescription"
            name="signUpMethodDescription"
            type="hidden"
          />
          {otpMethods.map(({ type, localizedType, message, maskedValue }, i) => (
            <FormGroup key={i}>
              <Field
                type="radio"
                validate={required}
                name="type"
                value={type}
                parse={value => parseInt(value)}
              >
                {({ input, meta: { error, touched } }) => (
                  <>
                    <Input
                      invalid={error && touched ? true : false} {...input}
                      onClick={() => form.mutators.updateFieldValue('signUpMethodDescription', localizedType)}
                    />
                    <UserOtpTypeFormStyle>
                      <h3 className="title">{localizedType}</h3>
                      <p className="description">{message}</p>
                    </UserOtpTypeFormStyle>
                    <FormFeedback>{error}</FormFeedback>
                  </>
                )}
              </Field>
            </FormGroup>
          ))}
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            buttonLabel="Continua"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Form>
  )
}

export default UserOtpTypeForm
