import styled from 'styled-components'

const CustomerAreaPageStyle = styled.div`
  .navigation-menu-container {
    overflow: hidden;
    padding: 0;
  }

  .content-container {
    height: 100vh;
    overflow-y: auto;

    @media (max-width: 768px) {
      height: auto;
      min-height: 250px;
    }
  }
`

export default CustomerAreaPageStyle