import SupplyPointsActionTypes from './supplyPoints.types'

export const toggleUtilityModalVisibility = () => ({
  type: SupplyPointsActionTypes.TOGGLE_SUPPLY_POINTS_UTILITY_MODAL_VISIBILITY
})

export const fetchSupplyPointsStart = customerCodes => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_START,
  payload: customerCodes
})

export const fetchSupplyPointsSuccess = data => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const fetchSupplyPointsFailure = error => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const fetchSupplyPointDetailStart = customerCodes => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_START,
  payload: customerCodes
})

export const fetchSupplyPointDetailSuccess = data => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_SUCCESS,
  payload: data
})

export const fetchSupplyPointDetailFailure = error => ({
  type: SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_FAILURE,
  payload: error
})

export const updateSupplyPointAliasStart = data => ({
  type: SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_START,
  payload: data
})

export const updateSupplyPointAliasSuccess = data => ({
  type: SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_SUCCESS,
  payload: data
})

export const updateSupplyPointAliasFailure = error => ({
  type: SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_FAILURE,
  payload: error
})
