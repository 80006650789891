import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'

import { checkUserEmailProviderStart } from '../../../redux/user/user.actions'
import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import { emailCheck } from './utils'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import CustomFormField from '../../CustomFormField/CustomFormField.component'

const EmailSignInForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const onSubmit = values => dispatch(checkUserEmailProviderStart(values))

  return(
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="email"
            name="email"
            type="email"
            className="custom-form-field"
            placeholder="Email"
            validate={emailCheck}
            autoComplete="off"
            focusOnMount={true}
          />
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
            buttonLabel="Continua"
          />
        </form>
      )}
    </Form>
  )
}

export default EmailSignInForm
