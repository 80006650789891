import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'

import StyledExtendedAutocompleteFormField from './style'

const ExtendedAutocompleteFormField = ({ children, ...props }) => {
  return (
    <FormGroup>
      <Field {...props}>
        {({ isMulti = false, isClearable = true, fetchFunction, fieldLabel, fieldDescription, noValidationMessage,
          input: { name, value, onChange, onBlur }, meta: { error, touched }, options, className, ...input }) => (
          <>
            {fieldLabel &&
              <Label for={name}>{fieldLabel}</Label>
            }
            <StyledExtendedAutocompleteFormField
              classNamePrefix="react-autocomplete"
              className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
              value={value}
              isMulti={isMulti}
              isClearable={isClearable}
              onChange={value => {
                onChange(value)
              }}
              onBlur={onBlur}
              {...input}
            />
            <FormFeedback className={classnames({ 'd-flex': error && touched })}>
              {error}
            </FormFeedback>
          </>
        )}
      </Field>
    </FormGroup>
  )
}

export default ExtendedAutocompleteFormField
