import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import CustomerAreaPageStyle from './style'
import MainContentWrapper from '../../components/MainContentWrapper/MainContentWrapper.component'
import VerticalMenu from '../../components/customer-area/VerticalMenu/VerticalMenu.component'
import NavigationBar from '../../components/customer-area/NavigationBar/NavigationBar.component'
import SupplyPointsOverviewPage from '../SupplyPointsOverviewPage/SupplyPointsOverviewPage.component'
import SupplyPointDetailPage from '../SupplyPointDetailPage/SupplyPointDetailPage.component'
import UserProfilePage from '../UserProfilePage/UserProfilePage.component'
import BillsPage from '../BillsPage/BillsPage.component'
import CadastralDataPage from '../CadastralDataPage/CadastralDataPage.component'
import SelfMeterReadingPage from '../SelfMeterReadingPage/SelfMeterReadingPage.component'
import UserModal from '../../components/UserModal/UserModal.component'
import SupplyPointsModal from '../../components/customer-area/SupplyPointsModal/SupplyPointsModal.component'
import BillsModal from '../../components/customer-area/BillsModal/BillsModal.component'
import SelfMeterReadModal from '../../components/customer-area/SelfMeterReadModal/SelfMeterReadModal.component'
import CadastralDataModal from '../../components/customer-area/CadastralData/CadastralDataModal/CadastralDataModal.component'
import { selectReservedAreaEnabled } from '../../redux/user/user.selectors'

const CustomerAreaPage = () => {
  const reservedAreaEnabled = useSelector(selectReservedAreaEnabled)

  return (
    <CustomerAreaPageStyle>
      {reservedAreaEnabled === false ? (
        <Navigate to="/" />
      ) : (
        <MainContentWrapper>
          <Row>
            <Col md={3} lg={2} className="navigation-menu-container">
              <VerticalMenu />
            </Col>
            <Col md={9} lg={10} className="content-container">
              <NavigationBar />
              <Routes>
                <Route path="punti-fornitura">
                  <Route index element={<SupplyPointsOverviewPage />} />
                  <Route
                    path=":type/:supplyPointId"
                    element={<SupplyPointDetailPage />}
                  />
                </Route>

                <Route
                  path="profilo"
                  element={<UserProfilePage />}
                />

                <Route
                  path="autoletture"
                  element={<SelfMeterReadingPage />}
                />

                <Route
                  path="bollette"
                  element={<BillsPage />} 
                />

                <Route 
                  path="dati-catastali"
                  element={<CadastralDataPage />}
                />

                <Route 
                  path="*"
                  element={<Navigate to="/no-match" />} 
                />
              </Routes>
            </Col>
          </Row>
        </MainContentWrapper>
      )}
      <UserModal />
      <SupplyPointsModal />
      <BillsModal />
      <SelfMeterReadModal />
      <CadastralDataModal />
    </CustomerAreaPageStyle>
  )
}

export default CustomerAreaPage
