import styled from 'styled-components'

const SelfMeterReadOverviewStyle = styled.div`
  .customer-code {
    font-weight: 700;
    color: ${({ theme }) => theme.selfMeterReadCustomerCodeColor};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .self-meter-list {
    display: flex;
    width: 100%;
    position: relative;
    overflow-x: auto;
    padding: 0 .75rem;
    padding-bottom: .5rem;
    margin-left: -.75rem;

    .scroll-to-see-more {
      display: none;
    }

    @media (min-width: 576px) and (max-width: 991.9px) {
      > div:nth-child(n+4) {
        &.scroll-to-see-more {
          display: flex;
        }
      }
    }

    @media (min-width: 992px) {
      > div:nth-child(n+5) {
        &.scroll-to-see-more {
          display: flex;
        }
      }
    }

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
      margin-left: 0;
      padding: 0 .25rem 1.5rem;

      .scroll-to-see-more {
        display: none !important;
      }
    }
  }

  .no-data {
    color: ${({ theme }) => theme.selfMeterReadCustomerCodeColor};
  }
`

export default SelfMeterReadOverviewStyle