import styled from 'styled-components'

const SelfMeterReadItemDataStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.itemBackgroundColor};
  box-shadow: ${({ theme }) => theme.selfMeterReadBoxShadow};
  overflow: hidden;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  margin: .75rem 0;
  margin-right: .75rem;
  min-width: calc(33.33% - .25rem);

  @media (max-width: 991.9px) {
    min-width: calc(50% - .25rem);
  }

  @media (max-width: 575.9px) {
    margin-right: 0;
    min-width: calc(100% - .25rem);
  }

  header {
    display: flex;
    padding: 1rem;
    align-items: center;
    min-width: 100%;
    justify-content: space-between;
    background: ${({ theme }) => theme.selfMeterReadItemNameBkg};
    color: ${({ theme }) => theme.selfMeterReadItemNameColor};

    .alias {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .custom-icon {
      fill: ${({ theme }) => theme.selfMeterReadItemNameColor};
    }
  }

  main {
    padding: 1rem;
    width: 100%;
    
    .supply-point-data {
      color: ${({ theme }) => theme.selfMeterReadItemTextColor};
      margin-bottom: 1rem;

      &.bordered {
        padding: .75rem;
        border-radius: 10px;
        box-shadow: ${({ theme }) => theme.selfMeterReadDataBoxShadow};
        background-color: ${({ theme }) => theme.selfMeterReadDataBkg};
        flex-wrap: wrap;
      }
    }

    .data-title {
      flex-basis: 100%;
      font-size: .85rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .self-read-data {
      display: flex;

    }
  }

  footer {
    align-self: center;
    padding: 0 1rem 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .custom-icon {
      fill: ${({ theme }) => theme.selfMeterReadItemInsertReadNameColor};
    }
  }
`

export default SelfMeterReadItemDataStyle