const SupplyPointsActionTypes = {
  TOGGLE_SUPPLY_POINTS_UTILITY_MODAL_VISIBILITY: 'TOGGLE_SUPPLY_POINTS_UTILITY_MODAL_VISIBILITY',

  FETCH_SUPPLY_POINTS_START: 'FETCH_SUPPLY_POINTS_START',
  FETCH_SUPPLY_POINTS_SUCCESS: 'FETCH_SUPPLY_POINTS_SUCCESS',
  FETCH_SUPPLY_POINTS_FAILURE: 'FETCH_SUPPLY_POINTS_FAILURE',

  FETCH_SUPPLY_POINT_DETAIL_START: 'FETCH_SUPPLY_POINT_DETAIL_START',
  FETCH_SUPPLY_POINT_DETAIL_SUCCESS: 'FETCH_SUPPLY_POINT_DETAIL_SUCCESS',
  FETCH_SUPPLY_POINT_DETAIL_FAILURE: 'FETCH_SUPPLY_POINT_DETAIL_FAILURE',

  UPDATE_SUPPLY_POINT_ALIAS_START: 'UPDATE_SUPPLY_POINT_ALIAS_START',
  UPDATE_SUPPLY_POINT_ALIAS_SUCCESS: 'UPDATE_SUPPLY_POINT_ALIAS_SUCCESS',
  UPDATE_SUPPLY_POINT_ALIAS_FAILURE: 'UPDATE_SUPPLY_POINT_ALIAS_FAILURE'
}

export default SupplyPointsActionTypes
