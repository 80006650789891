import styled from 'styled-components'

export const VerticalMenuStyle = styled.nav`
  height: 100vh;
  background-color: ${({ theme }) => theme.navigationMenuBkgColor};

  @media (max-width: 767.9px) {
    height: auto;
  }

  .navigation-menu {
    @media (max-width: 767.9px) {
      display: none !important;
    }
  }

  .brand-logo {
    @media (max-width: 768px) {
      max-width: 50%;
      margin-bottom: 1rem;
    }
  }
`
