import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CadastralDataInfoModalStyle from './style'

const CadastralDataInfoModal = ({ isVisible, toggle }) => (
  <Modal size="lg" isOpen={isVisible} toggle={toggle} className="utility-modal">
    <ModalHeader toggle={toggle}>
      <FontAwesomeIcon size="lg" icon={['fas', 'info-circle']} className="mr-2" />
      Informazioni sui dati catastali
    </ModalHeader>
    <ModalBody>
      <CadastralDataInfoModalStyle>
        <p>
          <strong>Che cosa sono?</strong>
          <br />
          I dati catastali sono i codici di riferimento identificativi di un immobile e vengono comunicati 
          ai possessori tramite la visura catastale, documento rilasciato dalla Agenzia delle Entrate. 
          Puoi trovare questi dati all'interno del tuo contratto di affitto o dell'atto di proprietà della tua casa o ufficio.
          <br />
          I dati della visura di un immobile sono: 
          <a rel="noreferrer" href="https://it.wikipedia.org/wiki/Visura_catastale" target="_blank">sezione urbana</a>,{' '}
          <a rel="noreferrer" href="https://it.wikipedia.org/wiki/Foglio_(catasto)" target="_blank">foglio</a>,{' '}
          <a rel="noreferrer" href="https://it.wikipedia.org/wiki/Particella_catastale" target="_blank">particella</a>,{' '}
          <a rel="noreferrer" href="https://it.wikipedia.org/wiki/Subalterno" target="_blank">subalterno</a>,{' '}
          <a rel="noreferrer" href="https://it.wikipedia.org/wiki/Comune" target="_blank">comune</a>.
        </p>
        <p>
          <strong>Perché devo comunicare i miei dati catastali?</strong>
          <br />
          Come disposto dalla Legge Finanziaria 2005 (L. n. 311 30/12/2004), le società che erogano servizi pubblici 
          {' '}sono tenute a raccogliere e, successivamente, comunicare all'Anagrafe Tributaria i dati catastali degli immobili presso 
          i quali sono attive le forniture.
        </p>
        <p>
          <strong>Se non comunico i miei dati catastali cosa succede?</strong>
          <br />
          In caso di mancata comunicazione dei dati catastali sono previste sanzioni amministrative sia nei confronti delle società che 
          erogano i servizi pubblici, sia verso i clienti stessi. Per maggiori informazioni consulta la{' '}
          <a 
            href="https://www.agenziaentrate.gov.it/portale/documents/20143/288488/Circolare+n+44+E+del+19+10+2005_circolare_44_2005.pdf/158437b7-5c88-d535-82c7-b42534b91436"
            target="_blank"
            rel="noreferrer"
          >
            Circolare n.44 del 19/10/2005 dell'Agenzia delle Entrate.
          </a>
        </p>
      </CadastralDataInfoModalStyle>
    </ModalBody>
  </Modal>
)

export default CadastralDataInfoModal
