import styled from 'styled-components'

export const BillsListHeaderStyle = styled.thead`
  color: ${({ theme }) => theme.mainTextColor};
  font-size: .75rem;

  th {
    border-top: none;
    border-bottom: none !important;
    vertical-align: middle;
    font-weight: 700;
    font-size: .75rem;
    color: ${({ theme }) => theme.billsThColor};

    > * {
      font-family: inherit;
    }

    &.w-5 {
      width: 5%;
    }

    &.w-10 {
      width: 10%;
    }

    &.w-15 {
      width: 15%;
    }

    &.w-20 {
      width: 20%;
    }
  }
`
