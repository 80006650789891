import React from 'react'
import { useSelector } from 'react-redux'

import { selectSignUpMethodDescription } from '../../../redux/user/user.selectors'
import LoginStep from '../../LoginStep/LoginStep.component'
import UserOtpSignForm from '../UserOtpSignForm/UserOtpSignForm.component'

const UserOtpSignStep = () => {
  const signUpMethodDescription = useSelector(selectSignUpMethodDescription)

  return (
    <LoginStep>
      <h1 className="step-title text-left">
        Inserisci il codice di conferma ricevuto tramite {signUpMethodDescription}
      </h1>
      <UserOtpSignForm />
    </LoginStep>
  )
}

export default UserOtpSignStep
