import React, { useState, useEffect, useCallback } from 'react'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import { OnChange } from 'react-final-form-listeners'

import { getAPIUrl } from '../../ABapi'
import { debounce, fetchData } from './utils'
import StyledExtendedCustomZipCodeSelect from './style'

const ExtendedCustomZipCodeSelect = ({ fieldToListen, fieldToListenDefaultValue, accessToken, ...props }) => {
  const [ options, setOptions ] = useState([])

  const updateValues = useCallback(objValue => {
    setOptions([])
    fetchData(
      `${getAPIUrl()}/town/${objValue.value}/postal-codes`,
      accessToken
    )
    .then(({ data }) => setOptions(data.postalCodes.map(value => ({ value: value, label: value }))))
    .catch(error => console.error(error.message))
  }, [accessToken])

  useEffect(() => {
    if(fieldToListenDefaultValue) updateValues(fieldToListenDefaultValue)
  }, [fieldToListenDefaultValue, updateValues])

  return (
    <FormGroup>
      <Field {...props}>
        {({ isMulti, fieldLabel, fieldDescription, noValidationMessage, input: { name, value, onChange, onBlur }, meta: { error, touched }, className, ...input }) => (
          <>
            {fieldLabel &&
              <Label for={input.name}>{fieldLabel}</Label>
            }
            <StyledExtendedCustomZipCodeSelect
              classNamePrefix="react-select-zip"
              className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
              value={value}
              isMulti={isMulti}
              options={options}
              onChange={value => onChange(value)}
              onBlur={onBlur}
              {...input}
            />
            {error && touched &&
              <FormFeedback>
                {error}
              </FormFeedback>
            }
            <OnChange name={fieldToListen}>
              {debounce(objValue => {
                if(objValue?.value) {
                  updateValues(objValue)
                }
              }, 300)}
            </OnChange>
          </>
        )}
      </Field>
    </FormGroup>
  )
}

export default ExtendedCustomZipCodeSelect
