import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const GasOfferDetails = ({ number, remiCode, status, meterSerialNumber, useCategory }) => (
  <div className="data-box gas">
    <h3 className="box-title electrical">
      Caratteristiche tecniche
    </h3>
    <div className="supply-point-data">
      <div className="data-group">
        <label>PDR:</label>
        <span className="code">{number}</span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Matricola contatore:</label>
        <span className="code">{meterSerialNumber ? (
          meterSerialNumber
        ) : (
          <FontAwesomeIcon title="Dato mancante" icon={['fas', 'question']} />
        )}
        </span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Codice Remi:</label>
        <span className="code">
          {remiCode ? remiCode : <FontAwesomeIcon title="Dato mancante" icon={['fas', 'question']} />}
        </span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Categoria d'uso:</label>
        <span className="code">
          {useCategory ? useCategory : <FontAwesomeIcon title="Dato mancante" icon={['fas', 'question']} />}
        </span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Stato fornitura:</label>
        <span 
          className={classNames({
            "code": true,
            "pdp-status": true,
            "closed": status === "closed",
          })}
        >
          {status === 'closed' ? (
            <>
              <FontAwesomeIcon icon="circle-xmark" className="mr-1" /> Cessato
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-check" className="mr-1" /> Attivo
            </>
          )}
        </span>
      </div>
    </div>
  </div>
)

export default GasOfferDetails
