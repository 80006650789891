//!IMPORTANT This component presumes you're using react-final-form
import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, Label } from 'reactstrap'

import './style.scss'

const CustomRadioField = ({ children, ...props }) => (
  <FormGroup check>
    <Field {...props}>
      {({ input, meta: { error, touched }, description, ...otherProps }) => (
        <Label check>
          <Input invalid={error && touched ? true : false} {...input} {...otherProps} />{' '}
          {description}
        </Label>
      )}
    </Field>
    {children}
  </FormGroup>
)


export default CustomRadioField
