import styled from 'styled-components'
import { Navbar } from 'reactstrap'

export const NavigationBarStyle = styled(Navbar)`
  box-shadow: ${({ theme }) => theme.navigationBarBoxShadow};
  margin-top: .5rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.navigationBarBkg};

  @media (max-width: 767.9px) {
    border: none;
    background-color: ${({ theme }) => theme.navigationBarBkgColorMobile};
  }

  .user-name {
    background: transparent;
    padding: .75rem 1.25rem;
    border-radius: 25px;
    border: 1px solid;
    font-size: .8rem;
    color: ${({ theme }) => theme.navigationBarUserNameColor};

    strong {
      font-weight: 600;
    }

    @media (max-width: 767.9px) {
      display: none;
    }
  }

  .sign-out-btn {
    display: flex;
    align-items: center;
    transition: transform .5s ease-in;

    @media (max-width: 767.9px) {
      display: none !important;
    }

    #disconnetti {
      width: 2rem;

      .st0 {
        fill: ${({ theme }) => theme.navigationBarSignOutColor};
        transition: fill .2s ease;
      }
    }

    &:hover {
      #disconnetti {
        transform: scale(1.05);
       
        .st0 {
          fill: ${({ theme }) => theme.navigationBarSignOutHoverColor};
        }
      }
    }

    button {
      padding: 0;
      line-height: 0;
      font-size: 1.75rem;
    }
  }

  .navbar-brand {
    color: ${({ theme }) => theme.navigationBarBrandColor};
    font-size: 1rem;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.navigationBarBrandColor};
    }

    @media (max-width: 767.9px) {
      color: ${({ theme }) => theme.navigationBarBrandColorMobile};

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => theme.navigationBarBrandColorMobile};
      }
    }
  }

  .navbar-nav {
    align-items: center;

    @media (max-width: 767.9px) {
      flex-direction: row;
      margin-right: 1rem;
    }

    .nav-item {
      margin: 0 .5rem;
    }

    .sign-out-btn {
      display: flex;
      align-items: center;
      transition: transform .5s ease-in;
      color: ${({ theme }) => theme.navigationBarSignOutColor};
  
      @media (max-width: 767.9px) {
        display: none !important;
      }
  
      &:hover {
        color: ${({ theme }) => theme.navigationBarSignOutHoverColor};
      }
    }
  }
  
  .navbar-collapse {
    @media (min-width: 768px) {
      display: none !important;
    }
  }

  .navbar-toggler {
    border: none;

    > span {
      width: 2rem;
      height: 3px;
      display: block;
      background-color: ${({ theme }) => theme.navbarTogglerBkg};
      margin-bottom: 5px;
      transition: transform .3s ease-out;

      &:last-child {
        margin: 0;
      }
    }

    &.menu-open {
      > span:nth-child(1) {
        transform: translate(0px, 10px) rotate(45deg);
      }

      > span:nth-child(2) {
        visibility: hidden;
      }

      > span: nth-child(3) {
        transform: translate(-1px, -6px) rotate(-45deg)
      }
    }
  }
`
