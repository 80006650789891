import styled from 'styled-components'
import { Modal } from 'reactstrap'

const AttachmentModalStyle = styled(Modal)`

  .modal-content {
    min-height: 300px;
  }

  #attachment-embed {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 600;
    padding: 3rem;

    p {
      font-weight: 700;
      margin-bottom: .25rem;
      font-size: 1rem;
      color: ${({ theme }) => theme.attachmentModalTitleColor};

      &.light {
        font-weight: 600;
        font-size: .8rem;
        color: ${({ theme }) => theme.attachmentModalDescriptionColor};
      }
    }

    button {
      margin-top: 1.5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default AttachmentModalStyle