import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'

import CadastralDataItemStyle from "./style"
import PdpItem from './PdpItem/PdpItem.component'
import { fetchCadastralDataItemStart, fetchAvailablePdpStart } from '../../../../redux/cadastralData/cadastralData.actions'
import { selectIsFetchingCadastralData, selectUnmatchedPods, selectUnmatchedPdrs } from '../../../../redux/cadastralData/cadastralData.selectors'
import DeleteCadastralDataModal from '../DeleteCadastralDataModal/DeleteCadastralDataModal.component'
import { getAPIUrl } from '../../../../ABapi'
import ButtonWithLoader from '../../../ButtonWithLoader/ButtonWithLoader.component'

const CadastralDataItem = ({ data }) => {
  const dispatch = useDispatch()
  const unmatchedPod = useSelector(selectUnmatchedPods)
  const unmatchedPdr = useSelector(selectUnmatchedPdrs)
  const isFetchingCadastralData = useSelector(state => selectIsFetchingCadastralData(`cadastralDataItemFetch-${data.id}`)(state))
  const isFetchingPdpItems = useSelector(state => selectIsFetchingCadastralData(`fetchAvailablePdp-${data.id}`)(state))

  return (
    <>
      <CadastralDataItemStyle>
        <header>
          <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
          <h1 className="alias">
            {data.address} {data.streetNo} {data.postalCode?.label} - {data.town?.label}
          </h1>
        </header>
        <main>
          <section>
            <h3 className="supply-points-title">Utenze associate:</h3>
            {data.matchedPod?.length || data.matchedPdr?.length ? (
              <div className="pdp-container">
                {data.matchedPod.map(item => (
                  <PdpItem
                    key={item.id}
                    cadastralDataItemId={data.id}
                    {...item}
                  />
                ))}
                {data.matchedPdr?.map(item => (
                  <PdpItem
                    key={item.id}
                    cadastralDataItemId={data.id}
                    {...item}
                  />
                ))}
              </div>
            ) : (
              <div className="supply-point-data">Nessuna utenza associata</div>
            )}
            {(unmatchedPod || unmatchedPdr) &&
              <div className="supply-point-data bordered mt-3">
                <div className="data-group alias">
                  <span className="code">
                    Aggiungi utenze
                  </span>
                </div>
                <div className="data-group action-btns">
                  <ButtonWithLoader
                    className="ab-button"
                    defaultIcon={['fas', 'plus']}
                    title="Aggiungi utenze"
                    buttonLabel=""
                    isFetching={isFetchingPdpItems}
                    onClick={() => 
                      dispatch(fetchAvailablePdpStart({ 
                        apiUrl: `${getAPIUrl()}/cadastral-data/association`,
                        cadastralDataItemId: data.id
                      }))
                    } 
                  />
                </div>
              </div>
            }
          </section>
        </main>
        <footer>
          {data.matchedPod?.length === 0 && data.matchedPdr?.length === 0 &&
            <DeleteCadastralDataModal 
              cadastralDataId={data.id}
            />
          }
          <ButtonWithLoader
            className="ab-button"
            defaultIcon={['fas', 'edit']}
            title="Modifica questi dati catastali"
            buttonLabel="Modifica"
            isFetching={isFetchingCadastralData}
            onClick={() => dispatch(fetchCadastralDataItemStart({ 
              apiUrl: `${getAPIUrl()}/cadastral-data/${data.id}`,
              cadastralDataItemId: data.id
            }))}
          />
        </footer>
      </CadastralDataItemStyle>
    </>
  )
}

export default CadastralDataItem