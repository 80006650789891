import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'
import { googleSignInStart } from '../../../redux/user/user.actions'

const GoogleSignInButton = () => {
  const dispatch = useDispatch()

  return(
    <Button
      color="danger"
      onClick={() => dispatch(googleSignInStart())}
      className="g-btn"
    >
      <FontAwesomeIcon icon={['fab', 'google']} />
      Continua con Google
    </Button>
  )
}

export default GoogleSignInButton
