import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lottie from 'react-lottie'

import * as clientCodeHelper from '../../../assets/lottie/client-code-help.json'
import * as otpModeHelper from '../../../assets/lottie/otp-mode-help.json'
import { selectSignUpStep, selectIsSubmitting, selectCustomerCodeModalVisibility } from '../../../redux/user/user.selectors'
import { toggleCustomerCodeModal, goToStep } from '../../../redux/user/user.actions'
import UserLoginSteps from '../../../pages/LoginPage/loginSteps'
import UserClientCodeForm from '../../login/UserClientCodeForm/UserClientCodeForm.component'
import UserOtpTypeForm from '../../login/UserOtpTypeForm/UserOtpTypeForm.component'
import UserOtpSignForm from '../../login/UserOtpSignForm/UserOtpSignForm.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { ReactComponent as Info } from '../../../assets/svg/info.svg'

const AddCustomerCodeModal = () => {
  const dispatch = useDispatch()
  const signUpStep = useSelector(selectSignUpStep)
  const isSubmitting = useSelector(selectIsSubmitting)
  const customerCodeModalVisibility = useSelector(selectCustomerCodeModalVisibility)
  const toggle = () => {
    dispatch(goToStep(UserLoginSteps.USER_CLIENT_CODE))
    dispatch(toggleCustomerCodeModal())
  }

  const [ clientCodeVisiblity, setClientCodeVisiblity ] = useState(false)
  const toggleClientCode = () => setClientCodeVisiblity(!clientCodeVisiblity)
  const clientCodeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: clientCodeHelper.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const otpModeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: otpModeHelper.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <ButtonWithLoader
        otherClasses="ab-button"
        buttonLabel="Aggiungi codice cliente"
        onClick={toggle}
        isFetching={isSubmitting}
        disabled={isSubmitting}
        defaultIcon={['fas', 'plus-circle']}
      />
      <Modal isOpen={customerCodeModalVisibility} toggle={toggle} className="add-customer-code-modal">
        <ModalHeader toggle={toggle}>
          Aggiungi un codice cliente
          {signUpStep < UserLoginSteps.USER_OTP_SIGN_STEP &&
            <span onClick={toggleClientCode} className="info-trigger ml-2">
              <Info />
            </span>
          } 
        </ModalHeader>
        <ModalBody>
          {signUpStep === UserLoginSteps.USER_CLIENT_CODE &&
            <UserClientCodeForm />
          }
          {signUpStep === UserLoginSteps.OTP_TYPE_STEP &&
            <UserOtpTypeForm />
          }
          {signUpStep === UserLoginSteps.USER_OTP_SIGN_STEP &&
            <UserOtpSignForm />
          }
        </ModalBody>
      </Modal>

      <Modal isOpen={clientCodeVisiblity} toggle={toggleClientCode}>
        <ModalHeader toggle={toggleClientCode}>
          Dove trovo il codice cliente?
        </ModalHeader>
        <ModalBody>
          {signUpStep === UserLoginSteps.USER_CLIENT_CODE &&
            <>
              <div className="animation-help">
                <Lottie options={clientCodeAnimationOptions} />
              </div>
              <p className="mt-3">Puoi trovare il codice cliente nella prima pagina della bolletta</p>
            </>
          }
          {signUpStep === UserLoginSteps.OTP_TYPE_STEP &&
            <>
              <div className="animation-help">
                <Lottie options={otpModeAnimationOptions} />
              </div>
              <p className="mt-3">Per completare la registrazione ti invieremo un codice di conferma al contatto che hai lasciato in fase di
              sottoscrizione del contratto di fornitura.</p>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button className="ab-button" onClick={toggleClientCode}>
            <FontAwesomeIcon icon={['fas', 'check']} />
            <span>Ho capito</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddCustomerCodeModal
