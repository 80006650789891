import styled from 'styled-components'

const PdpShortcutModalStyle = styled.div`
  font-weight: 500;
  text-align: center;

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
  }
    
  p {
    color: ${({ theme }) => theme.descriptionText} !important;

    strong {
      color: ${({ theme }) => theme.modalBodyTextColor};
    }
  }

  a {
    color: ${({ theme }) => theme.otpSignNewCodeLinkColor};
    text-decoration: underline;
    font-weight: 700;
  }

  .action-container {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default PdpShortcutModalStyle