import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SortableColumnHeaderStyle } from './style'
import { selectSorting } from '../../../redux/bills/bills.selectors'
import { setBillsSorting } from '../../../redux/bills/bills.actions'

const SortableColumnHeader = ({ className, columnLabel, columnParam }) => {
  const dispatch = useDispatch()
  const sorting = useSelector(selectSorting)

  const handleSorting = () => {
    const newParam = sorting === columnParam ? `-${columnParam}` : columnParam
    dispatch(setBillsSorting(newParam))
  }

  const [ icon, setIcon ] = useState('sort')
  useEffect(() => {
    if(sorting.indexOf(columnParam) === -1) setIcon('sort')
    if(sorting.indexOf(columnParam) !== -1 && sorting.indexOf("-") === -1) setIcon('sort-up')
    if(sorting.indexOf(columnParam) !== -1 && sorting.indexOf("-") !== -1) setIcon('sort-down')
  }, [sorting, columnParam])

  return (
    <SortableColumnHeaderStyle
      className={
        classnames({
          'sortable-column': true,
          [className]: className !== undefined,
          'active-col': sorting.indexOf(columnParam) !== -1
        })
      }
      onClick={() => handleSorting(columnParam)}
    >
      <span>{columnLabel}</span>
      <FontAwesomeIcon icon={['fas', icon]} />
    </SortableColumnHeaderStyle>
  )
}

export default SortableColumnHeader
