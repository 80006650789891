import React from 'react'

import LoginStep from '../../LoginStep/LoginStep.component'
import EmailSignInForm from '../EmailSignInForm/EmailSignInForm.component'
import GoogleSignInButton from '../GoogleSignInButton/GoogleSignInButton.component'
import FacebookSignInButton from '../FacebookSignInButton/FacebookSignInButton.component'
import AppleSignInButton from '../AppleSignInButton/AppleSignInButton.component'

const SignInStep = () => (
  <LoginStep>
    <h1 className="step-title">
      Qual è il tuo indirizzo email?
    </h1>
    <p className="form-description">
      Con questo indirizzo email accederai all'area clienti ABenergie® di Gas and Power Srl.
    </p>
    <EmailSignInForm />
    <hr />
    <div className="buttons-list">
      <GoogleSignInButton />
      <FacebookSignInButton />
      <AppleSignInButton />
    </div>
  </LoginStep>
)

export default SignInStep
