import styled from 'styled-components'

export const UpdateDataButtonStyle = styled.div`
  margin: 1.5rem 0;

  > button {
    background-color: ${({ theme }) => theme.updateDataButtonBkg};
    color: ${({ theme }) => theme.updateDataButtonColor};
    border: 1px solid ${({ theme }) => theme.updateDataButtonBkg};
    padding: .75rem 1.25rem;
    border-radius: 25px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: ${({ theme }) => theme.updateDataButtonHoverBkg};
      color: ${({ theme }) => theme.updateDataButtonHoverColor};
      border: 1px solid ${({ theme }) => theme.updateDataButtonHoverColor};
    }

    > svg {
      margin-right: .75rem;
    }

    > .text-label {
      font-weight: 600;
    }
  }
`
