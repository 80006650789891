import { createSelector } from 'reselect'

const selectUser = state => state.user

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
)

export const selectIsFetching = createSelector(
  [selectUser],
  user => user.isLoading
)

export const selectIsCheckingUser = createSelector(
  [selectUser, selectCurrentUser],
  (user, currentUser ) => user.isLoading && currentUser === undefined ? true : false
)

export const selectIsSubmitting = createSelector(
  [selectUser],
  user => user.isSubmitting
)

export const selectSignUpStep = createSelector(
  [selectUser],
  user => user.signUpStep
)

export const selectSignUpEmail = createSelector(
  [selectUser],
  user => user.signUpEmail
)

export const selectSignUpClientCode = createSelector(
  [selectUser],
  user => user.signUpClientCode
)

export const selectSignUpMethodDescription = createSelector(
  [selectUser],
  user => user.signUpMethodDescription
)

export const selectOtpTransactionId = createSelector(
  [selectUser],
  user => user.otpTransactionId
)

export const selectOobCode = createSelector(
  [selectUser],
  user => user.oobCode
)

export const selectOtpMethods = createSelector(
  [selectUser],
  user => user.otpMethods
)

export const selectIdToken = createSelector(
  [selectCurrentUser],
  currentUser => currentUser.idToken
)

export const selectProfilePicture = createSelector(
  [selectCurrentUser],
  currentUser => currentUser.profilePicture
)

export const selectCustomerCodes = createSelector(
  [selectCurrentUser],
  currentUser => currentUser?.customerCodes || null
)

export const selectCustomerCodesArray = createSelector(
  [selectCustomerCodes],
  customerCodes => (customerCodes ? Object.keys(customerCodes) : [])
)

export const selectReservedAreaEnabled = createSelector(
  [selectCurrentUser],
  currentUser => currentUser && currentUser.customerCodes && Object.keys(currentUser.customerCodes).length ? true : false
)

export const selectCustomerCodeItem = customerCode => createSelector(
  [selectCustomerCodes],
  customerCodes => (customerCodes ? customerCodes[customerCode] : null)
)

export const selectCustomerCodeItemIsLoading = customerCode => createSelector(
  [selectCustomerCodes],
  customerCodes => {
    return (customerCodes ? customerCodes[customerCode]?.toFetch : null)
  }
)

export const selectCustomerCodeItemIsSubmitting = customerCode => createSelector(
  [selectCustomerCodes],
  customerCodes => {
    return (customerCodes ? customerCodes[customerCode].isSubmitting : null)
  }
)

export const selectResetPasswordDone = createSelector(
  [selectUser],
  user => user.resetPasswordDone
)

export const selectCustomerCodeModalVisibility = createSelector(
  [selectUser],
  user => user.customerCodeModalVisiblity
)

export const selectUtilityModal = createSelector(
  [selectUser],
  user => user.utilityModal
)

export const selectUserTheme = createSelector(
  [selectCurrentUser],
  currentUser => currentUser?.theme || 'light'
)

export const selectUserMaintenanceState = createSelector(
  [selectUser],
  user => user.maintenanceState
)