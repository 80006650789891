export const buildChartData = data => {
  const chartData = data.map(item => {
    const { month, year, totalConsumption, ranges, unitOfMeasure } = item
    const consumptionDetail = ranges.reduce((accumulator, rangeItem) => {
      accumulator[rangeItem.range] = rangeItem.consumption
      return accumulator
    }, {})

    return {
      date: `${month}/${year}`,
      unit: ` ${unitOfMeasure}`,
      totalConsumption: totalConsumption,
      ...consumptionDetail
    }
  })
  return chartData
}
