import { createSelector } from 'reselect'

const selectBills = state => state.bills

export const selectIsFetchingBills = createSelector(
  [selectBills],
  bills => bills.isLoading || bills.items === null
)

export const selectItems = createSelector(
  [selectBills],
  bills => bills.items
)

export const selectFilters = createSelector(
  [selectBills],
  bills => bills.filters
)

export const selectSorting = createSelector(
  [selectBills],
  bills => bills.sorting
)

export const selectCurrentPage = createSelector(
  [selectBills],
  bills => bills.selectedPage
)

export const selectTotalItems = createSelector(
  [selectBills],
  bills => bills.totalItems
)

export const selectTotalPages = createSelector(
  [selectBills],
  bills => bills.totalPages
)

export const selectAttachmentModalVisibility = createSelector(
  [selectBills],
  bills => bills.attachmentModalVisibility
)

export const selectIsAttachmentLoading = createSelector(
  [selectBills],
  bills => bills.isAttachmentLoading
)

export const selectAttachment = createSelector(
  [selectBills],
  bills => bills.attachment
)

export const selectUtilityModal = createSelector(
  [selectBills],
  bills => bills.utilityModal
)
