import _ from 'lodash'

import { getAPIUrl, fetchData, submitRequest } from '../../ABapi'

export const updateItem = ({ items }, { customerCode, number, item }) => {
  let itemToUpdate = items[customerCode][number]
  itemToUpdate = {
    ...itemToUpdate,
    toFetch: false,
    ...item
  }
  items[customerCode][number] = itemToUpdate

  return items
}

export const fetchItem = ({ items }, { customerCode, number }) => {
  let itemToUpdate = items[customerCode][number]
  itemToUpdate = {
    ...itemToUpdate,
    toFetch: true
  }
  items[customerCode][number] = itemToUpdate

  return items
}

export function sendSelfMeterRead(value, pdrNumber, idToken) {
  return submitRequest(`${getAPIUrl()}/pdr/${pdrNumber}/self-meter-reading`, idToken, { value })
    .then(response => response)
}

export function getSelfMeterReadData(pdrNumber, idToken) {
  return fetchData(`${getAPIUrl()}/pdr/${pdrNumber}/self-meter-reading`, idToken)
    .then(response => response)
}

export function setupActiveSupplyPointsArray(customerCodesObj) {
  let supplyPointsArray = []
  _.map(customerCodesObj, (customerCode) => {
    _.map(customerCode, (supplyPoint) => supplyPointsArray.push(supplyPoint))
  })

  return supplyPointsArray
}

export function normalizeSelfMeterReads(supplyPoints) {
  const selfMeterReadsNumbers = supplyPoints.reduce((accumulator, supplyPoint) => {
    if(!supplyPoint[0]) return accumulator
    const supplyPointSelfMeters = supplyPoint.reduce((acc, point) => {
      if(point.status === "active") {
        acc[point.number] = {
          toFetch: true,
          ...point
        }
      }
      return acc
    }, {})

    if(Object.keys(supplyPointSelfMeters).length > 0) {
      accumulator[supplyPoint[0].customerCode] = supplyPointSelfMeters
    }
    return accumulator
  }, {})
  return selfMeterReadsNumbers
}
