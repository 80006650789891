import styled from 'styled-components'

export const SupplyPointItemStyle = styled.div`
  padding: 1.5rem .5rem;

  .back-btn {
    color: ${({ theme }) => theme.backButtonColor};

    &:hover {
      color: ${({ theme }) => theme.backButtonHoverColor};
    }
    
    .link-text {
      font-weight: 700;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.supplyPointHeaderBorderColor};
    padding: .25rem 0;

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
      padding: .25rem 0 1rem;
    }

    .title-container {
      display: flex;
      align-items: center;

      @media (max-width: 575.9px) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .custom-icon {
      margin-right: 1rem;
      fill: ${({ theme }) => theme.supplyPointAliasColor};
    }

    .alias {
      color: ${({ theme }) => theme.supplyPointAliasColor};
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 0;

      > span {
        font-weight: 700;
      }

      .edit-alias {
        border: none;
        padding: 0;
        background: transparent;
        line-height: 1;
        cursor: pointer;
        margin-left: 1rem;
        font-size: 2rem;
        transition: color .3s ease;
        outline: none;
        color: ${({ theme }) => theme.editAliasButtonColor};

        &:active {
          background-color: transparent !important;
          border: none !important;
          
          > svg path {
            fill: ${({ theme }) => theme.editAliasButtonColorHover};
          }
        }

        &:hover,
        &:focus {
          box-shadow: none;
          color: ${({ theme }) => theme.editAliasButtonColorHover};

          > svg path {
            fill: ${({ theme }) => theme.editAliasButtonColorHover};
          }
        }
      }
    }
  }

  .green-option {
    color: ${({ theme }) => theme.supplyPointGreenOptionColor};
    font-size: .85rem;
    display: flex;
    align-items: center;

    > span {
      font-weight: 600;
    }

    > svg {
      margin-left: .5rem;
      fill: ${({ theme }) => theme.supplyPointGreenOptionColor};
      font-size: 1.25rem;
    }
  }


  .data-box {
    border: none;
    box-shadow: 0px 0px 16px #00000019;
    background-color: ${({ theme }) => theme.itemBackgroundColor};
    height: 100%;
    padding: 1.25rem 1rem;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    letter-spacing: 0;

    @media (max-width: 575.9px) {
      height: auto;
      margin-bottom: 1rem;
    }

    .logo-bkg {
      position: absolute;
      width: 160px;
      right: -1.5rem;
      top: -1.5rem;
      fill: rgba(221, 221, 221, 0.1);
    }

    .box-title {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      color: ${({ theme }) => theme.supplyPointDataBoxTitleColor};
      font-weight: 700;
      display: flex;
      align-items: center;

      > svg {
        margin-right: .5rem;
        font-size: 1.5rem;
      }
    }

    .pdp-status {

      svg {
        font-size: 1rem;
        vertical-align: text-bottom;
      }
    }
  }

  .supply-point-data {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .chart-title {
    margin: 2.5rem 0 0;
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: ${({ theme }) => theme.supplyPointChartTitleColor};
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .cadastral-btn {
    display: inline-block;
    margin-top: .5rem;
    text-decoration: none !important;
  }
`
