import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-final-form'
import Lottie from 'react-lottie'

import SelfMeterReadModalFormStyle from './style'
import * as animationData from '../../../assets/lottie/self-reading.json'
import { checkFormvalues } from './utils'
import { selectIsSubmittingSelfMeterRead } from '../../../redux/selfMeterRead/selfMeterRead.selectors'
import { submitSelfMeterReadStart } from '../../../redux/selfMeterRead/selfMeterRead.actions'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import CustomNumberField from '../../CustomNumberField/CustomNumberField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'

const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

const SelfMeterReadModalForm = ({ customerCode, isOpen, toggle, number, updateData }) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmittingSelfMeterRead)

  const [ helpModalVisibility, setHelpModalVisibility ] = useState(false)
  const toggleHelpModalVisiblity = () => setHelpModalVisibility(!helpModalVisibility)
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const onSubmit = (values) => {
    const readValues = checkFormvalues(values)
    dispatch(submitSelfMeterReadStart({ value: readValues, customerCode, number }))
  }

  return (
    <>
      <SelfMeterReadModalFormStyle size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Flame className="custom-icon" />
          Inserisci autolettura gas
          <span onClick={toggleHelpModalVisiblity} className="info-trigger ml-2">
            <FontAwesomeIcon icon={['fas', 'question-circle']} />
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="description">
            <p>
              Trascrivi tutte le <strong>cifre</strong> che si trovano prima della virgola, compresi eventuali zeri.
              Se uno di questi numeri non si trovasse in posizione centrale, perché la ghiera sta scattando verso il numero successivo,
              considera la cifra che risulta più visibile. Le cifre a destra della virgola non sono rilevanti ai fini della lettura.
            </p>
            <p className="alt-color">
              Ricorda che l’Autorità per l’Energia Elettrica ed il Gas stabilisce che l’autolettura da te fornita è valida ai fini 
              della fatturazione, a meno che sia evidentemente errata.<br />
              Esempio 1: Ci comunichi un’autolettura di 100, quando la precedente era 150.<br />
              Esempio 2: Ci comunichi un’autolettura di 10000, quando la precedente era 10.
            </p>
          </div>
          <Form mutators={{updateFieldValue}} onSubmit={onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values, submitError }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="self-meter-read-form">
                <div className="self-meter-inputs-container">
                  <div className="measuring-unit">Smc</div>
                  <CustomNumberField
                    id="selfRead1"
                    name="selfRead1"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <CustomNumberField
                    id="selfRead2"
                    name="selfRead2"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <CustomNumberField
                    id="selfRead3"
                    name="selfRead3"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <CustomNumberField
                    id="selfRead4"
                    name="selfRead4"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <CustomNumberField
                    id="selfRead5"
                    name="selfRead5"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <CustomNumberField
                    id="selfRead6"
                    name="selfRead6"
                    type="text"
                    maxLength={1}
                    placeholder="0"
                    updateFieldValue={form.mutators.updateFieldValue}
                  />
                  <span className="comma">,</span>
                  <CustomFormField
                    id="selfRead7"
                    name="selfRead7"
                    type="text"
                    defaultValue="0"
                    readOnly={true}
                  />
                  <CustomFormField
                    id="selfRead8"
                    name="selfRead8"
                    type="text"
                    defaultValue="0"
                    readOnly={true}
                  />
                  <CustomFormField
                    id="selfRead9"
                    name="selfRead9"
                    type="text"
                    defaultValue="0"
                    readOnly={true}
                  />
                  {submitError && <div className="submit-error">{submitError}</div>}
                </div>
                <div className="submit-container">
                  <ButtonWithLoader
                    type="submit"
                    otherClasses="ab-button inverted"
                    buttonLabel="Invia"
                    onClick={handleSubmit}
                    isFetching={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Form>
        </ModalBody>
      </SelfMeterReadModalFormStyle>

      <Modal isOpen={helpModalVisibility} toggle={toggleHelpModalVisiblity}>
        <ModalHeader toggle={toggleHelpModalVisiblity}>
          Come faccio a leggere dal contatore?
        </ModalHeader>
        <ModalBody>
          <div className="animation-help">
            <Lottie options={animationOptions} />
          </div>
          <p className="mt-3">
            Trascrivi tutte le <strong>cifre</strong> che si trovano prima della virgola, compresi eventuali zeri.<br />
            Se uno di questi numeri non si trovasse in posizione centrale, perché la ghiera sta scattando verso il numero successivo,
            considera la cifra che risulta più visibile.<br />
            Le cifre a destra della virgola non sono rilevanti ai fini della lettura.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="ab-button" onClick={toggleHelpModalVisiblity}>
            <FontAwesomeIcon icon={['fas', 'check']} />
            <span>Ho capito</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SelfMeterReadModalForm
