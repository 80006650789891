import styled from "styled-components"

const CadastralItemDataFormStyle = styled.div`
  .grouped-form {
    padding: .75rem;
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.selfMeterReadDataBoxShadow};
    background-color: ${({ theme }) => theme.selfMeterReadDataBkg};
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`

export default CadastralItemDataFormStyle