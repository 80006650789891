import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import PaginationComponent from "react-reactstrap-pagination"

import BillsListStyle from './style'
import { fetchBillsStart } from '../../../redux/bills/bills.actions'
import { selectTotalItems, selectCurrentPage } from '../../../redux/bills/bills.selectors'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../redux/bills/bills.utils'
import BillsListFilters from '../BillsListFilters/BillsListFilters.component'
import BillsListHeader from '../BillsListHeader/BillsListHeader.component'
import BillsListBodyContainer from '../BillsListBody/BillsListBodyContainer.component'
import AttachmentModal from '../../AttachmentModal/AttachmentModal.component'

const BillsList = () => {
  const dispatch = useDispatch()
  const totalItems = useSelector(selectTotalItems)
  const activePage = useSelector(selectCurrentPage)

  const handlePageSelection = selectedPage => {
    dispatch(fetchBillsStart({ selectedPage }))
  }

  return (
    <BillsListStyle className="bills-list">
      <BillsListFilters />
      <Table responsive="lg" className="table-container">
        <BillsListHeader />
        <tbody className="bills-list-body">
          <BillsListBodyContainer />
        </tbody>
      </Table>
      <PaginationComponent
        totalItems={totalItems}
        pageSize={DEFAULT_ITEMS_PER_PAGE}
        onSelect={handlePageSelection}
        maxPaginationNumbers={10}
        defaultActivePage={activePage}
        firstPageText='I<'
        lastPageText='>I'
        previousPageText='<'
        nextPageText='>'
      />
      <AttachmentModal />
    </BillsListStyle>
  )
}

export default BillsList
