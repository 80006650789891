import React from 'react'

import { BillsListHeaderStyle } from './style'
import SortableColumnHeader from '../SortableColumnHeader/SortableColumnHeader.component'

const BillsListHeader = () => (
  <BillsListHeaderStyle>
    <tr>
      <th className="w-15">N° Bolletta</th>
      {/*<th className="w-10">Metodo di pagamento</th>*/}
      <th className="w-15">Indirizzo</th>
      <SortableColumnHeader className="w-15" columnLabel="Data emissione" columnParam="date" defaultValue />
      <th className="w-10">Periodo</th>
      <SortableColumnHeader className="w-10" columnLabel="Importo" columnParam="amount" />
      <SortableColumnHeader className="w-10" columnLabel="Consumo" columnParam="consumption" />
      <th className="w-10">Scadenza</th>
      <th className="w-5">Bolletta</th>
      <th className="w-5">Dettaglio</th>
    </tr>
  </BillsListHeaderStyle>
)

export default BillsListHeader
