import styled from 'styled-components'

const CustomTooltipStyle = styled.div`
  width: 200px;
  background-color: ${({ theme }) => theme.tooltipBkg};
  color: ${({ theme }) => theme.tooltipColor};
  font-size: .85rem;
  padding: 1rem;
  border-radius: 5px;

  .label {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.15rem;

    > svg {
      margin-right: .5rem;
      font-size: 1rem;
    }
  }

  .item {
    margin-bottom: .25rem;
  }

  .data-label {
    color: $abBlue;
    font-weight: 600;
    margin-right: .25rem;
  }
`

export default CustomTooltipStyle