import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import NoMatchPage from '../../pages/NoMatchPage/NoMatchPage.component'
import LoginPage from '../../pages/LoginPage/LoginPage.component'
import CustomerAreaPage from '../../pages/CustomerAreaPage/CustomerAreaPage.component'
import FirebaseAuthPage from '../../pages/FirebaseAuthPage/FirebaseAuthPage.component'
import MaintenancePage from '../../pages/MaintenancePage/MaintenancePage.component'
import { selectReservedAreaEnabled, selectUserMaintenanceState } from '../../redux/user/user.selectors'
import { useSelector } from 'react-redux'
import PortalRedirectPage from '../../pages/PortalRedirectPage/PortalRedirectPage.component'

const AppRouter = () => {
  const reservedAreaEnabled = useSelector(selectReservedAreaEnabled)
  const maintenanceState = useSelector(selectUserMaintenanceState)

  return (
    <>
      {maintenanceState?.is_under_maintenance === true ? (
        <MaintenancePage />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {reservedAreaEnabled ? (
                    <Navigate to="/punti-fornitura" />
                  ) : (
                    <PortalRedirectPage />
                  )}
                </>
              }
            />

            <Route
              path={"/login-form"}
              element={
                <>
                  {reservedAreaEnabled ? (
                    <Navigate to="/punti-fornitura" />
                  ) : (
                    <LoginPage />
                  )}
                </>}
            />

            <Route
              path={"/firebase-auth/*"}
              element={<FirebaseAuthPage />}
            />


            <Route
              path="*"
              element={<CustomerAreaPage />}
            />

            <Route
              path="/no-match"
              element={<NoMatchPage />}
            />
          </Routes>
        </BrowserRouter>
      )}
    </>
  )
}

export default AppRouter