import { call, put, all, takeLatest, select } from 'redux-saga/effects'

import CadastralDataTypes from './cadastralData.types'
import { 
  fetchCadastralDataSuccess, fetchCadastralDataFailure,
  submitCadastralDataItemSuccess, submitCadastralDataItemFailure, fetchCadastralDataItemFailure,
  fetchCadastralDataItemSuccess, deleteCadastralDataItemSuccess, deleteCadastralDataItemFailure,
  submitCadastralItemPdpAssociationFailure, submitCadastralItemPdpAssociationSuccess,
  fetchAvailablePdpFailure, fetchAvailablePdpSuccess, submitPdpToCadastralItemAssociationFailure, 
  submitPdpToCadastralItemAssociationSuccess, addCadastralDataSuccess, addCadastralDataFailure, 
  deletePdpToCadastralItemAssociationFailure,
  deletePdpToCadastralItemAssociationSuccess, 
} from './cadastralData.actions'
import { selectIdToken } from '../user/user.selectors'
import { 
  fetchCadastralData, fetchCadastralFormItems, normalizeCadastralDataItems,
  fetchCadastralItem, deleteItem, submitItem, fetchAvailablePdpItems
} from './cadastralData.utils'

export function* deletePdpToCadastralItemAsssociation({ payload: { apiUrl } }) {
  try {
    const idToken = yield select(selectIdToken)
    yield call(deleteItem, apiUrl, idToken)
    const { data } = yield call(fetchCadastralData, idToken)
    data.cadastralData = normalizeCadastralDataItems(data.cadastralData)
    yield put(deletePdpToCadastralItemAssociationSuccess({ cadastralData: data }))
  }
  catch(error) {
    yield put(deletePdpToCadastralItemAssociationFailure(error))
  }
}

export function* onDeletePdpToCadastralItemAssociationStart() {
  yield takeLatest(
    CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START,
    deletePdpToCadastralItemAsssociation
  )
}

export function* addCadastralData({ payload: { apiUrl } }) {
  try {
    const idToken = yield select(selectIdToken)
    const { data } = yield call(fetchAvailablePdpItems, apiUrl, idToken)
    yield put(addCadastralDataSuccess(data))
  }
  catch(error) {
    yield put(addCadastralDataFailure(error))
  }
}

export function* onAddCadastralDataStart() {
  yield takeLatest(
    CadastralDataTypes.ADD_CADASTRAL_DATA_START,
    addCadastralData
  )
}

export function* submitPdpToCadastralItemAssociaction({ payload: { apiUrl, values, method } }) {
  try {
    const idToken = yield select(selectIdToken)
    yield call(submitItem, apiUrl, idToken, values, method)
    const { data } = yield call(fetchCadastralData, idToken)
    data.cadastralData = normalizeCadastralDataItems(data.cadastralData)
    yield put(submitPdpToCadastralItemAssociationSuccess({ cadastralData: data }))
  }
  catch(error) {
    yield put(submitPdpToCadastralItemAssociationFailure(error))
  }
}

export function* onSubmitPdpToCadastralItemAssociationStart() {
  yield takeLatest(
    CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START,
    submitPdpToCadastralItemAssociaction
  )
}

export function* fetchAvailablePdp({ payload: { apiUrl } }) {
  try {
    const idToken = yield select(selectIdToken)
    const { data } = yield call(fetchAvailablePdpItems, apiUrl, idToken)
    yield put(fetchAvailablePdpSuccess(data))
  }
  catch(error) {
    yield put(fetchAvailablePdpFailure(error))
  }
}

export function* onFetchAvailablePdpStart() {  
  yield takeLatest( 
    CadastralDataTypes.FETCH_AVAILABLE_PDP_START,
    fetchAvailablePdp
  )
}

export function* submitCadastralItemPdpAssociation({ payload: { apiUrl, values, method } }) {
  try {
    const idToken = yield select(selectIdToken)
    yield call(submitItem, apiUrl, idToken, values, method)
    const { data } = yield call(fetchCadastralData, idToken)
    data.cadastralData = normalizeCadastralDataItems(data.cadastralData)
    yield put(submitCadastralItemPdpAssociationSuccess({ cadastralData: data }))
  }
  catch(error) {
    yield put(submitCadastralItemPdpAssociationFailure(error))
  }
}

export function* onSubmitCadastralItemPdpAssociationStart() {
  yield takeLatest(
    CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_START,
    submitCadastralItemPdpAssociation
  )
}

export function* deleteCadastralDataItem({ payload: { apiUrl, cadastralDataId } }) {
  try {
    const idToken = yield select(selectIdToken)
    yield call(deleteItem, apiUrl, idToken)
    yield put(deleteCadastralDataItemSuccess({ cadastralDataId }))
  }
  catch(error) {
    yield put(deleteCadastralDataItemFailure(error))
  }
}

export function* onDeleteCadastralDataItemStart() {
  yield takeLatest(
    CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_START,
    deleteCadastralDataItem
  )
}

export function* submitCadastralDataItem({ payload: { apiUrl, values, method } }) {
  try {
    const idToken = yield select(selectIdToken)
    const { data: selectedCadastralData } = yield call(submitItem, apiUrl, idToken, values, method)
    const { data } = yield call(fetchCadastralData, idToken)
    data.cadastralData = normalizeCadastralDataItems(data.cadastralData)
    yield put(submitCadastralDataItemSuccess({ 
      cadastralData: data,
      selectedCadastralData,
      isNewItem: method === "POST" ? true : false 
    }))
  }
  catch(error) {
    yield put(submitCadastralDataItemFailure(error))
  }
}

export function* onSubmitCadastralDataItemStart() {
  yield takeLatest(
    CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_START,
    submitCadastralDataItem
  )
}

export function* fetchCadastralDataItem({ payload: { apiUrl } }) {
  try {
    const idToken = yield select(selectIdToken)
    const { data } = yield call(fetchCadastralItem, apiUrl, idToken)
    yield put(fetchCadastralDataItemSuccess(data))
  }
  catch(error) {
    yield put(fetchCadastralDataItemFailure(error))
  }
}

export function* onFetchCadastralDataItemStart() {
  yield takeLatest(
    CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_START,
    fetchCadastralDataItem
  )
}

export function* fetchAllCadastralData() {
  try {
    const idToken = yield select(selectIdToken)
    const { data } = yield call(fetchCadastralData, idToken)
    const { data: formItems } = yield call(fetchCadastralFormItems, idToken) 
    data.cadastralData = normalizeCadastralDataItems(data.cadastralData)
    yield put(fetchCadastralDataSuccess({ cadastralData: data, formItems }))
  }
  catch(error) {
    yield put(fetchCadastralDataFailure(error))
  }
}

export function* onFetchAllCadastralDataStart() {
  yield takeLatest(
    CadastralDataTypes.FETCH_CADASTRAL_DATA_START,
    fetchAllCadastralData
  )
}

export function* cadastralDataSagas() {
  yield all([
    call(onFetchAllCadastralDataStart),
    call(onSubmitCadastralDataItemStart),
    call(onFetchCadastralDataItemStart),
    call(onDeleteCadastralDataItemStart),
    call(onSubmitCadastralItemPdpAssociationStart),
    call(onFetchAvailablePdpStart),
    call(onSubmitPdpToCadastralItemAssociationStart),
    call(onAddCadastralDataStart),
    call(onDeletePdpToCadastralItemAssociationStart),
  ])
}