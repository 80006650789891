import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import WithSkeletonStyle from './style'

const WithSkeleton = WrappedComponent => {
  const SkeletonLoader = ({ isLoading, otherClasses, skeletonConfig, ...otherProps }) => {
    return isLoading ? (
      <WithSkeletonStyle
        className={`${otherClasses?.length ? otherClasses.join(' ') : ''}`}
      >
        <header>
          <Skeleton  count={1} height={100} />
        </header>
        <main>
          <Skeleton count={5} />
        </main>
      </WithSkeletonStyle>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return SkeletonLoader
}

export default WithSkeleton
