import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap'
import { Form } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAPIUrl } from '../../../../ABapi'
import { ReactComponent as Bolt } from '../../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../../assets/svg/flame.svg'
import CadastralDataInfoModal from './CadastralDataInfoModal/CadadstralDataInfoModal.component'
import CustomFormField from '../../../CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../../ExtendedSelectField/ExtendedSelectField.component'
import ExtendedCustomZipCodeSelect from '../../../ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import ExtendedAutocompleteFormField from '../../../ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ButtonWithLoader from '../../../ButtonWithLoader/ButtonWithLoader.component'
import WhenFieldChanges from '../../../WhenFieldChanges/WhenFieldChanges.component'
import CadastralDataItemFormStyle from './style'
import { selectIdToken } from '../../../../redux/user/user.selectors'
import { 
  selectCadastralModalVisibility, selectIsSubmittingCadastralItemForm,
  selectFormItems, selectSelectedCadastralData, selectTownsArray,
  selectAvailablePdpItems
} from '../../../../redux/cadastralData/cadastralData.selectors'
import { toggleCadastralDataModal, submitCadastralDataItemStart } from '../../../../redux/cadastralData/cadastralData.actions'
import { filterTowns, required, requiredArray } from './utils'

const CadastralDataItemForm = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(selectIdToken)
  const formItems = useSelector(selectFormItems)
  const availablePdpItems = useSelector(selectAvailablePdpItems)
  const townsArray = useSelector(selectTownsArray)
  const selectedCadastralData = useSelector(selectSelectedCadastralData)
  const toggle = useCallback(() => dispatch(toggleCadastralDataModal()), [dispatch])
  const isOpen = useSelector(selectCadastralModalVisibility)
  const isSubmitting = useSelector(selectIsSubmittingCadastralItemForm('cadastralDataItemSubmit'))
  const [infoVisibility, setInfoVisibility] = useState(false)
  const infoToggle = () => setInfoVisibility(!infoVisibility)

  const filterTownsPromise = useCallback(inputValue => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(filterTowns(inputValue, townsArray))
      }, 300)
    })
  }, [townsArray])

  const onSubmit = useCallback(values => {
    const apiUrl = `${getAPIUrl()}/cadastral-data${selectedCadastralData ? '/' + selectedCadastralData.id : ''}`
    const method = selectedCadastralData ? "PUT" : "POST"
    dispatch(submitCadastralDataItemStart({ apiUrl, values, method }))
  }, [selectedCadastralData, dispatch])

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static" keyboard={false}>
        <CadastralDataItemFormStyle>
          <ModalHeader toggle={toggle}>
            <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
            {selectedCadastralData ? (
              `${selectedCadastralData.address} ${selectedCadastralData.streetNo} ${selectedCadastralData.postalCode?.label} - ${selectedCadastralData.town?.label}`
            ) : (
              "Nuovo dato catastale"
            )}
          </ModalHeader>
          <ModalBody>
            <p className="mb-3">
              <Button 
                onClick={infoToggle}
                className="ab-button mt-2"
              >
                <FontAwesomeIcon icon={['fas', 'circle-info']} />
                Cosa sono i dati catastali?
              </Button>
            </p>
            <Form onSubmit={onSubmit}>
              {({ values, handleSubmit }) => (
                <form onSubmit={handleSubmit} className="cadastral-data-form">
                  <div className="grouped-form">
                    <Row form>
                      <Col md={9}>
                        <CustomFormField
                          name="address"
                          validate={required}
                          placeholder="Indirizzo"
                          label="Indirizzo*"
                          initialValue={selectedCadastralData?.address || null}
                        />
                      </Col>
                      <Col md={3}>
                        <CustomFormField
                          name="streetNo"
                          validate={required}
                          placeholder="Civico"
                          label="Civico*"
                          initialValue={selectedCadastralData?.streetNo || null}
                        />
                      </Col>
                      <Col md={9}>
                        <WhenFieldChanges
                          field="town"
                          set="postalCode"
                          to={null}
                          initialValue={selectedCadastralData?.town || null}
                        />
                        <ExtendedAutocompleteFormField
                          name="town"
                          fieldLabel="Comune*"
                          placeholder="Comune"
                          cacheOptions 
                          validate={required}
                          loadOptions={filterTownsPromise}
                          initialValue={selectedCadastralData?.town || null}
                          noOptionsMessage={() => 
                            <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <ExtendedCustomZipCodeSelect
                          name="postalCode"
                          placeholder="CAP"
                          fieldLabel="CAP*"
                          validate={required}
                          fieldToListen="town"
                          fieldToListenDefaultValue={selectedCadastralData?.town || null}
                          accessToken={accessToken}
                          initialValue={selectedCadastralData?.postalCode || null}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="grouped-form">
                    <Row form>
                      <Col md={6}>
                        <ExtendedSelectField
                          options={formItems?.declarantStatus || []}
                          name="declarantStatus"
                          fieldLabel="Qualifica dichiarante*"
                          placeholder="Qualifica dichiarante"
                          validate={required}
                          defaultValue={null}
                          initialValue={selectedCadastralData?.declarantStatus || null}
                        />
                      </Col>
                      <Col md={6}>
                        <ExtendedSelectField
                          options={formItems?.utilityType || []}
                          name="utilityType"
                          placeholder="Tipo utenza"
                          fieldLabel="Tipo utenza*"
                          validate={required}
                          defaultValue={null}
                          initialValue={selectedCadastralData?.utilityType || null}
                        />
                      </Col>
                      <Col md={6}>
                        <ExtendedSelectField
                          options={formItems?.unitType || []}
                          name="unitType"
                          placeholder="Tipo unità"
                          fieldLabel="Tipo unità*"
                          validate={required}
                          defaultValue={null}
                          initialValue={selectedCadastralData?.unitType || null}
                        />
                      </Col>
                      <Col md={6}>
                        <ExtendedAutocompleteFormField
                          name="cadastralTown"
                          fieldLabel="Comune catastale*"
                          placeholder="Comune catastale"
                          initialValue={selectedCadastralData?.cadastralTown || null}
                          cacheOptions
                          validate={required}
                          loadOptions={filterTownsPromise}
                          noOptionsMessage={() => 
                            <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="grouped-form">
                    <Row form>
                      <Col md={12}>
                        <p className="small">In caso di indisponibilità dei dati catastali identificativi dell’immobile selezionare l’opzione relativa al motivo della mancata comunicazione:</p>
                        <ExtendedSelectField
                          name="dataLack"
                          fieldLabel="Motivo irreperibilità"
                          placeholder="Motivo irreperibilità"
                          options={formItems?.dataLack || []}
                          defaultValue={null}
                          isClearable
                          initialValue={selectedCadastralData?.dataLack || null}
                        />
                      </Col>
                      <WhenFieldChanges
                        field="dataLack"
                        set="section"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="dataLack"
                        set="certificate"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="dataLack"
                        set="particle"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="dataLack"
                        set="subordinate"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="dataLack"
                        set="particleExtension"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="dataLack"
                        set="particleType"
                        to={null}
                      />
                      {!values.dataLack?.value &&
                        <>
                          <Col md={12}>
                            <p className="small">Per gli immobili accatastati indicare gli identificativi catastali: Sezione, Foglio, Numero particella, Subalterno</p>
                          </Col>
                          <Col md={3}>
                            <CustomFormField
                              name="section"
                              type="text"
                              maxLength="50"
                              placeholder="Sezione"
                              label="Sezione"
                              initialValue={selectedCadastralData?.section || null}
                            />
                          </Col>
                          <Col md={3}>
                            <CustomFormField
                              name="certificate"
                              type="text"
                              maxLength="50"
                              placeholder="Foglio"
                              label="Foglio*"
                              validate={required}
                              initialValue={selectedCadastralData?.certificate || null}
                            />
                          </Col>
                          <Col md={3}>
                            <CustomFormField
                              name="particle"
                              type="text"
                              maxLength="50"
                              placeholder="Particella/Mappale"
                              label="Particella/Mappale*"
                              validate={required}
                              initialValue={selectedCadastralData?.particle || null}
                            />
                          </Col>
                          <Col md={3}>
                            <CustomFormField
                              name="subordinate"
                              type="text"
                              maxLength="50"
                              placeholder="Subalterno"
                              label="Subalterno"
                              initialValue={selectedCadastralData?.subordinate || null}
                            />
                          </Col>
                          <Col md={12}>
                            <p className="small">Compilare esclusivamente per gli immobili che appartengono al catasto tavolare:</p>
                          </Col>
                          <Col md={6}>
                            <CustomFormField
                              name="particleExtension"
                              type="text"
                              maxLength="50"
                              placeholder="Estensione Particella"
                              label="Estensione Particella"
                              initialValue={selectedCadastralData?.particleExtension || null}
                            />
                          </Col>
                          <Col md={6}>
                            <ExtendedSelectField
                              options={formItems.particleType || []}
                              name="particleType"
                              placeholder="Tipo particella"
                              fieldLabel="Tipo particella"
                              initialValue={selectedCadastralData?.particleType || null}
                            />
                          </Col>
                        </>
                      }
                    </Row>
                  </div>
                  {!selectedCadastralData &&
                    <div className="grouped-form">
                      <Row form>
                        <Col md={12}>
                          <p className="small">Associa i punti di fornitura a questo dato catastale selezionandoli dall'elenco qui sotto.</p>
                          <ExtendedSelectField 
                            name="supplyPoints"
                            validate={requiredArray}
                            fieldLabel="Seleziona i punti di fornitura"
                            placeholder="Digita i primi caratteri del nome o il numero del punto di fornitura"
                            options={availablePdpItems || []}
                            isMulti
                            getOptionValue={(option) => `${option['alias']}: ${option['number']}`}
                            getOptionLabel={({ supplyType, ...option }) =>
                              <>
                                <span className="mr-2">{supplyType.label === 'Energia' ? <Bolt /> : <Flame />}</span>
                                <strong>{option.alias}</strong>: {option.number}
                              </>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                  <ButtonWithLoader
                    type="submit"
                    outline
                    color="secondary"
                    onClick={handleSubmit}
                    isFetching={isSubmitting}
                    disabled={isSubmitting}
                    buttonLabel="Continua"
                    className="ab-button"
                  />
                </form>
              )}
            </Form>
          </ModalBody>
        </CadastralDataItemFormStyle>
      </Modal>
      <CadastralDataInfoModal 
        isVisible={infoVisibility}
        toggle={infoToggle}
      />
    </>
  )
}

export default CadastralDataItemForm