import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { NavbarBrand, NavbarToggler, Nav, NavItem, Button, Collapse } from 'reactstrap'

import { withRouter } from '../../AppRouter/utils'
import { signOutStart } from '../../../redux/user/user.actions'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { NavigationBarStyle } from './style'
import PageNamesFromPath from '../../../pages/utils/PageNamesFromPath.utils'
import UserAvatar from '../../UserAvatar/UserAvatar.component'
import NavigationMenu from '../NavigationMenu/NavigationMenu.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavigationBar = ({ router: { location } }) => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)

  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const closeMenu = () => setTimeout(() => setIsOpen(false), 800)

  const { name, surname, displayName } = user
  const { pathname } = location
  const nameToShow = name && surname ? `${name} ${surname}` : displayName

  return(
    <NavigationBarStyle light expand="md">
      <NavbarBrand>{PageNamesFromPath[pathname]}</NavbarBrand>

      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink to="/profilo" datatoggle="collapse" title="Vedi profilo utente">
            <UserAvatar />
          </NavLink>
        </NavItem>
        <NavItem className="user-name">
          <span>Ciao, <strong>{nameToShow}</strong>!</span>
        </NavItem>
        <NavItem className="sign-out-btn">
          <Button
            color="link"
            title="Sign out"
            onClick={() => dispatch(signOutStart())}
          >
            <FontAwesomeIcon icon={['fas', 'sign-out']} className="sign-out-btn" />
          </Button>
        </NavItem>
      </Nav>
      <NavbarToggler 
        onClick={toggle}
        className={`${isOpen ? 'menu-open' : ''}`}
      >
        <span></span>
        <span></span>
        <span></span>
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar onClick={closeMenu}>
        <NavigationMenu vertical />
      </Collapse>
    </NavigationBarStyle>
  )
}

export default withRouter(NavigationBar)
