import styled from 'styled-components'
import { Nav } from 'reactstrap'

export const NavigationMenuStyle = styled(Nav)`
  height: auto;
  background-color: transparent;

  margin-top: 5rem;
  padding: 0 15px;

  @media (max-width: 768px) {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    padding: 0;
  }
  
  .nav-item {
    margin-bottom: .5rem;
    width: 100%;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.navigationMenuItemBorderColor};

    &:first-child {
      border-top: 1px solid;
      border-color: ${({ theme }) => theme.navigationMenuItemBorderColor};
    }

    &.sign-out {
      @media (min-width: 768px) {
        display: none;
      }

      #disconnetti {
        width: 2rem;
  
        .st0 {
          fill: ${({ theme }) => theme.navigationMenuLinkIcon};
          transition: fill .2s ease;
        }
      }
  
      &:hover {
        #disconnetti {
          transform: scale(1.05);
         
          .st0 {
            fill: ${({ theme }) => theme.navigationBarSignOutHoverColor};
          }
        }
      }
    }

    a, button {
      display: flex;
      padding: .75rem .5rem;
      align-items: center;
      text-decoration: none;
      position: relative;
      width: 100%;
      z-index: 1;
      color: ${({ theme }) => theme.navigationMenuLink};
      transition: color .2s ease;

      @media (max-width: 767.9px) {
        padding-left: 0;
        padding-right: 0;
      }

      &:before {
        background-color: ${({ theme }) => theme.mainTitleColor}
      }
      &:after {
        background-color: ${({ theme }) => theme.mainBAckgroundActiveColor};
      }

      &:hover {
        .link-text {
          transform: scale(.95);
        }
      }

      &.active {
        color: ${({ theme }) => theme.navigationMenuLinkActive};
      }

      .link-text {
        font-weight: 600;
        transition: transform .2s ease-out;
      }

      .link-icon {
        margin-right: 1rem;
        font-size: 1.15rem;
        flex-basis: 15%;
        text-align: center;
        display: inline-flex;
        color: ${({ theme }) => theme.navigationMenuLinkIcon};

        .custom-icon {
          max-width: 1.5rem;
          height: 1.5rem;
          fill: ${({ theme }) => theme.navigationMenuLinkIcon};
        }
      }
    }
  }
`
