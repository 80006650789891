import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const ElectricalOfferDetails = ({ number, power, powerUnitOfMeasure, voltage, status }) => (
  <div className="data-box electrical">
    <h3 className="box-title electrical">
      Caratteristiche tecniche
    </h3>
    <div className="supply-point-data">
      <div className="data-group">
        <label>POD:</label>
        <span className="code">{number}</span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Potenza:</label>
        <span className="code">
          {power && powerUnitOfMeasure ? (
            `${power} ${powerUnitOfMeasure}`
          ) : (
            <FontAwesomeIcon title="Dato mancante" icon={['fas', 'question']} />
          )}
        </span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Tensione:</label>
        <span className="code">
          {voltage ? voltage : <FontAwesomeIcon title="Dato mancante" icon={['fas', 'question']} />}
        </span>
      </div>
    </div>
    <div className="supply-point-data">
      <div className="data-group">
        <label>Stato fornitura:</label>
        <span 
          className={classNames({
            "code": true,
            "pdp-status": true,
            "closed": status === "closed",
          })}
        >
          {status === 'closed' ? (
            <>
              <FontAwesomeIcon icon="circle-xmark" className="mr-1" /> Cessato
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-check" className="mr-1" /> Attivo
            </>
          )}
        </span>
      </div>
    </div>
  </div>
)

export default ElectricalOfferDetails
