import UserActionTypes from './user.types'
import UserLoginSteps from '../../pages/LoginPage/loginSteps'
import {
  setUpCustomerCodes, fetchItem, updateItem,
  toggleItemSubmitting, initThemeMode, setThemeMode
} from './user.utils'

const INITIAL_STATE = {
  maintenanceState: undefined,
  reservedAreaEnabled: false,
  resetPasswordDone: false,
  signUpStep: UserLoginSteps.SIGN_IN_STEP,
  signUpEmail: null,
  signUpClientCode: null,
  signUpMethodDescription: '',
  oobCode: null,
  currentUser: undefined,
  otpMethods: [],
  otpTransactionId: null,
  isLoading: true,
  isSubmitting: false,
  customerCodeModalVisiblity: false,
  error: null,
  utilityModal: {
    isVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case UserActionTypes.RESET_LOGIN_FORM:
      return {
        ...INITIAL_STATE,
        isLoading: false
      }

    case UserActionTypes.TOGGLE_USER_THEME:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          theme: setThemeMode(state.currentUser.theme)
        }
      }

    case UserActionTypes.GO_TO_NEXT_STEP:
      return {
        ...state,
        signUpStep: state.signUpStep + 1
      }

    case UserActionTypes.GO_TO_STEP:
      return {
        ...state,
        signUpStep: action.payload
      }

    case UserActionTypes.CHECK_USER_EMAIL_PROVIDER_START:
      return {
        ...state,
        isSubmitting: true,
        signUpEmail: action.payload.email
      }

    case UserActionTypes.CHECK_USER_EMAIL_PROVIDER_IS_NEW_USER:
      return {
        ...state,
        isSubmitting: false,
        signUpStep: UserLoginSteps.USER_DATA_STEP
      }

    case UserActionTypes.CHECK_USER_EMAIL_PROVIDER_ALREADY_EXISTS:
      return {
        ...state,
        isSubmitting: false,
        signUpStep: UserLoginSteps.USER_PASSWORD_STEP
      }

    case UserActionTypes.CHECK_USER_EMAIL_PROVIDER_USED_BY_OTHER:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: action.payload.modalTitle,
          modalDescription: action.payload.modalDescription,
          modalIcon: action.payload.modalIcon,
          isVisible: true
        }
      }

    case UserActionTypes.CHECK_USER_SESSION_NO_USER: 
      return {
        ...state,
        isLoading: false,
        isSubmitting: false
      }

    case UserActionTypes.CHECK_USER_EMAIL_PROVIDER_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case UserActionTypes.GOOGLE_SIGN_IN_START:
    case UserActionTypes.FACEBOOK_SIGN_IN_START:
    case UserActionTypes.APPLE_SIGN_IN_START:
    case UserActionTypes.SIGN_UP_WITH_EMAIL_START:
    case UserActionTypes.EMAIL_SIGN_IN_START:
    case UserActionTypes.REGISTER_CLIENT_CODE_START:
    case UserActionTypes.VERIFY_OTP_SIGNATURE_START:
    case UserActionTypes.CONFIRM_RESET_PASSWORD_START:
    case UserActionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        isSubmitting: true
      }

    case UserActionTypes.SIGN_OUT_START:
      return {
        ...state,
        isLoading: true
      }

    case UserActionTypes.CHECK_USER_SESSION_START:
      return {
        ...state,
        isLoading: true
      }

    case UserActionTypes.CHECK_USER_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case UserActionTypes.SEND_OTP_REQUEST_START:
      return {
        ...state,
        isSubmitting: true,
        signUpMethodDescription: action.payload.signUpMethodDescription
      }

    case UserActionTypes.SIGN_UP_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          theme: initThemeMode(action.payload),
          customerCodes: setUpCustomerCodes(action.payload)
        },
        isSubmitting: false,
        signUpStep: UserLoginSteps.USER_CLIENT_CODE
      }

    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          theme: initThemeMode(action.payload),
          customerCodes: setUpCustomerCodes(action.payload)
        },
        maintenanceState: action.payload.maintenanceState,
        isSubmitting: false,
        isLoading: false,
        signUpStep: UserLoginSteps.USER_CLIENT_CODE,
        error: null
      }

    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.GET_CLIENT_OTP_METHODS_FAILURE:
    case UserActionTypes.SEND_OTP_REQUEST_FAILURE:
    case UserActionTypes.VERIFY_OTP_SIGNATURE_FAILURE:
    case UserActionTypes.RESET_PASSWORD_FAILURE:
    case UserActionTypes.SIGN_UP_WITH_EMAIL_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: action.payload.error?.code !== "auth/popup-closed-by-user" ? true : false
        }
      }

    case UserActionTypes.VERIFY_OTP_SIGNATURE_RESET:
      return {
        ...state,
        isSubmitting: false,
        signUpStep: UserLoginSteps.OTP_TYPE_STEP,
        error: action.payload
      }

    case UserActionTypes.CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordDone: true,
        isSubmitting: false,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: action.payload.modalTitle,
          modalDescription: action.payload.modalDescription,
          modalIcon: action.payload.modalIcon,
          isVisible: true
        }
      }

    case UserActionTypes.CONFIRM_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: action.payload.modalTitle,
          modalDescription: action.payload.modalDescription,
          modalIcon: action.payload.modalIcon,
          isVisible: true
        }
      }

    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        isLoading: false
      }

    case UserActionTypes.GET_CLIENT_OTP_METHODS_START:
      return {
        ...state,
        isSubmitting: true,
        signUpClientCode: action.payload.clientCode
      }

    case UserActionTypes.GET_CLIENT_OTP_METHODS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        otpMethods: action.payload,
        signUpStep: UserLoginSteps.OTP_TYPE_STEP,
        error: null
      }

    case UserActionTypes.SEND_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        otpTransactionId: action.payload.transactionId,
        signUpStep: UserLoginSteps.USER_OTP_SIGN_STEP,
        error: null
      }

    case UserActionTypes.DELETE_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        currentUser: {
          ...state.currentUser,
          customerCodes: setUpCustomerCodes(action.payload)
        }
      }

    case UserActionTypes.VERIFY_OTP_SIGNATURE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        signUpStep: UserLoginSteps.USER_CLIENT_CODE,
        currentUser: {
          ...state.currentUser,
          customerCodes: setUpCustomerCodes(action.payload)
        },
        customerCodeModalVisiblity: false
      }

    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        signUpStep: UserLoginSteps.SIGN_IN_STEP,
        error: null
      }

    case UserActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        currentUser: action.payload
      }

    case UserActionTypes.SET_OOB_CODE:
      return {
        ...state,
        oobCode: action.payload
      }

    case UserActionTypes.UPDATE_USER_PROFILE_START:
      return {
        ...state,
        isSubmitting: true
      }

    case UserActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        currentUser: {
          ...state.currentUser,
          name: action.payload.name,
          surname: action.payload.surname,
          displayName: action.payload.displayName,
          mobileNumber: action.payload.mobileNumber,
          profilePicture: action.payload.profilePicture
        }
      }

    case UserActionTypes.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error:  action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case UserActionTypes.FETCH_CUSTOMER_DATA_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          customerCodes: fetchItem(state, action.payload)
        }
      }

    case UserActionTypes.FETCH_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          customerCodes: updateItem(state, action.payload)
        }
      }

    case UserActionTypes.FETCH_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        currentUser: {
          ...state.currentUser,
          customerCodes: updateItem(state, action.payload)
        },
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case UserActionTypes.DELETE_CUSTOMER_DATA_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          customerCodes: toggleItemSubmitting(state, action.payload)
        }
      }

    case UserActionTypes.DELETE_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          customerCodes: toggleItemSubmitting(state, action.payload)
        },
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case UserActionTypes.UPDATE_USER_TOKEN:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      }

    case UserActionTypes.TOGGLE_CUSTOMER_CODE_MODAL:
      return {
        ...state,
        customerCodeModalVisiblity: !state.customerCodeModalVisiblity
      }

    case UserActionTypes.TOGGLE_USER_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    case UserActionTypes.USER_UPDATE_MAINTENANCE_STATE:
      return {
        ...state,
        maintenanceState: action.payload.maintenanceState
      }

    default:
      return state
  }
}

export default userReducer
