import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap'

import ForgotPasswordModalStyle from './style'
import { resetPasswordStart } from '../../../redux/user/user.actions'
import { selectSignUpEmail } from '../../../redux/user/user.selectors'

const ForgotPasswordModal = () => {
  const dispatch = useDispatch()
  const signUpEmail = useSelector(selectSignUpEmail)
  const [ isVisible, setIsVisible ] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  const handleResetPassword = () => {
    dispatch(resetPasswordStart({ email: signUpEmail }))
    toggle()
  }

  return(
    <ForgotPasswordModalStyle>
      <Button
        onClick={toggle}
        className="forgot-password-btn"
        color="link"
      >
        Password dimenticata?
      </Button>
      <Modal isOpen={isVisible} toggle={toggle} className="forgot-password-modal">
        <ModalHeader toggle={toggle}>
          Ho dimenticato la password
        </ModalHeader>
        <ModalBody>
          <p>
            Se confermi, ti invieremo un'email contenente un link che ti porterà alla pagina di reset
            {' '}
            della password, dalla quale potrai inserirne una nuova!
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            color="secondary"
            className="ab-button"
            onClick={handleResetPassword}
          >
            Conferma
          </Button>
        </ModalFooter>
      </Modal>
    </ForgotPasswordModalStyle>
  )
}

export default ForgotPasswordModal
