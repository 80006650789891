import { connect } from "react-redux"
import { compose } from "redux"

import CadastralDataOverview from "./CadastralDataOverview.component"
import WithSpinner from "../../WithSpinner/WithSpinner.component"
import { selectIsFetchingCadastralData } from "../../../redux/cadastralData/cadastralData.selectors"

const mapStateToProps = state => ({
  isLoading: selectIsFetchingCadastralData("allCadastralDataFetch")(state)
})

const CadastralDataOverviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(CadastralDataOverview)

export default CadastralDataOverviewContainer