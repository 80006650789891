import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Form } from 'react-final-form'

import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import { updateSupplyPointAliasStart } from '../../../redux/supplyPoints/supplyPoints.actions'
import { selectIsSupplyPointSubmitting } from '../../../redux/supplyPoints/supplyPoints.selectors'
import { required } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditAliasModal = ({ item: { alias, number, serviceType }, updateData }) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSupplyPointSubmitting)

  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const onSubmit = (values) => {
    dispatch(updateSupplyPointAliasStart(values))
    toggle()
  }

  return (
    <>
      <Button 
        disabled={isSubmitting} 
        color="link" 
        className="ab-button edit-alias"
        onClick={toggle}
      >
        <FontAwesomeIcon icon={['fas', 'pen']} className="edit-alias-icon" />
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} className="edit-alias-modal">
        <ModalHeader toggle={toggle}>
          Modifica il nome <strong>{alias}</strong>
        </ModalHeader>
        <ModalBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values, submitError }) => (
            <form autoComplete="off" onSubmit={handleSubmit} className="self-meter-read-form">
              <div className="self-meter-inputs-container">
                <CustomFormField
                  type="hidden"
                  name="number"
                  defaultValue={number}
                />
                <CustomFormField
                  type="hidden"
                  name="supplyPointType"
                  defaultValue={serviceType}
                />
                <CustomFormField
                  id="alias"
                  name="alias"
                  type="text"
                  placeholder="Inserisci il nuovo nome"
                  validate={required}
                  className="my-2"
                />
              </div>
              {submitError && <div className="submit-error">{submitError}</div>}
              <ButtonWithLoader
                type="submit"
                otherClasses="ab-button"
                buttonLabel="Salva"
                onClick={handleSubmit}
                isFetching={isSubmitting}
                disabled={isSubmitting}
              />
            </form>
          )}
        </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditAliasModal
