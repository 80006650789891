import { combineReducers } from 'redux'

import userReducer from './user/user.reducer'
import supplyPointsReducer from './supplyPoints/supplyPoints.reducer'
import billsReducer from './bills/bills.reducer'
import selfMeterReadReducer from './selfMeterRead/selfMeterRead.reducer'
import cadastralDataReducer from './cadastralData/cadastralData.reducer'

const rootReducer = combineReducers({
  user: userReducer,
  supplyPoints: supplyPointsReducer,
  bills: billsReducer,
  selfMeterRead: selfMeterReadReducer,
  cadastralData: cadastralDataReducer,
})

export default rootReducer
