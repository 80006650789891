import styled from 'styled-components'

const UserOtpSignFormStyle = styled.div`
  .new-otp-code {
    font-size: .85rem;
    color: ${({ theme }) => theme.otpSignNewCodeLinkColor};
    display: flex;
    align-items: center;
    padding: .375rem 0;

    svg {
      margin-right: .25rem;
    }
  }
`

export default UserOtpSignFormStyle