import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import moment from 'moment'

import SelfMeterReadItemDataStyle from './style'
import { selectSelfMeterReadItem } from '../../../redux/selfMeterRead/selfMeterRead.selectors'
import SelfMeterReadModalForm from '../SelfMeterReadModalForm/SelfMeterReadModalForm.component'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'

const SelfMeterReadItemData = ({ customerCode, number }) => {
  const {
    alias, last, next, available,
    delivery: { address, city }
  } = useSelector(state => selectSelfMeterReadItem(customerCode, number)(state))

  //Modal state
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <SelfMeterReadItemDataStyle>
        <header>
          <span className="alias">{alias}</span>
          <div className="type-icon" title="Fornitura energia">
            <Flame className="custom-icon" />
          </div>
        </header>
        <main>
          <div className="supply-point-data">
            <div className="data-group">
              <label>PDR:</label>
              <span className="code">{number}</span>
            </div>
          </div>
          <div className="supply-point-data">
            <div className="data-group">
              <label>Indirizzo</label>
              <span className="code">
                {address}, {city}
              </span>
            </div>
          </div>
          {last &&
            <div className="supply-point-data bordered">
              <h3 className="data-title">Ultima autolettura</h3>
              <div className="self-read-data">
                <div className="data-group">
                  <label>Data</label>
                  <span className="code">{moment(last.date).format("DD/MM/YYYY")}</span>
                </div>
                <div className="data-group">
                  <label>Valore</label>
                  <span className="code">{last.value} {last.unitOfMeasure}</span>
                </div>
              </div>
            </div>
          }
          {next &&
            <div className="supply-point-data bordered">
              <h3 className="data-title">Periodo prossima autolettura</h3>
              <div className="self-read-data">
                <div className="data-group">
                  <label>Dal</label>
                  <span className="code">{moment(next.from).format("DD/MM/YYYY")}</span>
                </div>
                <div className="data-group">
                  <label>Al</label>
                  <span className="code">{moment(next.to).format("DD/MM/YYYY")}</span>
                </div>
              </div>
            </div>
          }
        </main>
        <footer>
          <Button
            className="ab-button"
            onClick={toggle}
            disabled={!available}
            title={!available && next ? (
              `È possibile inserire l'autolettura dal ${moment(next.from).format("DD/MM/YYYY")} al ${moment(next.to).format("DD/MM/YYYY")}`
            ) : (
              'Inserisci autolettura'
            )}
          >
            <Flame className="custom-icon" />
            Inserisci
          </Button>
        </footer>
      </SelfMeterReadItemDataStyle>
      <SelfMeterReadModalForm
        isOpen={isOpen}
        toggle={toggle}
        number={number}
        customerCode={customerCode}
      />
    </>
  )
}

export default SelfMeterReadItemData
