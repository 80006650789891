import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { Button } from 'reactstrap'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TableRowStyle } from './style'
import { fetchDocumentStart } from '../../../redux/bills/bills.actions'
import { selectItems, selectSorting } from '../../../redux/bills/bills.selectors'
import { ReactComponent as Bolt } from '../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'
import { getPaymentStatusIcon } from './utils'

const BillsListBody = () => {
  const dispatch = useDispatch()
  const items = useSelector(selectItems)
  const sorting = useSelector(selectSorting)

  return (
    <>
      <TableRowStyle />
      {!items.length &&
        <tr>
          <td className="no-results" colSpan="9">Nessun risultato trovato.</td>
        </tr>
      }
      {items.map((bill, i) => (
        <tr key={i}>
          <td
            className={classnames({ 'bill-number': true, 'active-col': sorting.indexOf('number') !== -1 })}
            title={`${bill.type === 2 ? 'Nota di credito' : 'Bolletta'}`}
          >
            {bill.serviceType === 1 ?
              <Bolt className="custom-icon" title="Energia" />
              :
              <Flame className="custom-icon" title="Gas" />
            }
            <span>{bill.number}</span>
          </td>
          {/*<td>{bill.paymentCode}</td>*/}
          <td>{bill.billing.address}, {bill.billing.city}</td>
          <td className={classnames({ 'active-col': sorting.indexOf('date') !== -1 })}>
            {moment(bill.date).format("DD/MM/YYYY")}
          </td>
          <td>
            {moment(bill.startDate).format("DD/MM/YYYY")}
            <br />
            {moment(bill.endDate).format("DD/MM/YYYY")}
          </td>
          <td className={classnames({ 'amount': true, 'active-col': sorting.indexOf('amount') !== -1 })}>
            <span
              title={`${bill.localizedPaymentStatus}`}
              className={`bill-status bill-status-${bill.paymentStatus}`}
            >
              <FontAwesomeIcon className="icon-status" icon={['fad', getPaymentStatusIcon(bill.paymentStatus)]}/>
              <span className="total-amount">{bill.amount}€</span>
            </span>
          </td>
          <td className={classnames({ 'active-col': sorting.indexOf('consumption') !== -1 })}>
            {bill.consumption} {bill.unitOfMeasure}
          </td>
          <td>
            {moment(bill.dueDate).format("DD/MM/YYYY")}
          </td>
          <td className="bill-file">
            <Button
              onClick={() => dispatch(fetchDocumentStart({ documentId: bill.normalizedNumber, documentType: 1 }))}
              className="document-link"
              color="link"
              title="Vedi documento"
            >
              <FontAwesomeIcon icon={['fas', 'file-invoice']} />
            </Button>
          </td>
          <td className="bill-file detail">
            <Button
              onClick={() => dispatch(fetchDocumentStart({ documentId: bill.normalizedNumber, documentType: 2 }))}
              className="document-link"
              color="link"
              title="Vedi documento"
            >
              <FontAwesomeIcon icon={['fas', 'file-invoice']} />
            </Button>
          </td>
        </tr>
      ))}
    </>
  )
}

export default BillsListBody
