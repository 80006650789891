import styled from 'styled-components'

const BillsListStyle = styled.div`
  position: relative;
  margin: 1.5rem 0;

  > nav[aria-label="pagination"] {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .table-container {
    margin-top: 1.5rem;
  }

  .pagination {
    display: inline-flex;
    font-size: .75rem;
    text-align: center;
    margin: 0;
    border: none;

    .page-item {
      margin: 0 .25rem;

      button {
        font-weight: 600;
        color: ${({ theme }) => theme.billsPaginationButtonColor};
        border: none;
        background-color: ${({ theme }) => theme.billsPaginationBkg};
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.disabled {
        button {
          opacity: .5;
        }
      }

      &.active {
        button {
          background-color: ${({ theme }) => theme.billsPaginationActiveButtonBkg};
          color: ${({ theme }) => theme.billsPaginationActiveButtonColor};
        }
      }
    }
  }
`
export default BillsListStyle
