const UserActionTypes = {
  TOGGLE_USER_UTILITY_MODAL_VISIBILITY: 'TOGGLE_USER_UTILITY_MODAL_VISIBILITY',
  TOGGLE_USER_THEME: 'TOGGLE_USER_THEME',

  RESET_LOGIN_FORM: 'RESET_LOGIN_FORM',

  GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
  GO_TO_STEP: 'GO_TO_STEP',
  TOGGLE_CUSTOMER_CODE_MODAL: 'TOGGLE_CUSTOMER_CODE_MODAL',

  CHECK_USER_SESSION_START: 'CHECK_USER_SESSION_START',
  CHECK_USER_SESSION_NO_USER: 'CHECK_USER_SESSION_NO_USER',
  CHECK_USER_SESSION_FAILURE: 'CHECK_USER_SESSION_FAILURE',
  UPDATE_USER_TOKEN: 'UPDATE_USER_TOKEN',

  UPDATE_USER_DATA: 'UPDATE_USER_DATA',

  CHECK_USER_EMAIL_PROVIDER_START: 'CHECK_USER_EMAIL_PROVIDER_START',
  CHECK_USER_EMAIL_PROVIDER_IS_NEW_USER: 'CHECK_USER_EMAIL_PROVIDER_IS_NEW_USER',
  CHECK_USER_EMAIL_PROVIDER_ALREADY_EXISTS: 'CHECK_USER_EMAIL_PROVIDER_ALREADY_EXISTS',
  CHECK_USER_EMAIL_PROVIDER_USED_BY_OTHER: 'CHECK_USER_EMAIL_PROVIDER_USED_BY_OTHER',
  CHECK_USER_EMAIL_PROVIDER_FAILURE: 'CHECK_USER_EMAIL_PROVIDER_FAILURE',

  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
  FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
  APPLE_SIGN_IN_START: 'APPLE_SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  SIGN_UP_WITH_EMAIL_START: 'SIGN_UP_WITH_EMAIL_START',
  SIGN_UP_WITH_EMAIL_SUCCESS: 'SIGN_UP_WITH_EMAIL_SUCCESS',
  SIGN_UP_WITH_EMAIL_FAILURE: 'SIGN_UP_WITH_EMAIL_FAILURE',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CONFIRM_RESET_PASSWORD_START: 'CONFIRM_RESET_PASSWORD_START',
  CONFIRM_RESET_PASSWORD_SUCCESS: 'CONFIRM_RESET_PASSWORD_SUCCESS',
  CONFIRM_RESET_PASSWORD_FAILURE: 'CONFIRM_RESET_PASSWORD_FAILURE',

  SET_OOB_CODE: 'SET_OOB_CODE',

  GET_CLIENT_OTP_METHODS_START: 'GET_CLIENT_OTP_METHODS_START',
  GET_CLIENT_OTP_METHODS_SUCCESS: 'GET_CLIENT_OTP_METHODS_SUCCESS',
  GET_CLIENT_OTP_METHODS_FAILURE: 'GET_CLIENT_OTP_METHODS_FAILURE',

  SEND_OTP_REQUEST_START: 'SEND_OTP_REQUEST_START',
  SEND_OTP_REQUEST_SUCCESS: 'SEND_OTP_REQUEST_SUCCESS',
  SEND_OTP_REQUEST_FAILURE: 'SEND_OTP_REQUEST_FAILURE',

  VERIFY_OTP_SIGNATURE_START: 'VERIFY_OTP_SIGNATURE_START',
  VERIFY_OTP_SIGNATURE_SUCCESS: 'VERIFY_OTP_SIGNATURE_SUCCESS',
  VERIFY_OTP_SIGNATURE_FAILURE: 'VERIFY_OTP_SIGNATURE_FAILURE',
  VERIFY_OTP_SIGNATURE_RESET: 'VERIFY_OTP_SIGNATURE_RESET',

  UPDATE_USER_PROFILE_START: 'UPDATE_USER_PROFILE_START',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  FETCH_CUSTOMER_DATA_START: 'FETCH_CUSTOMER_DATA_START',
  FETCH_CUSTOMER_DATA_SUCCESS: 'FETCH_CUSTOMER_DATA_SUCCESS',
  FETCH_CUSTOMER_DATA_FAILURE: 'FETCH_CUSTOMER_DATA_FAILURE',

  DELETE_CUSTOMER_DATA_START: 'DELETE_CUSTOMER_DATA_START',
  DELETE_CUSTOMER_DATA_SUCCESS: 'DELETE_CUSTOMER_DATA_SUCCESS',
  DELETE_CUSTOMER_DATA_FAILURE: 'DELETE_CUSTOMER_DATA_FAILURE',

  USER_UPDATE_MAINTENANCE_STATE: 'USER_UPDATE_MAINTENANCE_STATE',
}

export default UserActionTypes
