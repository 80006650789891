import styled from 'styled-components'

export const BillsListFiltersStyle = styled.div`
  margin: .5rem 0;

  .card {
    border-color: transparent;
    border-right: none;
    border-bottom: none;
    box-shadow: ${({ theme }) => theme.billsFiltersBoxShadow};
    background-color: transparent;
  }

  .filter-toggler {
    margin-bottom: 1.5rem;

    &.filters-open {
      background-color: transparent;
      border: ${({ theme }) => theme.billsFiltersTogglerOpenBorder};
      color: ${({ theme }) => theme.abButtonOutlineColor};

      &:focus {
        > svg path {
          fill: ${({ theme }) => theme.abButtonColorHover};
        }
      }
    }
  }

  .filters-form {
    padding: .5rem;

    legend {
      font-size: .85rem;
      font-weight: 700;
      margin-bottom: .5rem;
      color: ${({ theme }) => theme.billsFiltersLegendColor};
      padding-bottom: .15rem;
      border-bottom: 1px solid #cecece;

      > svg {
        font-size: 1.05rem;
        margin-right: .5rem;
      }
    }

    .form-buttons {
      display: flex;
      justify-content: flex-end;
    }

    .form-check-label {
      font-size: .85rem;
    }

    .reset-form {
      margin-right: .5rem;
      text-decoration: underline;
      color: ${({ theme }) => theme.billsFiltersResetBtnColor};
      font-weight: 700;
    }
  }
`
