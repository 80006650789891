import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'
import { facebookSignInStart } from '../../../redux/user/user.actions'

const FacebookSignInButton = () => {
  const dispatch = useDispatch()

  return(
    <Button
      color="primary"
      onClick={() => dispatch(facebookSignInStart())}
      className="fb-btn"
    >
      <FontAwesomeIcon size="lg" icon={['fab', 'facebook']} />
      Continua con Facebook
    </Button>
  )
}

export default FacebookSignInButton
