import { createSelector } from 'reselect'

const selectSupplyPoints = state => state.supplyPoints

export const selectIsFetchingSupplyPoints = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.isLoading
)

export const selectSupplyPointsItems = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.items
)

export const selectSupplyPointItemDetail = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.itemDetail
)

export const selectIsSupplyPointsLoaded = createSelector(
  [selectSupplyPointsItems],
  supplyPointsItems => !!supplyPointsItems.items
)

export const selectIsFetchingSupplyPointDetail = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.itemDetail === null
)

export const selectIsSupplyPointSubmitting = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.isSubmitting
)

export const selectUtilityModal = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints.utilityModal
)
