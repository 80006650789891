import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UserProfilePageStyle from './style'
import UserProfile from '../../components/customer-area/UserProfile/UserProfile.component'
import CustomersDataContainer from '../../components/customer-area/CustomersData/CustomersDataContainer.component'
import ScrollToSeeMore from '../../components/customer-area/ScrollToSeeMore/ScrollToSeeMore.component'
import { toggleScrollToSeeVisibility } from './utils'
import { selectCustomerCodesArray } from '../../redux/user/user.selectors'
import { fetchCustomerDataStart } from '../../redux/user/user.actions'

const UserProfilePage = () => {
  const dispatch = useDispatch()
  const customerCodesArray = useSelector(selectCustomerCodesArray)

  const handleScroll = e => {
    const scrollLeft = e.currentTarget.scrollLeft
    const toggleElement = e.currentTarget.querySelector(".scroll-to-see-more")
    toggleScrollToSeeVisibility(scrollLeft, toggleElement)
  }

  useEffect(() => {
    if(customerCodesArray.length) {
      customerCodesArray.forEach(code => dispatch(fetchCustomerDataStart({ customerCode: code } )))
    }
  }, [customerCodesArray, dispatch])

  return (
    <UserProfilePageStyle>
      <UserProfile />
      <div className="customers-data" onScroll={customerCodesArray.length > 1  ? handleScroll : null}>
        {customerCodesArray.map((code, i) => (
          <CustomersDataContainer
            key={i}
            customerCode={code}
          />
        ))}
        <ScrollToSeeMore />
      </div>
    </UserProfilePageStyle>
  )
}

export default UserProfilePage
