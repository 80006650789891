export const lightTheme = {
  mainBackgroundColor: '#F2F4F4',
  itemBackgroundColor: '#fff',

  //Custom Icons
  customIconVioletFill: '#7B48DA',
  customIconYellowFill: '#FFF240',

  //Login page
  loginPageBkg: 'linear-gradient(90deg,rgb(45,83,111) 0%,rgb(5,70,105) 100%)',

  //Navigation side menu
  navigationMenuBkgColor: '#0065a7',
  navigationMenuLink: '#fff',
  navigationMenuItemBorderColor: '#007dcf',
  navigationMenuLinkActive: '#ffc524',
  navigationMenuLinkIcon: '#fff',

  //Navigation bar (TOP)
  navigationBarBrandColor: '#fff',
  navigationBarBoxShadow: '0px 0px 16px #00000019;',
  navigationBarBkg: '#0065a7',
  navigationBarUserNameColor: '#fff',
  navigationBarUserAvatarBkgColor: '#ffc524',
  navigationBarUserAvatarBorder: '1px solid #fff',
  navigationBarSignOutColor: '#fff',
  navigationBarSignOutHoverColor: '#ffc524',
  navigationBarUserAvatarDefaultUserColor: '#0065a7',
  navigationBarBkgColorMobile: '#0065a7',
  navigationBarBrandColorMobile: '#fff',
  navbarTogglerBkg: '#ffc524',

  //Forms
  inputBorderColor: '#ddd',
  hoverInputBorderColor: '#0065a7',
  inputColor: '#5F5F5F',
  formLabelColor: '#0065a7',
  userOtpTypeTitleColor: '#0065a7',
  otpSignNewCodeLinkColor: '#0065a7',
  customSwitchBorderColor: '#ddd',
  customSwitchControlBkg: '#adb5bd',
  dropzoneBorderColor: '#ddd',
  focusedOption: '#c9e1f1',
  selectedOption: '#0065a7',

  //Buttons
  updateDataButtonBkg: '#0065a7',
  updateDataButtonColor: '#fff',
  updateDataButtonHoverBkg: '#fff',
  updateDataButtonHoverColor: '#0065a7',
  scrollToSeeMoreArrowBkg: '#0065a7',
  scrollToSeeMoreArrowColor: '#fff',
  abButtonColor: '#fff',
  abButtonBkg: '#0065a7',
  abButtonBkgHover: '#fff',
  abButtonColorHover: '#0065a7',
  abButtonBorderColorHover: '#0065a7',
  abButtonOutlineColor: '#0065a7',
  abButtonOutlineBorder: '1px solid #0065a7',
  abButtonOutlineBkg: 'transparent',
  deleteButtonColor: '#FF1100',
  deleteButtonBorder: '2px solid #FF1100',
  deleteButtonBkg: 'transparent',
  deleteButtonHoverBkg: '#ffd9d7',
  deleteButtonHoverBorder: '2px solid #fff',

  //Modals
  modalBackdropBkg: 'rgb(78 31 205 / 60%)',
  modalHeaderBkg: '#0065a7',
  modalHeaderColor: '#fff',
  modalBodyBkg: '#fff',
  modalBodyTextColor: '#5F5F5F',
  editAliasModalHeaderTitleColor: '#ddd',
  downloadAppModalLinkColor: '#4F0BCD',
  descriptionText: '#5F5F5F',

  //Alert
  alertWarningBackgroundColor: '#ffefc8',
  alertWarningTextColor: '#9c6b2e',
  alertWarningBorderColor: '#f0bb79',
  alertSuccessBackgroundColor: '#dbffc8',
  alertSuccessTextColor: '#0f5132',
  alertSuccessBorderColor: '#7de083',

  //Data group
  dataGroupTextColor: '#666',
  dataGroupCodeColor: '#0065a7',

  //Supply points
  customerCodeColor: '#0065a7',
  customerCodeBorderColor: '#ddd',
  supplyPointPreviewBoxShadow: '0px 0px 16px #00000019;',
  supplyPointPreviewElectricTextColor: '#fff',
  supplyPointPreviewGasTextColor: '#fff',
  supplyPointPreviewGasMainTextColor: '#4E21CD',
  supplyPointPreviewElectricHeaderBkg: 'linear-gradient(90deg, rgb(22, 132, 95) 0%, rgb(63, 159, 74) 75%, rgb(103, 186, 52) 100%)',
  supplyPointPreviewGasHeaderBkg: 'linear-gradient(90deg, rgb(1, 100, 167) 0%, rgb(0, 159, 248) 100%)',
  supplyPointPreviewClosedBkg: "#b1350d",

  //Supply point detail
  backButtonColor: '#0065a7',
  backButtonHoverColor: '#666',
  supplyPointAliasColor: '#0065a7',
  editAliasButtonColor: '#ffc524',
  editAliasButtonColorHover: '#666',
  supplyTypeIconColor: '#4F0BCD',
  supplyPointHeaderBorderColor: '#ddd',
  supplyPointGreenOptionColor: '#0065a7',
  supplyPointDataBoxTitleColor: '#0065a7',
  supplyPointDataGroupColor: '#4F0BCD',
  supplyPointChartTitleColor: '#0065a7',
  tooltipBkg: 'rgba(255, 255, 255, .9)',
  tooltipColor: '#333',

  //Bills
  billsThColor: '#0065a7',
  billsTdColor: '#666',
  billdTdBorderColor: '#D3C2F3',
  billsActiveColBkg: 'rgba(0, 101, 167, .2)',
  billsDownloadBtnColor: '#0065a7',
  billsDownloadBtnHoverColor: '#4F0BCD',
  billsPaginationBkg: '#0065a7',
  billsPaginationBorderColor: '#D3C2F3',
  billsPaginationButtonColor: '#fff',
  billsPaginationActiveButtonBkg: '#ffc524',
  billsPaginationActiveButtonColor: '#fff',
  attachmentModalTitleColor: '#0065a7',
  attachmentModalDescriptionColor: '#5F5F5F',
  billsFiltersBoxShadow: '0px 0px 16px #00000019;',
  billsFiltersLegendColor: '#0065a7',
  billsFiltersResetBtnColor: '#0065a7',
  billsFiltersTogglerOpenBorder: '2px solid #0065a7',
  billsListBodyIconFill: '#0065a7',

  //Self meter read
  selfMeterReadBoxShadow: '0px 0px 16px #00000019;',
  selfMeterReadCustomerCodeColor: '#0065a7',
  selfMeterReadItemNameColor: '#fff',
  selfMeterReadItemNameBkg: 'linear-gradient(90deg,rgb(1,100,167) 0%,rgb(0,159,248) 100%)',
  selfMeterReadItemTextColor: '#666',
  selfMeterReadDataBoxShadow: '0px 0px 10px #00000019;',
  selfMeterReadDataBkg: 'transparent',
  selfMeterReadModalFormHeaderBkg: '#fff',
  selfMeterReadModalFormBodyBkg: '#fff',
  selfMeterReadModalFormTitleColor: '#0065a7',
  selfMeterReadModalFormTitleIconColor: '#7CE0C8',
  selfMeterReadModalFormDescriptionColor: '#0065a7',
  selfMeterReadModalFormAltDescriptionColor: '#5F5F5F',
  selfMeterReadModalFormBkg: '#0065a7',
  selfMeterReadModalFormBorderBtnHover: '2px solid #fff',
  selfMeterReadItemInsertReadNameColor: '#fff',

  //User profile
  userProfileTextColor: '#0065a7',
  userProfileBoxShadow: '0px 0px 16px #00000019;',
  userProfileDataGroupBorderColor: '#ddd',
  userProfileSocialLingColor: '#666',
  userProfileSocialLingHoverColor: '#0065a7',
  userProfileModalFormButtonColor: '#0065a7',
  userProfileModalFormButtonBkg: '#fff',
  userProfileModalFormButtonBorder: '2px solid #0065a7',
  customersDataBoxShadow: '0px 0px 16px #00000019',
  customersDataTextColor: '#0065a7',
  switchInputBkg: '#fff',
  switchInputBorderColor: '#333',

  //Loader
  loaderOne: '#0164A7',
  loaderTwo: '#0181d8',
  loaderThree: '#009FF8',
  skeletonBkg: '#eee',
  skeletonBkgImage: 'linear-gradient(90deg, #eee, #f5f5f5, #eee)',

  //404
  notFoundPageBkg: '#0164A7',
}

export const darkTheme = {
  mainBackgroundColor: '#151515',
  itemBackgroundColor: '#333',

  //Custom Icons
  customIconVioletFill: '#7B48DA',
  customIconYellowFill: '#FFF240',

  //Login page
  loginPageBkg: 'linear-gradient(90deg,rgb(45,83,111) 0%,rgb(5,70,105) 100%)',

  //Navigation side menu
  navigationMenuBkgColor: '#333',
  navigationMenuLink: '#fff',
  navigationMenuItemBorderColor: '#666',
  navigationMenuLinkActive: '#999',
  navigationMenuLinkIcon: '#fff',

  //Navigation bar (TOP)
  navigationBarBrandColor: '#fff',
  navigationBarBoxShadow: '0px 0px 16px #00000019;',
  navigationBarBkg: '#333',
  navigationBarUserNameColor: '#fff',
  navigationBarUserAvatarBkgColor: '#fff',
  navigationBarUserAvatarBorder: '1px solid #fff',
  navigationBarSignOutColor: '#fff',
  navigationBarSignOutHoverColor: '#999',
  navigationBarUserAvatarDefaultUserColor: '#333',
  navigationBarBkgColorMobile: '#333',
  navigationBarBrandColorMobile: '#fff',
  navbarTogglerBkg: '#fff',

  //Forms
  inputBorderColor: '#595959',
  hoverInputBorderColor: '#ccc',
  inputColor: '#ddd',
  formLabelColor: '#ccc',
  userOtpTypeTitleColor: '#fff',
  otpSignNewCodeLinkColor: '#fff',
  customSwitchBorderColor: '#ddd',
  customSwitchControlBkg: '#fff',
  dropzoneBorderColor: '#595959',
  focusedOption: '#666',
  selectedOption: '#444',

  //Buttons
  updateDataButtonBkg: '#333',
  updateDataButtonColor: '#fff',
  updateDataButtonHoverBkg: '#fff',
  updateDataButtonHoverColor: '#333',
  scrollToSeeMoreArrowBkg: '#999',
  scrollToSeeMoreArrowColor: '#333',
  abButtonColor: '#333',
  abButtonBkg: '#fff',
  abButtonBkgHover: '#333',
  abButtonColorHover: '#fff',
  abButtonOutlineColor: '#fff',
  abButtonOutlineBorder: '1px solid #fff',
  abButtonOutlineBkg: 'transparent',
  deleteButtonColor: '#FF1100',
  deleteButtonBorder: '2px solid #FF1100',
  deleteButtonBkg: 'transparent',
  deleteButtonHoverBkg: '#fff',
  deleteButtonHoverBorder: '2px solid #ffd9d7',

  //Modals
  modalBackdropBkg: 'rgb(78 31 205 / 60%)',
  modalHeaderBkg: '#666',
  modalHeaderColor: '#fff',
  modalBodyBkg: '#333',
  modalBodyTextColor: '#fff',
  editAliasModalHeaderTitleColor: '#999',
  downloadAppModalLinkColor: '#4F0BCD',
  descriptionText: '#999',

  //Alert
  alertWarningBackgroundColor: '#ffefc8',
  alertWarningTextColor: '#9c6b2e',
  alertWarningBorderColor: 'transparent',
  alertSuccessBackgroundColor: '#dbffc8',
  alertSuccessTextColor: '#0f5132',
  alertSuccessBorderColor: 'transparent',

  //Data group
  dataGroupTextColor: '#fff',

  //Supply points
  customerCodeColor: '#fff',
  customerCodeBorderColor: '#ddd',
  supplyPointPreviewBoxShadow: '0px 0px 16px #00000019;',
  supplyPointPreviewElectricTextColor: '#fff',
  supplyPointPreviewGasTextColor: '#fff',
  supplyPointPreviewGasMainTextColor: '#fff',
  supplyPointPreviewElectricHeaderBkg: 'linear-gradient(90deg, rgb(11, 68, 49) 0%, rgb(35, 99, 43) 100%)',
  supplyPointPreviewGasHeaderBkg: 'linear-gradient(90deg, rgb(45, 83, 111) 0%, rgb(5, 70, 105) 100%)',
  supplyPointPreviewClosedBkg: "#b1350d",

  //Supply point detail
  backButtonColor: '#fff',
  backButtonHoverColor: '#999',
  supplyPointAliasColor: '#fff',
  editAliasButtonColor: '#fff',
  editAliasButtonColorHover: '#999',
  supplyTypeIconColor: '#4F0BCD',
  supplyPointHeaderBorderColor: '#D3C2F3',
  supplyPointGreenOptionColor: '#fff',
  supplyPointDataBoxTitleColor: '#fff',
  supplyPointDataGroupColor: '#fff',
  supplyPointChartTitleColor: '#fff',
  tooltipBkg: 'rgba(51, 51, 51, .9)',
  tooltipColor: '#fff',

  //Bills
  billsThColor: '#fff',
  billsTdColor: '#ddd',
  billdTdBorderColor: '#666',
  billsActiveColBkg: '#333',
  billsDownloadBtnColor: '#ddd',
  billsPaginationBkg: '#999',
  billsPaginationBorderColor: '#ddd',
  billsPaginationButtonColor: '#333',
  billsPaginationActiveButtonBkg: '#fff',
  billsPaginationActiveButtonColor: '#333',
  attachmentModalTitleColor: '#fff',
  attachmentModalDescriptionColor: '#ddd',
  billsFiltersBoxShadow: '0px 0px 16px #00000019;',
  billsFiltersLegendColor: '#fff',
  billsFiltersResetBtnColor: '#fff',
  billsFiltersTogglerOpenBorder: '2px solid #999',
  billsListBodyIconFill: '#ddd',

  //Self meter read
  selfMeterReadBoxShadow: '0px 0px 16px #00000019;',
  selfMeterReadCustomerCodeColor: '#fff',
  selfMeterReadItemNameColor: '#fff',
  selfMeterReadItemNameBkg: 'linear-gradient(90deg, rgb(45, 83, 111) 0%, rgb(5, 70, 105) 100%)',
  selfMeterReadItemTextColor: '#fff',
  selfMeterReadDataBoxShadow: '0px 0px 10px #00000019;',
  selfMeterReadDataBkg: '#444',
  selfMeterReadModalFormHeaderBkg: '#333',
  selfMeterReadModalFormBodyBkg: '#333',
  selfMeterReadModalFormTitleColor: '#fff',
  selfMeterReadModalFormTitleIconColor: '#7CE0C8',
  selfMeterReadModalFormDescriptionColor: '#fff',
  selfMeterReadModalFormAltDescriptionColor: '#ddd',
  selfMeterReadModalFormBkg: '#111',
  selfMeterReadModalFormBorderBtnHover: '2px solid #fff',
  selfMeterReadItemInsertReadNameColor: '#333',

  //User profile
  userProfileTextColor: '#fff',
  userProfileBoxShadow: '0px 0px 16px #00000019;',
  userProfileDataGroupBorderColor: '#ddd',
  userProfileSocialLingColor: '#fff',
  userProfileSocialLingHoverColor: '#999',
  userProfileModalFormButtonColor: '#50D6B6',
  userProfileModalFormButtonBkg: 'transparent',
  userProfileModalFormButtonBorder: '2px solid #50D6B6',
  customersDataBoxShadow: '0px 0px 16px #00000019',
  customersDataTextColor: '#fff',
  switchInputBkg: 'transparent',
  switchInputBorderColor: '#6DD2B8',

  //Loader
  loaderOne: '#444',
  loaderTwo: '#555',
  loaderThree: '#666',
  skeletonBkg: 'rgb(51,51,51)',
  skeletonBkgImage: 'linear-gradient(90deg, rgba(51,51,51,1) 0%, rgba(102,102,102,1) 100%)',

  //404
  notFoundPageBkg: '#0164A7',
}
