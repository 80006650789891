import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container } from 'reactstrap'

import { withRouter } from '../../components/AppRouter/utils'
import NoMatchPageStyle from './style'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'

const NoMatchPage = ({ router: { location } }) => {
  return (
    <NoMatchPageStyle className="page">
      <Container className="p-3">
        <Logo className="mb-3 logo" />
        <h3 className="title">
          Ci dispiace!
        </h3>
        <p>La pagina <code>{location.pathname}</code> non esiste ancora ma
        puoi tornare alla <NavLink to="/">Home</NavLink> se vuoi ritentare...</p>
      </Container>
    </NoMatchPageStyle>
  )
}

export default withRouter(NoMatchPage)
