import styled from 'styled-components'

export const SupplyPointsOverviewStyle = styled.div`
  padding: 0 .5rem;

  .supply-points-group {
    margin-bottom: 1rem;

    .supply-points-list {
      display: flex;
      width: 100%;
      position: relative;
      overflow-x: auto;
      padding: 0 .75rem;
      padding-bottom: .5rem;
      margin-left: -.75rem;
      margin-right: -.75rem;

      .scroll-to-see-more {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 1199.9px) {
        > div:nth-child(n+4) {
          &.scroll-to-see-more {
            display: flex;
          }
        }
      }

      @media (min-width: 1200px) {
        > div:nth-child(n+5) {
          &.scroll-to-see-more {
            display: flex;
          }
        }
      }

      @media (max-width: 767.9px) {
        > div:nth-child(n+4) {
          &.scroll-to-see-more {
            display: flex;
          }
        }
      }

      @media (max-width: 575.9px) {
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;

        .scroll-to-see-more {
          display: none !important;
        }
      }
    }

    .customer-code {
      text-transform: uppercase;
      font-weight: 700;
      border-bottom: 1px solid;
      border-bottom-color: ${({ theme }) => theme.customerCodeBorderColor};
      font-size: 2.2rem;
      padding: .35rem 0;
      margin: 0;
      margin-bottom: .5rem;
      color: ${({ theme }) => theme.customerCodeColor};
    }
`