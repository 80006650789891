import UserActionTypes from './user.types'

export const toggleUtilityModalVisibility = () => ({
  type: UserActionTypes.TOGGLE_USER_UTILITY_MODAL_VISIBILITY
})

export const toggleUserTheme = () => ({
  type: UserActionTypes.TOGGLE_USER_THEME
})

export const resetLoginForm = () => ({
  type: UserActionTypes.RESET_LOGIN_FORM
})

export const goToNextStep = () => ({
  type: UserActionTypes.GO_TO_NEXT_STEP
})

export const goToStep = (step) => ({
  type: UserActionTypes.GO_TO_STEP,
  payload: step
})

export const checkUserEmailProviderStart = (values) => ({
  type: UserActionTypes.CHECK_USER_EMAIL_PROVIDER_START,
  payload: values
})

export const checkUserEmailProviderIsNewUser = data => ({
  type: UserActionTypes.CHECK_USER_EMAIL_PROVIDER_IS_NEW_USER,
  payload: data
})

export const checkUserEmailProviderAlreadyExists = data => ({
  type: UserActionTypes.CHECK_USER_EMAIL_PROVIDER_ALREADY_EXISTS,
  payload: data
})

export const checkUserEmailProviderUsedByOther = data => ({
  type: UserActionTypes.CHECK_USER_EMAIL_PROVIDER_USED_BY_OTHER,
  payload: data
})

export const checkUserEmailProviderFailure = (error) => ({
  type: UserActionTypes.CHECK_USER_EMAIL_PROVIDER_FAILURE,
  payload: error
})

export const emailSignInStart = (values) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: values
})

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START
})

export const facebookSignInStart = () => ({
  type: UserActionTypes.FACEBOOK_SIGN_IN_START
})

export const appleSignInStart = () => ({
  type: UserActionTypes.APPLE_SIGN_IN_START
})

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
})

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
})

export const signUpWithEmailStart = (values) => ({
  type: UserActionTypes.SIGN_UP_WITH_EMAIL_START,
  payload: values
})

export const signUpWithEmailSuccess = (user) => ({
  type: UserActionTypes.SIGN_UP_WITH_EMAIL_SUCCESS,
  payload: user
})

export const signUpWithEmailFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_WITH_EMAIL_FAILURE,
  payload: error
})

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
})

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
})

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
})

export const resetPasswordStart = values => ({
  type: UserActionTypes.RESET_PASSWORD_START,
  payload: values
})

export const resetPasswordSuccess = values => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
  payload: values
})

export const resetPasswordFailure = error => ({
  type: UserActionTypes.RESET_PASSWORD_FAILURE,
  payload: error
})

export const confirmResetPasswordStart = values => ({
  type: UserActionTypes.CONFIRM_RESET_PASSWORD_START,
  payload: values
})

export const confirmResetPasswordSuccess = values => ({
  type: UserActionTypes.CONFIRM_RESET_PASSWORD_SUCCESS,
  payload: values
})

export const confirmResetPasswordFailure = error => ({
  type: UserActionTypes.CONFIRM_RESET_PASSWORD_FAILURE,
  payload: error
})

export const getClientOtpMethodsStart = (values) => ({
  type: UserActionTypes.GET_CLIENT_OTP_METHODS_START,
  payload: values
})

export const getClientOtpMethodsSuccess = (values) => ({
  type: UserActionTypes.GET_CLIENT_OTP_METHODS_SUCCESS,
  payload: values
})

export const getClientOtpMethodsFailure = (error) => ({
  type: UserActionTypes.GET_CLIENT_OTP_METHODS_FAILURE,
  payload: error
})

export const sendOtpRequestStart = (values) => ({
  type: UserActionTypes.SEND_OTP_REQUEST_START,
  payload: values
})

export const sendOtpRequestSuccess = (values) => ({
  type: UserActionTypes.SEND_OTP_REQUEST_SUCCESS,
  payload: values
})

export const sendOtpRequestFailure = (error) => ({
  type: UserActionTypes.SEND_OTP_REQUEST_FAILURE,
  payload: error
})

export const verifyOtpSignatureStart = (values) => ({
  type: UserActionTypes.VERIFY_OTP_SIGNATURE_START,
  payload: values
})

export const verifyOtpSignatureSuccess = data => ({
  type: UserActionTypes.VERIFY_OTP_SIGNATURE_SUCCESS,
  payload: data
})

export const verifyOtpSignatureFailure = (error) => ({
  type: UserActionTypes.VERIFY_OTP_SIGNATURE_FAILURE,
  payload: error
})

export const verifyOtpSignatureReset = (error) => ({
  type: UserActionTypes.VERIFY_OTP_SIGNATURE_RESET,
  payload: error
})

export const checkUserSessionStart = () => ({
  type: UserActionTypes.CHECK_USER_SESSION_START
})

export const checkUserSessionNoUser = data => ({
  type: UserActionTypes.CHECK_USER_SESSION_NO_USER,
  payload: data
})

export const checkUserSessionFailure = (error) => ({
  type: UserActionTypes.CHECK_USER_SESSION_FAILURE,
  payload: error
})

export const updateUserData = data => ({
  type: UserActionTypes.UPDATE_USER_DATA,
  payload: data
})

export const setOobCode = data => ({
  type: UserActionTypes.SET_OOB_CODE,
  payload: data
})

export const updateUserProfileStart = values => ({
  type: UserActionTypes.UPDATE_USER_PROFILE_START,
  payload: values
})

export const updateUserProfileSuccess = values => ({
  type: UserActionTypes.UPDATE_USER_PROFILE_SUCCESS,
  payload: values
})

export const updateUserProfileFailure = (error) => ({
  type: UserActionTypes.UPDATE_USER_PROFILE_FAILURE,
  payload: error
})

export const fetchCustomerDataStart = data => ({
  type: UserActionTypes.FETCH_CUSTOMER_DATA_START,
  payload: data
})

export const fetchCustomerDataSuccess = data => ({
  type: UserActionTypes.FETCH_CUSTOMER_DATA_SUCCESS,
  payload: data
})

export const fetchCustomerDataError = error => ({
  type: UserActionTypes.FETCH_CUSTOMER_DATA_FAILURE,
  payload: error
})

export const deleteCustomerDataStart = data => ({
  type: UserActionTypes.DELETE_CUSTOMER_DATA_START,
  payload: data
})

export const deleteCustomerDataSuccess = data => ({
  type: UserActionTypes.DELETE_CUSTOMER_DATA_SUCCESS,
  payload: data
})

export const deleteCustomerDataFailure = error => ({
  type: UserActionTypes.DELETE_CUSTOMER_DATA_FAILURE,
  payload: error
})

export const updateUserToken = data => ({
  type: UserActionTypes.UPDATE_USER_TOKEN,
  payload: data
})

export const toggleCustomerCodeModal = data => ({
  type: UserActionTypes.TOGGLE_CUSTOMER_CODE_MODAL
})

export const userUpdateMaintenanceState = data => ({
  type: UserActionTypes.USER_UPDATE_MAINTENANCE_STATE,
  payload: data
})