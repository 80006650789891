import { getAPIUrl, fetchData } from '../../ABapi'
import _ from 'lodash'

export const DEFAULT_ITEMS_PER_PAGE = 15

export function getDocumentAsBase64(documentId, documentType, idToken) {
  return fetchData(`${getAPIUrl()}/sales-documents/${documentId}/document?type=${documentType}`, idToken)
    .then(response => response)
}

export function composeQueryString(filters, sorting, selectedPage = 1, pageSize = DEFAULT_ITEMS_PER_PAGE) {
  let queryParams = []
  _.forEach(filters, (value, key) => {
    queryParams.push(`${key}==${value}`)
  })

  return `page=${selectedPage}&pageSize=${pageSize}&sorts=${sorting}&filters=${queryParams.join(',')}`
}

export function getBills(idToken, filters, sorting, selectedPage) {
  const queryString = composeQueryString(filters, sorting, selectedPage)
  return fetchData(`${getAPIUrl()}/sales-documents?${queryString}`, idToken)
    .then(response => response)
}
