import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { FormText, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import UserOtpSignFormStyle from './style'
import { required } from './utils'
import { verifyOtpSignatureStart, goToStep } from '../../../redux/user/user.actions'
import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import UserLoginSteps from '../../../pages/LoginPage/loginSteps'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const UserOtpSignForm = () => {
  const isSubmitting = useSelector(selectIsSubmitting)
  const dispatch = useDispatch()
  const onSubmit = values => dispatch(verifyOtpSignatureStart(values))

  return(
    <UserOtpSignFormStyle>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="login-form">
            <CustomFormField
              id="otpCode"
              name="otpCode"
              type="text"
              label="Codice di conferma"
              maxLength="6"
              placeholder="Codice di conferma"
              validate={required}
              focusOnMount={true}
            >
              <FormText color="muted">
                <Button color="link" className="new-otp-code" onClick={() => dispatch(goToStep(UserLoginSteps.OTP_TYPE_STEP))}>
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                  <span>Richiedi un nuovo codice</span>
                </Button>
              </FormText>
            </CustomFormField>
            <ButtonWithLoader
              type="submit"
              outline
              color="secondary"
              buttonLabel="Continua"
              onClick={handleSubmit}
              isFetching={isSubmitting}
              disabled={isSubmitting}
            />
          </form>
        )}
      </Form>
    </UserOtpSignFormStyle>
  )
}

export default UserOtpSignForm
