import React from 'react'

import LoginStep from '../../LoginStep/LoginStep.component'
import UserDataForm from '../UserDataForm/UserDataForm.component'

const UserDataStep = () => (
  <LoginStep>
    <h1 className="step-title">
      Crea account
    </h1>
    <p className="form-description">
      Continuando accetti i &nbsp;<a href="https://www.abenergie.it/note-legali" target="_blank" rel="noopener noreferrer">Termini e le condizioni</a>,
      l'<a href="https://www.abenergie.it/informativa-sulla-privacy" target="_blank" rel="noopener noreferrer">Informativa sulla privacy</a> dei dati
      e la &nbsp;<a href="https://www.abenergie.it/informativa-sui-cookie" target="_blank" rel="noopener noreferrer">Cookie policy</a>.
    </p>
    <UserDataForm />
  </LoginStep>
)

export default UserDataStep
