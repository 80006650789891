import styled from 'styled-components'

import { Button } from 'reactstrap'

const ButtonWithLoaderStyle = styled(Button)`
  .fa-spinner-third {
    animation: spin 1s ease-in-out infinite;
    margin-right: .75rem;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`

export default ButtonWithLoaderStyle