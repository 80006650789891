import CadastralDataTypes from "./cadastralData.types"
import { AsyncStatusEnum, removeItemFromCollection } from './cadastralData.utils'

const INITIAL_STATE = {
  asyncActionStatus: [null, AsyncStatusEnum.IDLE],
  cadastralDataModalVisibility: false,
  cadastralDataPdpListModalVisibility: false,
  cadastralDataItemsListModalVisibility: false,
  deletePdpToCadastralItemModalVisibility: false,
  addPdpShortcutModalVisibility: false,
  data: undefined,
  formItems: undefined,
  availablePdpItems: [],
  selectedCadastralData: undefined,
  selectedPdpItemData: undefined,
  error: null,
  utilityModal: {
    isVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const cadastralDataReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.SUCCESS],
        data: action.payload.cadastralData,
        deletePdpToCadastralItemModalVisibility: false,
        selectedCadastralData: undefined,
        selectedPdpItemData: undefined
      }

    case CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.ADD_CADASTRAL_DATA_START:
      return {
        ...state,
        asyncActionStatus: ['addCadastralData', AsyncStatusEnum.LOADING],
        availablePdpItems: [],
        selectedCadastralData: undefined,
        error: null,
      }

    case CadastralDataTypes.ADD_CADASTRAL_DATA_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['addCadastralData', AsyncStatusEnum.SUCCESS],
        availablePdpItems: action.payload,
        cadastralDataModalVisibility: true
      }

    case CadastralDataTypes.ADD_CADASTRAL_DATA_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['addCadastralData', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }
    
    case CadastralDataTypes.FETCH_CADASTRAL_DATA_START:
      return {
        ...state,
        asyncActionStatus: ['allCadastralDataFetch', AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.FETCH_CADASTRAL_DATA_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['allCadastralDataFetch', AsyncStatusEnum.SUCCESS],
        data: action.payload.cadastralData,
        formItems: action.payload.formItems
      }

    case CadastralDataTypes.FETCH_CADASTRAL_DATA_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['allCadastralDataFetch', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_START:
      return {
        ...state,
        asyncActionStatus: [`cadastralDataItemFetch-${action.payload.cadastralDataItemId}`, AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemFetch', AsyncStatusEnum.SUCCESS],
        selectedCadastralData: action.payload,
        cadastralDataModalVisibility: true
      }

    case CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemFetch', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.FETCH_AVAILABLE_PDP_START:
      return {
        ...state,
        asyncActionStatus: [`fetchAvailablePdp-${action.payload.cadastralDataItemId}`, AsyncStatusEnum.LOADING],
        error: null,
        availablePdpItems: [],
        selectedCadastralData: {
          id: action.payload.cadastralDataItemId
        }
      }

    case CadastralDataTypes.FETCH_AVAILABLE_PDP_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['fetchAvailablePdp', AsyncStatusEnum.SUCCESS],
        availablePdpItems: action.payload,
        cadastralDataPdpListModalVisibility: true
      }

    case CadastralDataTypes.FETCH_AVAILABLE_PDP_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['fetchAvailablePdp', AsyncStatusEnum.FAILURE],
        error: action.payload,
        cadastralDataPdpListModalVisibility: false,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_START:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataPdpAssociation', AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataPdpAssociation', AsyncStatusEnum.SUCCESS],
        data: action.payload.cadastralData,
        cadastralDataPdpListModalVisibility: false,
        addPdpShortcutModalVisibility: false
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataPdpAssociation', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_START:
      return {
        ...state,
        asyncActionStatus: [`cadastralDataItemDelete-${action.payload.cadastralDataId}`, AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemDelete', AsyncStatusEnum.SUCCESS],
        selectedCadastralData: undefined,
        data: {
          ...state.data,
          cadastralData: removeItemFromCollection(action.payload.cadastralDataId, state.data.cadastralData)
        }
      }

    case CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemDelete', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_START:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemSubmit', AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemSubmit', AsyncStatusEnum.SUCCESS],
        cadastralDataModalVisibility: false,
        data: action.payload.cadastralData,
        selectedCadastralData: action.payload.isNewItem ? action.payload.selectedCadastralData : undefined,
        addPdpShortcutModalVisibility: action.payload.isNewItem && (state.data.unmatchedPod.length || state.data.unmatchedPdr.length) 
      }

    case CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['cadastralDataItemSubmit', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.LOADING],
        error: null
      }

    case CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.SUCCESS],
        cadastralDataItemsListModalVisibility: false,
        data: action.payload.cadastralData
      }

    case CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE:
      return {
        ...state,
        asyncActionStatus: ['pdpToCadastralItem', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case CadastralDataTypes.TOGGLE_CADASTRAL_DATA_MODAL:
      return {
        ...state,
        cadastralDataModalVisibility: !state.cadastralDataModalVisibility
      }

    case CadastralDataTypes.TOGGLE_CADASTRAL_DATA_PDP_LIST_MODAL:
      return {
        ...state,
        cadastralDataPdpListModalVisibility: !state.cadastralDataPdpListModalVisibility
      }

    case CadastralDataTypes.TOGGLE_CADASTRAL_DATA_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    case CadastralDataTypes.TOGGLE_CADASTRAL_DATA_ITEMS_LIST_MODAL:
      return {
        ...state,
        cadastralDataItemsListModalVisibility: !state.cadastralDataItemsListModalVisibility,
        selectedCadastralData: {
          id: action.payload?.cadastralDataItemId
        },
        selectedPdpItemData: {
          number: action.payload?.pdpNo
        }
      }

    case CadastralDataTypes.TOGGLE_DELETE_PDP_TO_CADASTRAL_ITEM_MODAL:
      return {
        ...state,
        deletePdpToCadastralItemModalVisibility: !state.deletePdpToCadastralItemModalVisibility,
        selectedCadastralData: {
          id: action.payload?.cadastralDataItemId
        },
        selectedPdpItemData: {
          number: action.payload?.pdpNo
        }
      }

    case CadastralDataTypes.TOGGLE_PDP_SHORTCUT_MODAL:
      return {
        ...state,
        addPdpShortcutModalVisibility: !state.addPdpShortcutModalVisibility,
        selectedCadastralData: undefined
      }

    case CadastralDataTypes.RESET_SELECTED_CADASTRAL_DATA:
      return {
        ...state,
        selectedCadastralData: undefined
      }

    default:
      return state
  }
}

export default cadastralDataReducer