const CadastralDataTypes = {
  RESET_SELECTED_CADASTRAL_DATA: "RESET_SELECTED_CADASTRAL_DATA",
  TOGGLE_CADASTRAL_DATA_UTILITY_MODAL_VISIBILITY: "TOGGLE_CADASTRAL_DATA_UTILITY_MODAL_VISIBILITY",
  TOGGLE_CADASTRAL_DATA_MODAL: "TOGGLE_CADASTRAL_DATA_MODAL",
  TOGGLE_CADASTRAL_DATA_PDP_LIST_MODAL: "TOGGLE_CADASTRAL_DATA_PDP_LIST_MODAL",
  TOGGLE_CADASTRAL_DATA_ITEMS_LIST_MODAL: "TOGGLE_CADASTRAL_DATA_ITEMS_LIST_MODAL",
  TOGGLE_PDP_SHORTCUT_MODAL: "TOGGLE_PDP_SHORTCUT_MODAL",
  TOGGLE_DELETE_PDP_TO_CADASTRAL_ITEM_MODAL: "TOGGLE_DELETE_PDP_TO_CADASTRAL_ITEM_MODAL",

  FETCH_CADASTRAL_DATA_START: "FETCH_CADASTRAL_DATA_START",
  FETCH_CADASTRAL_DATA_SUCCESS: "FETCH_CADASTRAL_DATA_SUCCESS",
  FETCH_CADASTRAL_DATA_FAILURE: "FETCH_CADASTRAL_DATA_FAILURE",

  FETCH_CADASTRAL_DATA_ITEM_START: "FETCH_CADASTRAL_DATA_ITEM_START",
  FETCH_CADASTRAL_DATA_ITEM_SUCCESS: "FETCH_CADASTRAL_DATA_ITEM_SUCCESS",
  FETCH_CADASTRAL_DATA_ITEM_FAILURE: "FETCH_CADASTRAL_DATA_ITEM_FAILURE",

  SUBMIT_CADASTRAL_DATA_ITEM_START: "SUBMIT_CADASTRAL_DATA_ITEM_START",
  SUBMIT_CADASTRAL_DATA_ITEM_SUCCESS: "SUBMIT_CADASTRAL_DATA_ITEM_SUCCESS",
  SUBMIT_CADASTRAL_DATA_ITEM_FAILURE: "SUBMIT_CADASTRAL_DATA_ITEM_FAILURE",

  DELETE_CADASTRAL_DATA_ITEM_START: "DELETE_CADASTRAL_DATA_ITEM_START",
  DELETE_CADASTRAL_DATA_ITEM_SUCCESS: "DELETE_CADASTRAL_DATA_ITEM_SUCCESS",
  DELETE_CADASTRAL_DATA_ITEM_FAILURE: "DELETE_CADASTRAL_DATA_ITEM_FAILURE",

  FETCH_AVAILABLE_PDP_START: "FETCH_AVAILABLE_PDP_START",
  FETCH_AVAILABLE_PDP_SUCCESS: "FETCH_AVAILABLE_PDP_SUCCESS",
  FETCH_AVAILABLE_PDP_FAILURE: "FETCH_AVAILABLE_PDP_FAILURE",

  SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_START: "SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_START",
  SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_SUCCESS: "SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_SUCCESS",
  SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_FAILURE: "SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_FAILURE",

  SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START: "SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START",
  SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS: "SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS",
  SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE: "SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE",

  ADD_CADASTRAL_DATA_START: 'ADD_CADASTRAL_DATA_START',
  ADD_CADASTRAL_DATA_SUCCESS: 'ADD_CADASTRAL_DATA_SUCCESS',
  ADD_CADASTRAL_DATA_FAILURE: 'ADD_CADASTRAL_DATA_FAILURE',

  DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START: 'DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START',
  DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS: 'DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS',
  DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE: 'DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE',
}

export default CadastralDataTypes