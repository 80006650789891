import { createSelector } from "reselect"

import { AsyncStatusEnum } from "./cadastralData.utils"

export const selectCadastralData = state => state.cadastralData

export const selectIsFetchingCadastralData = searchedEntityName => createSelector(
  [selectCadastralData],
  cadastralData => {
    const [ fetchingEntityName, asyncStatus ] = cadastralData.asyncActionStatus
    return (
      (
        searchedEntityName === fetchingEntityName && 
        asyncStatus === AsyncStatusEnum.LOADING
      ) || (
        !fetchingEntityName &&
        asyncStatus === AsyncStatusEnum.IDLE
      )
    ) ?  true : false
  }
)

export const selectIsSubmittingCadastralItemForm = searchedEntityName => createSelector(
  [selectCadastralData],
  cadastralData => {
    const [ fetchingEntityName, asyncStatus ] = cadastralData.asyncActionStatus
    return searchedEntityName === fetchingEntityName && asyncStatus === AsyncStatusEnum.LOADING ? true : false
  }
)

export const selectUtilityModal = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.utilityModal
)

export const selectCadastralModalVisibility = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.cadastralDataModalVisibility
)

export const selectCadastralPdpListModalVisibility = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.cadastralDataPdpListModalVisibility
)

export const selectCadastralDataItemsListModalVisibility = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.cadastralDataItemsListModalVisibility
)

export const selectDeletePdpToCadastralItemModalVisibility = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.deletePdpToCadastralItemModalVisibility
)

export const selectData = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.data || null
)

export const selectUnmatchedPods = createSelector(
  [selectData],
  data => data?.unmatchedPod || null
)

export const selectUnmatchedPdrs = createSelector(
  [selectData],
  data => data?.unmatchedPdr || null
)

export const selectCadastralDataItems = createSelector(
  [selectData],
  data => {
    if(data) {
      return Object.keys(data.cadastralData).map(key => data.cadastralData[key])
    }

    return []
  }
)

export const selectAvailablePdpItems = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.availablePdpItems || []
)

export const selectFormItems = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.formItems
)

export const selectSelectedCadastralData = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.selectedCadastralData
)

export const selectSelectedPdpItemData = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData.selectedPdpItemData
)

export const selectTownsArray = createSelector(
  [selectFormItems],
  formItems => formItems?.cadastralTown || []
)

export const selectAvailableCadastralDataItems = createSelector(
  [selectCadastralDataItems, selectSelectedCadastralData],
  (cadastralDataItems, selectedCadastralData) => {
    if(selectedCadastralData) {
      return cadastralDataItems.filter(item => item.id !== selectedCadastralData.id )
    }
    return []
  }
)

export const selectCadastralDataPdpLength = cadastralDataId => createSelector(
  [selectData],
  data => {
    if(cadastralDataId) {
      const selectedItem = data.cadastralData[cadastralDataId]
      return selectedItem.matchedPod.length + selectedItem.matchedPdr.length
    }
    
    return undefined
  }
)