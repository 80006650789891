import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'

import { clientCodeCheck } from './utils'
import { getClientOtpMethodsStart } from '../../../redux/user/user.actions'
import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const UserClientCodeForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const onSubmit = values => dispatch(getClientOtpMethodsStart(values))

  return(
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="clientCode"
            name="clientCode"
            type="text"
            label="Codice cliente"
            validate={clientCodeCheck}
            placeholder="Codice cliente"
            focusOnMount={true}
          />
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            buttonLabel="Continua"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Form>
  )
}

export default UserClientCodeForm
