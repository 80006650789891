import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  .animation-help {
    border: 1px solid;
    border-color: ${({ theme }) => theme.mainBorderColor};
  }

  .form-group {
    font-size: 1rem;
    margin-bottom: 1.5rem;

    label {
      color: ${({ theme }) => theme.formLabelColor};
      font-weight: 600;
    }

    .form-check-input {
      margin-top: .23rem;
    }
  }

  .custom-icon {
    &.violet path {
      fill: ${({ theme }) => theme.customIconVioletFill};
    }

    &.yellow path {
      fill: ${({ theme }) => theme.customIconYellowFill};
    }
  }

  .ab-button {
    background-color: ${({ theme }) => theme.abButtonBkg};
    color: ${({ theme }) => theme.abButtonColor};
    border: 1px solid ${({ theme }) => theme.abButtonBkg};
    transition: all .2s ease-out;
    display: flex;
    align-items: center;
    font-weight: 700;
    border-radius: 150px;
    padding: .75rem 1.25rem;

    &.outline {
      background-color: ${({ theme }) => theme.abButtonOutlineBkg};
      color: ${({ theme }) => theme.abButtonOutlineColor};
      border: ${({ theme }) => theme.abButtonOutlineBorder};

      &:focus {
        background-color: ${({ theme }) => theme.abButtonOutlineBkg};
        color: ${({ theme }) => theme.abButtonOutlineColor};  
      }

      &:hover,
      &:active {
        color: ${({ theme }) => theme.abButtonColor}; 
        background-color: ${({ theme }) => theme.abButtonBkg};
      }
    }

    &:focus {
      background-color: ${({ theme }) => theme.abButtonBkg};
      color: ${({ theme }) => theme.abButtonColor};

      > svg path {
        fill: ${({ theme }) => theme.abButtonColor};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.abButtonColorHover};
      background: ${({ theme }) => theme.abButtonBkgHover};
      border: 1px solid ${({ theme }) => theme.abButtonBkg};

      > svg path {
        fill: ${({ theme }) => theme.abButtonColorHover};
      }
    }

    &:active {
      color: ${({ theme }) => theme.abButtonColorHover} !important;
      background: ${({ theme }) => theme.abButtonBkgHover} !important;
      border: 1px solid ${({ theme }) => theme.abButtonBkg} !important;

      > svg path {
        fill: ${({ theme }) => theme.abButtonColorHover};
      }
    }

    &:disabled {
      color: ${({ theme }) => theme.abButtonColorHover};
      background: ${({ theme }) => theme.abButtonBkgHover};
      opacity: .3;

      &:hover {
        color: ${({ theme }) => theme.abButtonColorHover};
        background: ${({ theme }) => theme.abButtonBkgHover};
      }

      > svg path {
        fill: ${({ theme }) => theme.abButtonColorHover};
      }
    }

    &.inverted {
      color: ${({ theme }) => theme.abButtonColorHover};
      background: ${({ theme }) => theme.abButtonBkgHover};
      border: 1px solid ${({ theme }) => theme.abButtonBkg};

      &:focus {
        > svg path {
          fill: ${({ theme }) => theme.abButtonColorHover};
        }
      }

      &:active,
      &:hover {
        background-color: ${({ theme }) => theme.abButtonBkg} !important;
        color: ${({ theme }) => theme.abButtonColor} !important;
        border: 1px solid ${({ theme }) => theme.abButtonColor} !important;

        > svg path {
          fill: ${({ theme }) => theme.abButtonColor};
        }
      }
    }

    > svg {
      font-size: 1.1rem;
      margin-right: .75rem;
    }
  }

  .data-group {
    flex-basis: 50%;
    flex-grow: 1;
    padding: 0;
    color: ${({ theme }) => theme.dataGroupTextColor};

    label {
      margin-bottom: .15rem;
      font-size: .9rem;
      display: block;
    }

    .code {
      font-weight: 700;
      font-size: .9rem;
      display: block;
    }
  }

  .back-btn {
    color: ${({ theme }) => theme.mainTitleColor};
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: all .3s ease-out;

    &:hover {
      color: ${({ theme }) => theme.mainTextColorActive};
      text-decoration: none;
    }

    .link-icon {
      margin-right: .5rem;
      font-size: 1.25rem;
    }
  }

  //Modal styles
  .modal {
    .modal-content {
      border: none;
      background-color: ${({ theme }) => theme.itemBackgroundColor};
    }

    .modal-header {
      border-bottom: none;
      color: ${({ theme }) => theme.modalHeaderColor};
      background-color: ${({ theme }) => theme.modalHeaderBkg};

      .close {
        color: ${({ theme }) => theme.modalHeaderColor};
      }

      svg {
        margin-right: .75rem;
        fill: ${({ theme }) => theme.modalHeaderColor};
      }

      .modal-title {
        font-weight: 700;
      }

      .info-trigger {
        cursor: pointer;

        #info {
          margin-right: 0;
          width: 1.5rem;
          transition: opacity .2s ease;

          .st0 {
            fill: ${({ theme }) => theme.modalHeaderColor};
          }
        }

        &:hover #info {
          opacity: .75;
        }
      }
    }

    .modal-body {
      background-color: ${({ theme }) => theme.modalBodyBkg};
      color: ${({ theme }) => theme.modalBodyTextColor};
      font-size: .9rem;
      font-weight: 600;

      p {
        color: ${({ theme }) => theme.modalBodyTextColor};
        font-size: .9rem;
        font-weight: 600;

        &.small {
          font-weight: 400;
        }
      }
    }

    .modal-footer {
      border-top: none;
      background-color: ${({ theme }) => theme.modalBodyBkg};

      .btn {
        font-weight: 700;
        color: ${({ theme }) => theme.abButtonColor};
        background-color: ${({ theme }) => theme.abButtonBkg};
        padding: .75rem 1.25rem;
        font-size: .85rem;
        border-radius: 25px;
        transition: all .2s ease;

        &:hover,
        &:focus {
          color: ${({ theme }) => theme.abButtonColorHover};
          background-color: ${({ theme }) => theme.abButtonBkgHover};  
        }
      }
    }
  }

  .add-customer-code-modal {
    .modal-body {
      padding: 1.5rem 2.5rem;
    }
  }

  .edit-alias-modal {
    .modal-header {
      strong {
        color: ${({ theme }) => theme.editAliasModalHeaderTitleColor};
        font-weight: 700;
      }
    }
    .modal-body {
      padding: 1.5rem;

      button[type="submit"] {
        margin-left: auto;
      }
    }
  }

  .login-form,
  .update-user-data-form,
  .self-meter-read-form,
  .cadastral-data-form {
    .form-control {
      color: ${({ theme }) => theme.inputColor};
      border-color: ${({ theme }) => theme.inputBorderColor};
      background-color: transparent;
      padding: .75rem;
      height: auto;
      font-size: .9rem;

      &:focus {
        box-shadow: none;
      }
    }

    label {
      margin-bottom: .1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.formLabelColor};
    }

    ::placeholder {
      color: ${({ theme }) => theme.inputColor};
    }

    button[type="submit"] {
      margin-left: auto;
      margin-top: 1rem;
      color: ${({ theme }) => theme.abButtonColor};
      background-color: ${({ theme }) => theme.abButtonBkg};
      border-radius: 25px;
      font-weight: 700;
      font-size: .85rem;
      padding: .75rem 1.25rem;
      transition: all .2s ease;
      border: 1px solid ${({ theme }) => theme.abButtonBkg};

      &:hover,
      &:active {
        color: ${({ theme }) => theme.abButtonColorHover} !important;
        background-color: ${({ theme }) => theme.abButtonBkgHover} !important;
        border: 1px solid ${({ theme }) => theme.abButtonBorderColor};
      }

      &:focus {
        color: ${({ theme }) => theme.abButtonColor};
        background-color: ${({ theme }) => theme.abButtonBkg};
      }
    }
  }

  .login-form {
    label {
      margin-bottom: .1rem;
      font-weight: 600;
    }

    ::placeholder {
      color: #666;
    }
  }

  #maintenance-alert {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    margin: 1.5rem;
    z-index: 100;
    box-shadow: 0 0 15px -5px #333;
    font-weight: 600;
    
    @media (min-width: 1200px) {
      max-width: 75%;
      margin: 1.5rem auto;
    }
  }

  .react-loading-skeleton {
    background-color: ${({ theme }) => theme.skeletonBkg} !important;

    &:after {
      background-image: ${({ theme }) => theme.skeletonBkgImage} !important;
    }
  }
`

export default GlobalStyles
