import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Collapse, Card, CardBody, FormGroup, Row, Col } from 'reactstrap'
import { Form } from 'react-final-form'

import { BillsListFiltersStyle } from './style'
import { resetBillsFilters, fetchBillsStart } from '../../../redux/bills/bills.actions'
import { selectCustomerCodesArray } from '../../../redux/user/user.selectors'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import CustomRadioField from '../../CustomRadioField/CustomRadioField.component'
import CustomSelectField from '../../CustomSelectField/CustomSelectField.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BillsListFilters = () => {
  const dispatch = useDispatch()
  const customerCodesArray = useSelector(selectCustomerCodesArray)
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    return () => dispatch(resetBillsFilters())
  }, [dispatch])

  const onSubmit = (values) => {
    toggle()
    dispatch(fetchBillsStart({ filters: values }))
  }

  return (
    <BillsListFiltersStyle>
      <Button 
        className={`ab-button filter-toggler ${isOpen ? 'filters-open' : ''}`}
        onClick={toggle}
      >
        <FontAwesomeIcon icon={['fas', 'filter']} />
        Filtra
      </Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values, reset }) => (
                <form onSubmit={handleSubmit} className="filters-form">
                  <Row>
                    <Col md={6} lg={4}>
                      <FormGroup tag="fieldset">
                        <legend>Tipo fornitura</legend>
                        <CustomRadioField
                          name="serviceType"
                          type="radio"
                          description="Energia"
                          value="1"
                          className="custom-form-field"
                        />
                        <CustomRadioField
                          name="serviceType"
                          type="radio"
                          description="Gas"
                          value="2"
                          className="custom-form-field"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                      <FormGroup tag="fieldset">
                        <legend>Tipo fattura</legend>
                        <CustomRadioField
                          name="type"
                          type="radio"
                          description="Bolletta"
                          value="1"
                          className="custom-form-field"
                        />
                        <CustomRadioField
                          name="type"
                          type="radio"
                          description="Nota credito"
                          value="2"
                          className="custom-form-field"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                      <FormGroup tag="fieldset">
                        <legend>Multi sito</legend>
                        <CustomRadioField
                          name="multisite"
                          type="radio"
                          description="Sì"
                          value="1"
                          className="custom-form-field"
                        />
                        <CustomRadioField
                          name="multisite"
                          type="radio"
                          description="No"
                          value="0"
                          className="custom-form-field"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                      <FormGroup tag="fieldset">
                        <legend>Stato fattura</legend>
                        <CustomRadioField
                          name="paymentStatus"
                          type="radio"
                          description="Pagato"
                          value="1"
                          className="custom-form-field"
                        />
                        <CustomRadioField
                          name="paymentStatus"
                          type="radio"
                          description="In attesa"
                          value="2"
                          className="custom-form-field"
                        />
                        <CustomRadioField
                          name="paymentStatus"
                          type="radio"
                          description="Non pagato"
                          value="3"
                          className="custom-form-field"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                      <FormGroup tag="fieldset">
                        <legend>Codice cliente</legend>
                        <CustomSelectField
                          name="customerNumber"
                          type="select"
                          options={customerCodesArray}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="form-buttons">
                    <Button
                      size="sm"
                      className="reset-form"
                      onClick={() => {
                        form.reset()
                        dispatch(resetBillsFilters())
                      }}
                      color="link"
                      outline
                      disabled={submitting || pristine}
                    >
                      Reset
                    </Button>
                    <ButtonWithLoader
                      size="sm"
                      type="submit"
                      outline
                      onClick={handleSubmit}
                      disabled={submitting}
                      buttonLabel="Filtra"
                      className="ab-button"
                    />
                  </div>
                </form>
              )}
            </Form>
          </CardBody>
        </Card>
      </Collapse>
    </BillsListFiltersStyle>
  )
}

export default BillsListFilters
