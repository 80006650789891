import React from 'react'
import { useSelector } from 'react-redux'

import { MaintenancePageStyle } from './style'
import { ReactComponent as Logo } from '../../assets/svg/maintenance.svg'
import { ReactComponent as Clock } from '../../assets/svg/clock.svg'
import { selectUserMaintenanceState } from '../../redux/user/user.selectors'

const MaintenancePage = () => { 
  const maintenanceState = useSelector(selectUserMaintenanceState)

  return (
    <MaintenancePageStyle id="pino">
      <article>
        <Logo />

        <div className="content">
          <Clock />
          <h1>{ maintenanceState.under_maintenance_title }</h1>

          <div className="paragraph">
            <p>{ maintenanceState.under_maintenance_message }</p>
          </div>
        </div>

        <div className="footer">
          <p>ABenergie® è marchio in licenza d'uso a Gas and Power Srl - P.IVA 09083400961 - sede legale Via Goffredo Mameli 11, 20129 Milano - REA MI 2068114</p>
        </div>
      </article>
    </MaintenancePageStyle>
  )
}

export default MaintenancePage