import React from 'react'

import CustomersDataStyle from './style'
import DeleteCustomerCodeModal from '../DeleteCustomerCodeModal/DeleteCustomerCodeModal.component'

const CustomersData = ({ customerCodeItem }) => (
  <CustomersDataStyle>
    <header>
      <div className="user-data-group no-border">
        <div className="data-group full-width">
          <label>Codice cliente:</label>
          <span className="code client-code">{customerCodeItem.code}</span>
        </div>
      </div>
      <DeleteCustomerCodeModal customerCode={customerCodeItem.code} />
    </header>
    <main>
      <h3 className="title">Dati cliente</h3>
      <div className="user-data-group">
        <div className="data-group">
          <label>Anagrafica:</label>
          <span className="code">{customerCodeItem.fullName}</span>
        </div>
        <div className="data-group">
          <label>Codice cliente:</label>
          <span className="code">{customerCodeItem.code}</span>
        </div>
        <div className="data-group">
          <label>Codice fiscale:</label>
          <span className="code">{customerCodeItem.fiscalCode}</span>
        </div>
      </div>
      {customerCodeItem.contactPerson &&
        <div className="contact-person">
          <h3 className="title">Referente dedicato:</h3>
          <div className="user-data-group">
            <div className="data-group">
              <label>Anagrafica:</label>
              <span className="code">{customerCodeItem.contactPerson.name} ({customerCodeItem.contactPerson.code})</span>
            </div>
            <div className="data-group">
              <label>Telefono:</label>
              <span className="code">{customerCodeItem.contactPerson.phoneNumber}</span>
            </div>
            <div className="data-group">
              <label>Email:</label>
              <span className="code">
                <a href={`mailto:${customerCodeItem.contactPerson.email}`}>{customerCodeItem.contactPerson.email}</a>
              </span>
            </div>
          </div>
        </div>
      }
      <DeleteCustomerCodeModal customerCode={customerCodeItem.code} />
    </main>
  </CustomersDataStyle>
)

export default CustomersData
