import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import WithSpinner from '../../WithSpinner/WithSpinner.component'
import SelfMeterReadOverview from './SelfMeterReadOverview.component'
import { selectIsFetchingData } from '../../../redux/selfMeterRead/selfMeterRead.selectors'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingData
})

const SelfMeterReadOverviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(SelfMeterReadOverview)

export default SelfMeterReadOverviewContainer
