import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Lottie from 'react-lottie'

import * as clientCodeHelper from '../../../assets/lottie/client-code-help.json'
import LoginStep from '../../LoginStep/LoginStep.component'
import UserClientCodeForm from '../UserClientCodeForm/UserClientCodeForm.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserClientCodeStep = () => {
  const [ modal, setModal ] = useState(false)
  const toggle = () => setModal(!modal)
  const clientCodeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: clientCodeHelper.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <LoginStep>
      <h1 className="step-title client-code">
        Inserisci il codice cliente a cui vuoi collegarti.
        <span onClick={toggle} className="info-trigger">
          <FontAwesomeIcon icon={['fas', 'question-circle']} />
        </span>
      </h1>
      <UserClientCodeForm />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Dove trovo il codice cliente?
        </ModalHeader>
        <ModalBody>
          <div className="animation-help">
            <Lottie options={clientCodeAnimationOptions} />
          </div>
          <p className="mt-3">Puoi trovare il codice cliente nella prima pagina della bolletta</p>
        </ModalBody>
        <ModalFooter>
          <Button className="ab-button" onClick={toggle}>
            Ho capito
          </Button>
        </ModalFooter>
      </Modal>
    </LoginStep>
  )
}

export default UserClientCodeStep
