import styled from 'styled-components'

export const UserProfileStyle = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1rem;
  box-shadow: ${({ theme }) => theme.userProfileBoxShadow};
  background-color: ${({ theme }) => theme.itemBackgroundColor};

  .user-avatar-container {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }

  .title {
    color: ${({ theme }) => theme.userProfileTextColor};
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding: 0 .25rem;
  }

  .user-data-group {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.userProfileDataGroupBorderColor};
    padding: 1rem 0;

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
    }

    .data-group {
      @media (min-width: 1200px) {
        max-width: 33.33%;
      }

      @media (max-width: 575.9px) {
        margin-bottom: 1rem;
      }
    }

    .code {
      font-weight: 700;
      margin-top: .25rem;
    }
  }

  .dark-mode {
    display: flex;
    align-items: center;

    > svg {
      font-size: 1.25rem;
    }

    .custom-switch {
      margin-left: .5rem;
      margin-right: 1rem;
      cursor: pointer;

      .custom-control-label {
        &:before,
        &:after {
          cursor: pointer;
        }
      }
    }

    .custom-switch .custom-control-label::before {
      height: 1.5rem;
      width: 2.75rem;
      border-radius: 25px;
      top: 0;
      border-color: ${({ theme }) => theme.customSwitchBorderColor};
    }

    .custom-switch .custom-control-label::after {
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
      border-radius: 50%;
      top: calc(0rem + 2px);
      background-color: ${({ theme }) => theme.customSwitchControlBkg};
    }

    .custom-control-input:checked~.custom-control-label::before {
      background-color: ${({ theme }) => theme.switchInputBkg};
      border-color: ${({ theme }) => theme.customSwitchBorderColor};
    }
    .custom-switch .custom-control-input:checked~.custom-control-label::after {
      transform: translateX(1.25rem);
    }
  }

  .social-link {
    color: ${({ theme }) => theme.userProfileSocialLingColor};
    font-size: 1.25rem;
    margin-right: 1rem;

    &:hover {
      color: ${({ theme }) => theme.userProfileSocialLingHoverColor};
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
      justify-content: center;

      > * {
        flex-grow: 1;
      }
    }

    button {
      margin-right: .5rem;

      @media (max-width: 575.9px) {
        margin-top: .5rem;
        margin-bottom: .5rem;
        width: 100%;
        justify-content: center;
        margin-right: 0;
      }
    }
  }
`
