import BillsActionTypes from './bills.types'

const INITIAL_STATE = {
  items: [],
  attachment: null,
  attachmentModalVisibility: false,
  isAttachmentLoading: false,
  isLoading: false,
  filters: {},
  sorting: '-date',
  selectedPage: 1,
  totalItems: 0,
  error: null,
  utilityModal: {
    isVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const billsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case BillsActionTypes.FETCH_BILLS_START:
      return {
        ...state,
        isLoading: true,
        filters: action.payload?.filters || state.filters,
        selectedPage: action.payload?.filters ? 1 : ( action.payload?.selectedPage || state.selectedPage)
      }

    case BillsActionTypes.FETCH_BILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        totalItems: action.payload.records
      }

    case BillsActionTypes.FETCH_BILLS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case BillsActionTypes.SET_BILLS_FILTERS:
      return {
        ...state,
        filters: action.payload
      }

    case BillsActionTypes.RESET_BILLS_FILTERS:
      return {
        ...state,
        filters: {}
      }

    case BillsActionTypes.SET_BILLS_SORTING:
      return {
        ...state,
        sorting: action.payload
      }

    case BillsActionTypes.RESET_BILLS_SORTING:
      return {
        ...state,
        sorting: '-date'
      }

    case BillsActionTypes.SET_ACTIVE_PAGE:
      return {
        ...state,
        selectedPage: action.payload
      }

    case BillsActionTypes.FETCH_DOCUMENT_START:
      return {
        ...state,
        isAttachmentLoading: true,
        attachmentModalVisibility: true
      }

    case BillsActionTypes.FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        attachment: action.payload,
        isAttachmentLoading: false
      }

    case BillsActionTypes.FETCH_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isAttachmentLoading: false,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case BillsActionTypes.TOGGLE_ATTACHMENT_MODAL_VISIBILITY:
      return {
        ...state,
        attachmentModalVisibility: !state.attachmentModalVisibility
      }

    case BillsActionTypes.TOGGLE_BILLS_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    default:
      return state
  }
}

export default billsReducer
