import SelfMeterReadActionTypes from './selfMeterRead.types'

export const toggleUtilityModalVisibility = () => ({
  type: SelfMeterReadActionTypes.TOGGLE_SELF_METER_READ_UTILITY_MODAL_VISIBILITY
})

export const fetchSelfMeterReadsStart = () => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READS_START
})

export const fetchSelfMeterReadsSuccess = data => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READS_SUCCESS,
  payload: data
})

export const fetchSelfMeterReadsFailure = error => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READS_FAILURE,
  payload: error
})

export const fetchSelfMeterReadItemStart = data => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_START,
  payload: data
})

export const fetchSelfMeterReadItemSuccess = data => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_SUCCESS,
  payload: data
})

export const fetchSelfMeterReadItemFailure = error => ({
  type: SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_FAILURE,
  payload: error
})

export const submitSelfMeterReadStart = data => ({
  type: SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_START,
  payload: data
})

export const submitSelfMeterReadSuccess = data => ({
  type: SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_SUCCESS,
  payload: data
})

export const submitSelfMeterReadFailure = error => ({
  type: SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_FAILURE,
  payload: error
})

export const resetSelfMeterReadingItems = () => ({
  type: SelfMeterReadActionTypes.RESET_SELF_METER_READ_ITEMS
})