import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PdpShortcutModalStyle from './style'
import ButtonWithLoader from '../../../../ButtonWithLoader/ButtonWithLoader.component'
import { getAPIUrl } from '../../../../../ABapi'
import { togglePdpShortcutModal, fetchAvailablePdpStart } from '../../../../../redux/cadastralData/cadastralData.actions'
import { selectCadastralData, selectIsFetchingCadastralData, selectSelectedCadastralData } from '../../../../../redux/cadastralData/cadastralData.selectors'

const PdpShortcutModal = () => {
  const dispatch = useDispatch()
  const { addPdpShortcutModalVisibility } = useSelector(selectCadastralData)
  const toggle = () => dispatch(togglePdpShortcutModal())
  const selectedCadastralData = useSelector(selectSelectedCadastralData)
  const isFetchingPdpItems = useSelector(state => selectIsFetchingCadastralData(`fetchAvailablePdp-${selectedCadastralData?.id}`)(state))

  return (
    <Modal isOpen={addPdpShortcutModalVisibility} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={['fas', 'badge-check']} className="custom-icon" />
        Dati catastali
      </ModalHeader>
      <ModalBody>
        <PdpShortcutModalStyle>
          <h3>
            Hai creato i tuoi dati catastali
          </h3>
          <p>Vuoi associare le utenze subito o più tardi?</p>
          <div className="action-container">
            <Button
              className="ab-button outline mr-2"
              onClick={toggle}
            >
              Più tardi
            </Button>
            <ButtonWithLoader
              type="submit"
              otherClasses="ab-button"
              buttonLabel="Associa utenze"
              isFetching={isFetchingPdpItems}
              onClick={() => 
                dispatch(fetchAvailablePdpStart({ 
                  apiUrl: `${getAPIUrl()}/cadastral-data/${selectedCadastralData.id}/association`,
                  cadastralDataItemId: selectedCadastralData.id
                }))
              }
            />
          </div>
        </PdpShortcutModalStyle>
      </ModalBody>
    </Modal>
  )
}

export default PdpShortcutModal