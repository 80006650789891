import styled from 'styled-components'

const ForgotPasswordModalStyle = styled.div`
  .forgot-password-btn {
    font-size: .8rem;
    padding: 0;
    color: #0065a7;
    

    &:hover {
      text-decoration: underline;
      color: #0065a7;
    }
  }

  .forgot-password-modal {
  }
`

export default ForgotPasswordModalStyle