import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { selectUserMaintenanceState } from "../../redux/user/user.selectors"
import { useSelector } from "react-redux"

const AppDismissSelfMeterReadingModal = () => {
    const [isVisible, setIsVisible] = useState(true)
    const toggle = () => setIsVisible(!isVisible)

    // Get the maintenance state from the redux store
    const maintenanceState = useSelector(selectUserMaintenanceState)
    const { selfreading_message, show_messages } = maintenanceState?.app_dismiss_alert

    return (
        <>
            {
                show_messages && (
                    <Modal isOpen={isVisible} toggle={toggle}>
                        <ModalBody>
                            {selfreading_message}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={toggle}>
                                Ok
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            }
        </>
    )
}

export default AppDismissSelfMeterReadingModal