import styled from 'styled-components'

const WithSkeletonStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;

  header {
    margin-bottom: 1rem;
  }
`

export default WithSkeletonStyle