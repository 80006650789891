import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import queryString from 'query-string'

import { withRouter } from '../../components/AppRouter/utils'
import FirebaseAuthPageStyle from './style'
import { setOobCode } from '../../redux/user/user.actions'
import ResetPassword from '../../components/firebase-auth/ResetPassword/ResetPassword.component'
import UserModal from '../../components/UserModal/UserModal.component'

const FirebaseAuthPage = ({ router: { location: { search } } }) => {
  const dispatch = useDispatch()
  const { mode, oobCode } = queryString.parse(search)

  useEffect(() => {
    if(oobCode) dispatch(setOobCode(oobCode))
  }, [oobCode, dispatch])

  return(
    <FirebaseAuthPageStyle>
      <Routes>
        <Route
          path="*"
          element={
            <>
              {!mode && <Navigate to="/" />}
              {mode === 'resetPassword' &&
                <ResetPassword />
              }
            </>
          }
        />
      </Routes>
      <UserModal />
    </FirebaseAuthPageStyle>
  )
}

export default withRouter(FirebaseAuthPage)
