import React, { useState } from 'react'
import { Button, ModalHeader, ModalBody } from 'reactstrap'

import './style.scss'
import DownloadAppModalStyle from './style'
import AppImage from '../../assets/download-app.jpg'

const UtilityModal = ({ deviceType }) => {
  const [ isVisible, setIsVisible ] = useState(true)
  const toggle = () => setIsVisible(!isVisible)
  const storeUrl = deviceType === 'iOS' ? 'https://apps.apple.com/app/id1500018175' : 'https://play.google.com/store/apps/details?id=it.abenergie.customerarea'

  return(
    <DownloadAppModalStyle isOpen={isVisible} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Scarica l'App!
      </ModalHeader>
      <ModalBody>
        <a href={storeUrl} rel="noreferrer noopener">
          <img className="img-fluid" src={AppImage} alt="Download My ABenergie app" />
        </a>
        <div className="store-link">
          <Button
            className="ab-button"
            href={storeUrl} 
            rel="noreferrer noopener"
          >
            Scaricala dall'App Store
          </Button>
        </div>
      </ModalBody>
    </DownloadAppModalStyle>
  )
}

export default UtilityModal
