import SelfMeterReadActionTypes from './selfMeterRead.types'
import { updateItem, fetchItem } from './selfMeterRead.utils'

const INITIAL_STATE = {
  items: [],
  isLoading: false,
  isSubmittingSelfMeterRead: false,
  error: null,
  utilityModal: {
    isVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const selfMeterReadReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SelfMeterReadActionTypes.FETCH_SELF_METER_READS_START:
      return {
        ...state,
        isLoading: true
      }

    case SelfMeterReadActionTypes.FETCH_SELF_METER_READS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
        error: null
      }

    case SelfMeterReadActionTypes.FETCH_SELF_METER_READS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fad', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_START:
      return {
        ...state,
        items: fetchItem(state, action.payload)
      }

    case SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_SUCCESS:
      return {
        ...state,
        items: updateItem(state, action.payload)
      }

    case SelfMeterReadActionTypes.FETCH_SELF_METER_READ_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fad', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_START:
      return {
        ...state,
        isSubmittingSelfMeterRead: true
      }

    case SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_SUCCESS:
      return {
        ...state,
        isSubmittingSelfMeterRead: false,
        items: updateItem(state, action.payload)
      }

    case SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_FAILURE:
      return {
        ...state,
        isSubmittingSelfMeterRead: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fad', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SelfMeterReadActionTypes.TOGGLE_SELF_METER_READ_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    case SelfMeterReadActionTypes.RESET_SELF_METER_READ_ITEMS:
      return {
        ...INITIAL_STATE
      }

    default:
      return state
  }
}

export default selfMeterReadReducer
