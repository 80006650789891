//!IMPORTANT This component presumes you're using react-final-form
import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { Input, FormFeedback, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomNumberFieldStyle from './style'

const CustomNumberField = ({ updateFieldValue, ...props }) => {
  const { name } = props
  const [ value, setValue ] = useState(0)
  useEffect(() => {
    updateFieldValue(name, value)
  }, [name, value, updateFieldValue])

  const addValue = e => {
    e.preventDefault()
    if(value === 9) return
    updateFieldValue(name, value + 1)
    setValue(value + 1)
  }

  const subtractValue = e => {
    e.preventDefault()
    if(value === 0) return
    updateFieldValue(name, value - 1)
    setValue(value - 1)
  }

  return (
    <CustomNumberFieldStyle>
      <Field {...props}>
        {({ input, meta: { error, touched }, ...otherProps }) => (
          <>
            <Input invalid={error && touched ? true : false} {...input} value={value} {...otherProps} />
            <FormFeedback>{error}</FormFeedback>
          </>
        )}
      </Field>
      <Button onClick={addValue} className="increment-btn add">
        <FontAwesomeIcon icon={['fas', 'plus-circle']} />
      </Button>
      <Button onClick={subtractValue} className="increment-btn subtract">
        <FontAwesomeIcon icon={['fas', 'minus-circle']} />
      </Button>
    </CustomNumberFieldStyle>
  )
}

export default CustomNumberField
