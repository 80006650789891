import ls from 'local-storage'

import { fetchData, getAPIUrl, submitRequest } from '../../ABapi'
import { FirebaseErrorMessagesIt } from '../../firebase/firebase-utils'

export const translateFirebaseErrorMessage = error => FirebaseErrorMessagesIt[error.code] || error.message

export const setThemeMode = (currentUserTheme) => {
  const newUserTheme = currentUserTheme === 'light' ? 'dark' : 'light'
  ls.set('themeMode', newUserTheme)
  return newUserTheme
}

export const initThemeMode = ({ customerCodes }) => {
  if(customerCodes.length === 0) {
    return 'light'
  }

  return ls.get('themeMode') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
}

export const updateItem = ({ currentUser: { customerCodes } }, data) => {
  const { code } = data
  let itemToUpdate = customerCodes[code]
  itemToUpdate = {
    ...itemToUpdate,
    ...data,
    toFetch: false
  }
  customerCodes[code] = itemToUpdate

  return customerCodes
}

export const fetchItem = ({ currentUser: { customerCodes } }, { customerCode }) => {
  let itemToUpdate = customerCodes[customerCode]
  itemToUpdate = {
    ...itemToUpdate,
    toFetch: true
  }
  customerCodes[customerCode] = itemToUpdate

  return customerCodes
}

export const toggleItemSubmitting = ({ currentUser: { customerCodes } }, { customerCode }) => {
  let itemToUpdate = customerCodes[customerCode]
  itemToUpdate = {
    ...itemToUpdate,
    isSubmitting: !itemToUpdate.isSubmitting
  }
  customerCodes[customerCode] = itemToUpdate

  return customerCodes
}

export function deleteCustomerData(code, idToken) {
  return submitRequest(`${getAPIUrl()}/customers/${code}`, idToken, null, 'DELETE')
    .then(response => response)
}

export function getCustomerData(code, idToken) {
  return fetchData(`${getAPIUrl()}/customers/${code}`, idToken)
    .then(response => response)
}

export const setUpCustomerCodes = ({ customerCodes }) => {
  const transformedCustomerCodes = customerCodes.reduce((accumulator, code) => {
    accumulator[code] = { code: code, toFetch: true, isSubmitting: false }
    return accumulator
  }, {})
  return transformedCustomerCodes
}

export const setProviderErrorModalContent = (email) => {
  return {
    modalTitle: 'Attenzione!',
    modalDescription: `L'email ${email} è già registrata con un account che utilizza un provider diverso da quello selezionato!`,
    modalIcon: ['fas', 'siren-on']
  }
}

export const setResetPasswordEmailSentModalContent = email => {
  return {
    modalTitle: 'Reset password',
    modalDescription: `Abbiamo inviato a ${email} un'email contenente un link che ti permetterà di reimpostare la tua password.`,
    modalIcon: ['fas', 'recycle']
  }
}

export const setConfirmResetPasswordEmailSentModalContentSuccess = () => {
  return {
    modalTitle: 'Reset password',
    modalDescription: 'La tua password è stata reimpostata con successo!',
    modalIcon: ['fas', 'check']
  }
}

export const setConfirmResetPasswordEmailSentModalContentError = errorMessage => {
  return {
    modalTitle: 'Reset password',
    modalDescription: errorMessage,
    modalIcon: ['fas', 'siren-on']
  }
}

export const credentialsUsedByAnotherProvider = (signInMethods, providerType) => {
  if(signInMethods.length && signInMethods.indexOf(providerType) === -1) {
    //Email exists and the provider is different from the one looking for
    return true
  }
  else if(signInMethods.length && signInMethods.indexOf(providerType) !== -1) {
    //Email exists and provider is the one looking for
    return false
  }
  //Email doesn't exist
  return -1
}

export function updateUser(values, idToken) {
  return submitRequest(`${getAPIUrl()}/users/`, idToken, values, 'PUT')
    .then(response => response)
}

export function signInUserWithABapi(idToken) {
  return fetchData(`${getAPIUrl()}/users/`, idToken)
    .then(response => response)
}

export function signUpUserWithABapi(values, idToken) {
  return submitRequest(`${getAPIUrl()}/users/`, idToken, values)
    .then(response => response)
}

export function getOtpMethods(clientCode, idToken) {
  return fetchData(`${getAPIUrl()}/customers/${clientCode}/registration-info`, idToken)
    .then(response => response)
}

export function initOtpTransaction(transactionType, clientCode, idToken) {
  return submitRequest(`${getAPIUrl()}/customers/${clientCode}/otp`, idToken, { type: parseInt(transactionType) } )
    .then(response => response)
}

export function verifyOtp(otpCode, transactionId, idToken) {
  return submitRequest(`${getAPIUrl()}/customers/otp/verify`, idToken, { otpCode, transactionId })
    .then(response => response)
}
