import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import SelfMeterReadOverviewContainer from '../../components/customer-area/SelfMeterReadOverview/SelfMeterReadOverviewContainer.component'
import { fetchSelfMeterReadsStart, resetSelfMeterReadingItems } from '../../redux/selfMeterRead/selfMeterRead.actions'
import AppDismissSelfMeterReadingModal from '../../components/AppDismissSelfMeterReadingModal/AppDismissSelfMeterReadingModal.component'

const SelfMeterReadingPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSelfMeterReadsStart())

    return () => {
      dispatch(resetSelfMeterReadingItems())
    }
  }, [dispatch])

  return (
    <div className="self-meter-reading-page">
      <SelfMeterReadOverviewContainer />
      <AppDismissSelfMeterReadingModal />
    </div>
  )
}

export default SelfMeterReadingPage
