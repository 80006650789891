import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { withRouter } from '../../AppRouter/utils'
import { SupplyPointItemPreviewStyle } from './style'
import { ReactComponent as Bolt } from '../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'
import { ReactComponent as GreenOption } from '../../../assets/svg/penso-verde.svg'

const SupplyPointItemPreview = ({ item, router: { location, navigate } }) => {
  const { status, alias, serviceType, number, greenOption, offerName, delivery: { address, city } } = item

  return (
    <SupplyPointItemPreviewStyle
      onClick={() => navigate(`${location.pathname}/${serviceType === 1 ? 'electrical' : 'gas'}/${number}`)}
      className={`supply-point-item-preview ${serviceType === 1 ? 'electrical' : 'gas'}`}
    >
      <header>
        <div className="point-names">
          <span className="forniture-type">Fornitura {serviceType === 1 ? 'energia' : 'gas'}</span>
          <span className="alias">{alias}</span>
        </div>
        <div className="icons" title="Fornitura energia">
          {greenOption &&
              <GreenOption 
                className="custom-icon mr-2"
              />
          }
          <div className="type-icon">
            {serviceType === 1 ?
              <Bolt className="custom-icon" />
              :
              <Flame className="custom-icon" />
            }
          </div>
        </div>
      </header>
      <main>
        <div className="supply-point-data">
          <div className="data-group">
            <span className="address">{address}, {city}</span>
            <span className="offer-name">{offerName}</span>
            <label className="forniture-type">{serviceType === 1 ? 'POD' : 'PDR'}</label>
            <span className="code">{number}</span>
            <span className={classNames({
                "mt-1": true,
                "pdp-status": true,
                "closed": status === "closed",
              })}
              title={status === "closed" ? "Cessata" : "Attiva"}
            >
              <span className="status-label">Stato fornitura:</span>
              {status === 'closed' ? (
                <>
                  <FontAwesomeIcon icon="circle-xmark" /> Cessata
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon="circle-check" /> Attiva
                </>
              )}
            </span>
          </div>
          <div className="see-detail-cta">
            <span>dettagli</span>
            <FontAwesomeIcon icon={['fas', 'angle-right']} />
          </div>
        </div>
      </main>
    </SupplyPointItemPreviewStyle>
  )
}

export default withRouter(SupplyPointItemPreview)
