import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalHeader } from 'reactstrap'

import AttachmentModalStyle from './style'
import { toggleAttachmentModalVisibility } from '../../redux/bills/bills.actions'
import { selectAttachmentModalVisibility } from '../../redux/bills/bills.selectors'
import AttachmentModalBodyContainer from './AttachmentModalBodyContainer.component'

const AttachmentModal = () => {
  const dispatch = useDispatch()
  const isAttachmentModalOpen = useSelector(selectAttachmentModalVisibility)
  const toggleAttachmentModal = () => dispatch(toggleAttachmentModalVisibility())

  return (
    <AttachmentModalStyle isOpen={isAttachmentModalOpen} toggle={toggleAttachmentModal}>
      <ModalHeader toggle={toggleAttachmentModal}>
        Visualizza documento
      </ModalHeader>
      <AttachmentModalBodyContainer />
    </AttachmentModalStyle>
  )
}

export default AttachmentModal
