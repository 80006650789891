import styled from 'styled-components'
import { Modal } from 'reactstrap'

const SelfMeterReadModalFormStyle = styled(Modal)`
  overflow: hidden;

  .modal-content {
    overflow: hidden;

    .modal-header {
      background-color: ${({ theme }) => theme.selfMeterReadModalFormHeaderBkg};;
      color: ${({ theme }) => theme.selfMeterReadModalFormTitleColor};
      padding: 1.5rem;

      .close {
        color: ${({ theme }) => theme.selfMeterReadModalFormTitleColor};
        @media (max-width: 575.9px) {
          font-size: 2rem;
        }
      }

      .custom-icon {
        margin-right: .75rem;
        fill: ${({ theme }) => theme.selfMeterReadModalFormTitleColor};
        
        @media (max-width: 575.9px) {
          display: none;
        }
      }
    }

    .modal-body {
      padding: 0;
      background-color: ${({ theme }) => theme.selfMeterReadModalFormBodyBkg};;
    }

    .info-trigger {
      cursor: pointer;
      #info .st0 {
        fill: ${({ theme }) => theme.selfMeterReadModalFormTitleIconColor}
      }
    }

    .description {
      padding: 0 1.5rem;

      p {
        font-weight: 600;
        color: ${({ theme }) => theme.selfMeterReadModalFormDescriptionColor};

        &.alt-color {
          color: ${({ theme }) => theme.selfMeterReadModalFormAltDescriptionColor};
        }
      }
        
      .alert {
        color: ${({ theme }) => theme.selfMeterReadModalFormDescriptionColor};
        border: none;
        padding: 0;
        margin: 0;
        font-weight: 700;
      }
    }

    .self-meter-read-form {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.selfMeterReadModalFormBkg};

      @media (max-width: 575.9px) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 1.5rem 0 1rem;
      }

      .self-meter-inputs-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.5rem 1rem;
        max-width: 500px;
        margin: 1.5rem auto;
        flex-basis: 60%;

        @media (max-width: 575.9px) {
          padding: .5rem;
          flex-basis: 100%;
        } 

        .measuring-unit {
          font-weight: 400;
          font-size: .85rem;
          margin-right: .5rem;
          color: #fff;
          font-weight: 600;
          text-transform: uppercase;

          @include media-breakpoint-down(sm) {
            font-size: .75rem;
          }
        }

        .comma {
          font-size: 3rem;
          color: #fff;
          margin: 0 .15rem;
        }

        .form-group {
          margin-left: .15rem;
          margin-right: .15rem;
          margin-bottom: 0;

          .form-control {
            text-align: center;
            max-width: 30px;
            height: 50px;
            font-size: 1rem;
            color: #666;
            background-color: #fff;
            border: none;
            padding: 0;

            &[readonly] {
              color: #fff;
              background-color: #dd3131;
              max-width: 20px;
              padding: 0;
            }

            @media (max-width: 575.9px) {
              font-size: 1rem;
              padding: 0;
            }
          }
        }
      }

      .submit-container {
        padding: 0 .5rem;
        flex-grow: 1;

        @media (max-width: 575.9px) {
          flex-grow: 0;
        }
      }

      .submit-error {
        color: #dd3131;
        margin: 1.5rem auto;
        font-weight: 600;
      }

      button[type="submit"] {
        margin: 1.5rem auto;
        border: 1px solid #fff;

        &:hover {
          border: ${({ theme }) => theme.selfMeterReadModalFormBorderBtnHover};
        }

        @media (max-width: 575.9px) {
          margin: 1rem 0;
        }
      }
    }
  }
`

export default SelfMeterReadModalFormStyle