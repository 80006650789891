import styled from 'styled-components'

const CustomersDataStyle = styled.div`
  box-shadow: ${({ theme }) => theme.mainBoxShadow};
  overflow: hidden;
  margin: .75rem 0;
  margin-right: .75rem;
  min-width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  position: relative;
  color: ${({ theme }) => theme.customersDataTextColor};
  background-color: ${({ theme }) => theme.itemBackgroundColor};

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 767.9px) {
      flex-wrap: wrap;
    }

    .client-code {
      font-size: 2.2rem;
      font-weight: 700;
      color: ${({ theme }) => theme.customersDataTextColor };
    }

    button.delete-customer-data {
      @media (max-width: 575.9px) {
        display: none;
      }
    }
  }

  main {
    button.delete-customer-data {
      margin: auto;
      @media (min-width: 576px) {
        display: none;
      }
    }
  }

  button.delete-customer-data {
    font-size: .85rem;
    border: ${({ theme }) => theme.deleteButtonBorder};
    color: ${({ theme }) => theme.deleteButtonColor};
    background-color: ${({ theme }) => theme.deleteButtonBkg};

    &:hover {
      background-color: ${({ theme }) => theme.deleteButtonHoverBkg};
      border: ${({ theme }) => theme.deleteButtonHoverBorder};

      > svg path {
        fill: ${({ theme }) => theme.deleteButtonColor};
      }
    }
  }

  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0;
    display: flex;
    align-items: center;

    > svg {
      margin-right: .5rem;
      font-size: 2.25rem;
    }
  }

  .user-data-group {
    margin-bottom: 1rem;
    display: flex;
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.userProfileDataGroupBorderColor};
    padding: 1rem 0;
    
    &:last-child,
    &.no-border {
      border: none;
    }

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
    }

    .data-group {
      @media (min-width: 1200px) {
        max-width: 33.33%;
      }

      @media (max-width: 575.9px) {
        margin-bottom: 1rem;
      }

      &.full-width {
        max-width: none;
        
        @media (max-width: 575.9px) {
          margin: 0;
        }
      }
    }

    .code {
      font-weight: 700;
      margin-top: 0;

      a {
        color: ${({ theme }) => theme.customersDataTextColor};
        font-weight: 700;
      }
    }
  }
`

export default CustomersDataStyle
