import styled from 'styled-components'

export const UserOtpTypeFormStyle = styled.div`
  .title {
    font-size: 1.15rem;
    font-weight: 600;
    color: ${({ theme }) => theme.userOtpTypeTitleColor};
    margin-bottom: .15rem;
  }

  .description {
    font-size: .85rem;
  }
`
