import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import BillsActionTypes from './bills.types'
import { fetchBillsSuccess, fetchBillsFailure, fetchDocumentSuccess, fetchDocumentFailure } from './bills.actions'
import { selectIdToken, selectCustomerCodesArray } from '../user/user.selectors'
import { selectFilters, selectSorting, selectCurrentPage } from './bills.selectors'
import { getBills, getDocumentAsBase64 } from './bills.utils'

export function* fetchBills() {
  try {
    const idToken = yield select(selectIdToken)
    let filters = yield select(selectFilters)
    const customerCodes = yield select(selectCustomerCodesArray)
    //TOOPTIMIZE
    if(!filters.customerNumber && customerCodes.length) {
      filters.customerNumber = customerCodes.join('|')
    }
    const sorting = yield select(selectSorting)
    const page = yield select(selectCurrentPage)
    const response = yield call(getBills, idToken, filters, sorting, page)
    const { data: { items, records } } = response
    yield put(fetchBillsSuccess({ items, records }))
  }
  catch(error) {
    yield put(fetchBillsFailure(error))
  }
}

export function* onFetchBillsStart() {
  yield takeLatest(
    BillsActionTypes.FETCH_BILLS_START,
    fetchBills
  )
}

export function* fetchDocument({ payload: { documentId, documentType } }) {
  try {
    const idToken = yield select(selectIdToken)
    const { data: { file } } = yield call(getDocumentAsBase64, documentId, documentType, idToken)
    yield put(fetchDocumentSuccess(file))
  }
  catch(error) {
    yield put(fetchDocumentFailure(error))
  }
}

export function* onFetchDocumentStart() {
  yield takeLatest(
    BillsActionTypes.FETCH_DOCUMENT_START,
    fetchDocument
  )
}

export function* billsSagas() {
  yield all([
    call(onFetchBillsStart),
    call(onFetchDocumentStart)
  ])
}
