import styled from 'styled-components'

const NoMatchPageStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.notFoundPageBkg};

  .logo {
    width: 220px;
  }

  .title {
    font-weight: 700;
    font-size: 2.5rem;
  }

  p, .title {
    color: #fff;
  }

  p {
    margin-bottom: .25rem;
  }

  code {
    color: #ffc525;
  }

  a {
    color: #ffc525;
    text-decoration: underline;
  }
`

export default NoMatchPageStyle