import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { selectIsFetchingSupplyPoints, selectSupplyPointsItems } from '../../../redux/supplyPoints/supplyPoints.selectors'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import SupplyPointsOverview from './SupplyPointsOverview.component'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingSupplyPoints,
  supplyPoints: selectSupplyPointsItems
})

const SupplyPointsOverviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(SupplyPointsOverview)

export default SupplyPointsOverviewContainer
