import styled from "styled-components"
import AsyncSelect from 'react-select/async'

const StyledExtendedAutocompleteFormField = styled(AsyncSelect)`
  .react-autocomplete__placeholder {
    color: ${({ theme }) => theme.inputColor};
    opacity: .3;
    font-weight: 400;
    font-size: .85rem;
  }

  .react-autocomplete__control {
    background-color: transparent;
    padding: .28rem;
    color: ${({ theme }) => theme.inputColor};
    border-color: ${({ theme }) => theme.inputBorderColor};
    box-shadow: none;

    &:hover {
      border-color: ${({ theme }) => theme.hoverInputBorderColor};
    }
  }

  .react-autocomplete__menu-list {
    background-color: ${({ theme }) => theme.itemBackgroundColor};
  }

  .react-autocomplete__single-value {
    color: ${({ theme }) => theme.inputColor};
    font-size: .85rem;
    font-weight: 500;
  }

  .react-autocomplete__indicator-separator {
    background-color: ${({ theme }) => theme.inputBorderColor};
  }

  .react-autocomplete__dropdown-indicator {
    color: ${({ theme }) => theme.inputBorderColor};
  }

  .react-autocomplete__input-container {
    color: ${({ theme }) => theme.inputColor};
    font-weight: 500;
    font-size: .85rem;
  }

  .react-autocomplete__input {
    color: ${({ theme }) => theme.inputColor} !important;
    font-weight: normal !important;
    font-size: .9rem !important;
  }

  .react-autocomplete__option {
    font-size: .85rem;
    font-weight: 400;

    &.react-autocomplete__option--is-focused {
      background-color: ${({ theme }) => theme.focusedOption};
    }

    &.react-autocomplete__option--is-selected {
      background-color: ${({ theme }) => theme.selectedOption};
    }
  }

  .react-autocomplete__menu-notice {
    font-weight: 400;
    font-size: .85rem;
  }
`

export default StyledExtendedAutocompleteFormField