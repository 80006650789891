import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchBillsStart } from '../../redux/bills/bills.actions'
import { selectSorting } from '../../redux/bills/bills.selectors'
import BillsList from '../../components/customer-area/BillsList/BillsList.component'
import AppDismissBillsModal from '../../components/AppDismissBillsModal/AppDismissBillsModal.component'

const BillsPage = () => {
  const dispatch = useDispatch()
  const sorting = useSelector(selectSorting)

  useEffect(() => {
    dispatch(fetchBillsStart())
  }, [dispatch, sorting])

  return (
    <div className="bills-page">
      <BillsList />
      <AppDismissBillsModal />
    </div>
  )
}

export default BillsPage
