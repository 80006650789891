import React from 'react'
import { NavLink } from 'react-router-dom'

import { BrandLogoStyle } from './style'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'

const BrandLogo = () => (
  <BrandLogoStyle className="brand-logo">
    <NavLink to="/punti-fornitura">
      <Logo className="logo" title="Torna alla Home page" />
    </NavLink>
  </BrandLogoStyle>
)

export default BrandLogo
