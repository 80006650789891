import React from 'react'
import { ReactComponent as Logo } from '../../assets/svg/ab-logo.svg'
import PortalRedirectStyle, { PortalRedirectModalStyle } from './style'
import PortalRedirectButton from '../../components/PortalRedirectButton/PortalRedirectButton.component'
import confJson from '../../ABapi/configuration.json'

const PortalRedirectPage = () => {
    return (
        <PortalRedirectStyle>
            <PortalRedirectModalStyle>
                <header>
                    <Logo className="ab-logo" />
                </header>
                <main>
                    <h1 className="step-title">
                        Accesso all'area clienti
                    </h1>
                    <p className="form-description">
                        Vuoi consultare le nuove fatture o effettuare l'autolettura del gas? Iscriviti al nuovo portale usando il codice cliente che trovi nella fattura di agosto 2024
                    </p>
                    <div className="buttons-list">
                        <PortalRedirectButton name="Vai al nuovo portale" to={confJson.externalLinks.newPortal} />
                    </div>
                    <hr />
                    <p className="form-description">
                        Vuoi consultare lo storico delle fatture emesse fino a luglio 2024? Accedi al portale precedente
                    </p>
                    <div className="buttons-list">
                        <PortalRedirectButton name="Vai al portale precedente" to="/login-form" />
                    </div>
                </main>
            </PortalRedirectModalStyle>
        </PortalRedirectStyle>
    )
}

export default PortalRedirectPage