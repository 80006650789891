import { createGlobalStyle } from 'styled-components'

export const TableRowStyle = createGlobalStyle`
  .bills-list {
    .bills-list-body {
      box-shadow: ${({ theme }) => theme.mainBoxShadow};
      font-size: .7rem;
      overflow: hidden;

      tr:first-child {
        td:first-child {
          border-radius: 10px 0 0 0;
        }
        td:last-child {
          border-radius: 0 10px 0 0;
        }
      }

      tr:last-child {
        td {
          border-bottom: none;
        }

        td:first-child {
          border-radius: 0 0 0 10px;
        }
        td:last-child {
          border-radius: 0 0 10px 0;
        }
      }

      td {
        border: none;
        vertical-align: middle;
        vertical-align: middle;
        color: ${({ theme }) => theme.billsTdColor};
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.billdTdBorderColor};
        padding: .75rem 1rem;
        font-weight: 600;

        &.no-results {
          font-weight: 700;
          font-size: .85rem;
          padding: 1.5rem;
          text-align: center;
        }

        &.active-col {
          background-color: ${({ theme }) => theme.billsActiveColBkg};
        }

        &.bill-number {
          > span {
            font-weight: 600;
          }

          .custom-icon {
            margin-right: .75rem;
            vertical-align: middle;
            height: auto;

            @media (max-width: 575.9px) {
              margin-bottom: .25rem;
            }

            path {
              fill: ${({ theme }) => theme.billsListBodyIconFill};
            }
          }
        }

        &.bill-file {
          text-align: center;
          font-size: 1.25rem;

          button {
            display: inline-block;
            color: ${({ theme }) => theme.billsDownloadBtnColor};
            transition: transform .2s ease-in;

            &:hover {
              .custom-icon .st0 {
                fill: ${({ theme }) => theme.billsDownloadBtnHoverColor} !important;
              }
              transform: scale(1.1);
            }
          }
          
          .custom-icon {
            width: 1.5rem;
          }
        }

        &.amount {
          .bill-status {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .5rem;
            border-radius: 25px;
            border: none;
            font-size: .85rem;

            .icon-status {
              margin-right: .5rem;
              color: #fff;
              width: 1rem;
            }

            .total-amount {
              color: #fff;
            }

            &.bill-status-1 {
              background-color: #67D167;
            }
            &.bill-status-2 {
              background-color: #EB9D09;
            }
            &.bill-status-3 {
              background-color: #D65050;
            }
          }
        }
      }
    }
  }
`
