import { getAPIUrl, fetchData, submitRequest } from "../../ABapi"

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const fetchCadastralData = idToken => {
  return fetchData(`${getAPIUrl()}/cadastral-data`, idToken)
    .then(response => response)
}

export const fetchCadastralItem = (apiUrl, idToken) => {
  return fetchData(apiUrl, idToken)
   .then(response => response)
}

export const fetchAvailablePdpItems = (apiUrl, idToken) => {
  return fetchData(apiUrl, idToken)
    .then(response => response)
}

export const fetchCadastralFormItems = idToken => {
  return fetchData(`${getAPIUrl()}/cadastral-data/items`, idToken)
    .then(response => response)
}

export const submitItem = (apiUrl, idToken, values, method) => {
  return submitRequest(apiUrl, idToken, values, method)
    .then(response => response)
}

export const deleteItem = (apiUrl, idToken) => {
  return submitRequest(apiUrl, idToken, null, "DELETE")
    .then(response => response)
}

export function normalizeCadastralDataItems(items) {
  const normalizedData = items.reduce((accumulator, item) => {
    accumulator[item.id] = item
    return accumulator
  }, {})
  return normalizedData
}

export const updateCollectionItem = (isNewItem, item, collection) => {
  if(isNewItem) {
    return {
      [item.id]: item,
      ...collection
    }
  }
  else {
    return Object.keys(collection).reduce((accumulator, id) => {
      if(item.id === id) {
        accumulator[id] = item
      }
      else {
        accumulator[id] = collection[id]
      }
      return accumulator
    }, {})
  }
}

export const removeItemFromCollection = (itemId, collection) => {
  return Object.keys(collection).reduce((accumulator, id) => {
    if(itemId !== id) {
      accumulator[id] = collection[id]
    }
    return accumulator
  }, {})
}