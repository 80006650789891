import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isIOS, isAndroid, MobileView } from 'react-device-detect'
import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ThemeProvider } from 'styled-components'
import { detect } from 'detect-browser'

import './App.scss'
import AppRouterContainer from './components/AppRouter/AppRouterContainer.component'
import MaintenanceAlert from './components/MaintenanceAlert/MaintenanceAlert.component'
import DownloadAppModal from './components/DownloadAppModal/DownloadAppModal.component'
import { lightTheme, darkTheme } from './ThemeMode.style'
import GlobalStyles from './GlobalStyles.style'
import UpdateYourBrowserBitch from './components/UpdateYourBrowserBitch/UpdateYourBrowserBitch.component'
import { checkUserSessionStart } from './redux/user/user.actions'
import { selectUserTheme, selectUserMaintenanceState } from './redux/user/user.selectors'

library.add(fab, fad, fas)

function App() {
  const dispatch = useDispatch()
  const maintenanceState = useSelector(selectUserMaintenanceState)
  const theme = useSelector(selectUserTheme)
  useEffect(() => {
    dispatch(checkUserSessionStart())
  }, [dispatch])

  //Check browser
  const { name } = detect()
  const browserCheck = name === 'ie' ? false : true

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      {browserCheck === true ?
        <>
          <GlobalStyles />
          <MobileView>
            {isIOS &&
              <DownloadAppModal
                deviceType="iOS"
              />
            }
            {isAndroid &&
              <DownloadAppModal deviceType="android" />
            }
          </MobileView>

          <AppRouterContainer />
        </>
      :
        <UpdateYourBrowserBitch />
      }

      {maintenanceState?.show_maintenance_alert === true &&
        <MaintenanceAlert />
      }
    </ThemeProvider>
  )
}

export default App
