const SelfMeterReadActionTypes = {
  TOGGLE_SELF_METER_READ_UTILITY_MODAL_VISIBILITY: 'TOGGLE_SELF_METER_READ_UTILITY_MODAL_VISIBILITY',

  FETCH_SELF_METER_READS_START: 'FETCH_SELF_METER_READS_START',
  FETCH_SELF_METER_READS_SUCCESS: 'FETCH_SELF_METER_READS_SUCCESS',
  FETCH_SELF_METER_READS_FAILURE: 'FETCH_SELF_METER_READS_FAILURE',

  FETCH_SELF_METER_READ_ITEM_START: 'FETCH_SELF_METER_READ_ITEM_START',
  FETCH_SELF_METER_READ_ITEM_SUCCESS: 'FETCH_SELF_METER_READ_ITEM_SUCCESS',
  FETCH_SELF_METER_READ_ITEM_FAILURE: 'FETCH_SELF_METER_READ_ITEM_FAILURE',

  SUBMIT_SELF_METER_READ_START: 'SUBMIT_SELF_METER_READ_START',
  SUBMIT_SELF_METER_READ_SUCCESS: 'SUBMIT_SELF_METER_READ_SUCCESS',
  SUBMIT_SELF_METER_READ_FAILURE: 'SUBMIT_SELF_METER_READ_FAILURE',

  RESET_SELF_METER_READ_ITEMS: 'RESET_SELF_METER_READ_ITEMS',
}

export default SelfMeterReadActionTypes
