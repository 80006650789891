import styled from "styled-components"

const AlertStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;

  @media (min-width: 1200px) {
    max-width: 33vw;
  }
  
  &.warning {
    background-color: ${({ theme }) => theme.alertWarningBackgroundColor};
    color: ${({ theme }) => theme.alertWarningTextColor};
    border: 1px solid ${({ theme }) => theme.alertWarningBorderColor};
  }

  &.success {
    background-color: ${({ theme }) => theme.alertSuccessBackgroundColor};
    color: ${({ theme }) => theme.alertSuccessTextColor};
    border: 1px solid ${({ theme }) => theme.alertSuccessBorderColor};
  }

  .title {
    font-size: 1.15rem;
    font-weight: bold;
  }

  .description {
    font-size: .9rem;
    margin-bottom: 0;
  }

  .icon {
    margin-right: 1.5rem;
    font-size: 1.5rem;
  }
`

export default AlertStyle