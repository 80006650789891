import styled from 'styled-components'

export const BitchStyle = styled.div`
  background-image: linear-gradient(125deg,#0065a7,#298b8a);
  width: 100%;
  min-height: 100vh;

  .jumbotron {
    background-color: transparent;
    color: #f1f1f1;

    a {
      color: #ddd;
      font-weight: 600;
    }
  }
`
