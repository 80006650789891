import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import WithTableLoader from '../../WithTableLoader/WithTableLoader.component'
import BillsListBody from './BillsListBody.component'
import { selectIsFetchingBills, selectItems } from '../../../redux/bills/bills.selectors'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingBills,
  bills: selectItems
})

const BillsListBodyContainer = compose(
  connect(mapStateToProps),
  WithTableLoader
)(BillsListBody)

export default BillsListBodyContainer
