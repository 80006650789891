import styled from "styled-components"

const CadastralDataInfoModalStyle = styled.div`
  font-weight: 500;
  
  p {
    color: ${({ theme }) => theme.descriptionText} !important;

    strong {
      color: ${({ theme }) => theme.modalBodyTextColor};
    }
  }
  
  a {
    color: ${({ theme }) => theme.otpSignNewCodeLinkColor};
    text-decoration: underline;
    font-weight: 700;
  }
`

export default CadastralDataInfoModalStyle