import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DeleteCustomerCodeModalStyle } from './style'
import { deleteCustomerDataStart } from '../../../redux/user/user.actions'
import { selectCustomerCodeItemIsSubmitting } from '../../../redux/user/user.selectors'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const DeleteCustomerCodeModal = ({ customerCode }) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(state => selectCustomerCodeItemIsSubmitting(customerCode)(state))
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const handleDeleteConfirm = () => {
    dispatch(deleteCustomerDataStart({ customerCode }))
    toggle()
  }

  return (
    <>
      <DeleteCustomerCodeModalStyle />
      <ButtonWithLoader
        otherClasses="ab-button delete-customer-data"
        buttonLabel="Elimina codice cliente"
        onClick={toggle}
        isFetching={isSubmitting}
        disabled={isSubmitting}
        defaultIcon={['fas', 'trash-alt']}
      />
      <Modal isOpen={isOpen} toggle={toggle} className="delete-customer-code-modal">
        <ModalHeader toggle={toggle}>
          Elimina {customerCode}
        </ModalHeader>
        <ModalBody>
          <p>Confermi di voler eliminare questo codice cliente dal tuo profilo?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="ab-button cancel"
            onClick={toggle}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
            Annulla
          </Button>
          <Button
            className="ab-button"
            onClick={handleDeleteConfirm}
          >
            <FontAwesomeIcon icon={['fas', 'check']} />
            <span>Elimina</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteCustomerCodeModal
