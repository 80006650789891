import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import SelfMeterReadOverviewStyle from './style'
import SelfMeterReadItemDataContainer from '../SelfMeterReadItemData/SelfMeterReadItemDataContainer.component'
import ScrollToSeeMore from '../ScrollToSeeMore/ScrollToSeeMore.component'
import { toggleScrollToSeeVisibility } from './utils'
import { selectSelfReads } from '../../../redux/selfMeterRead/selfMeterRead.selectors'
import { fetchSelfMeterReadsStart } from '../../../redux/selfMeterRead/selfMeterRead.actions'
import UpdateDataButton from '../../UpdateDataButton/UpdateDataButton.component'

const SelfMeterReadOverview = () => {
  const dispatch = useDispatch()
  const items = useSelector(selectSelfReads)
  const handleScroll = e => {
    const scrollLeft = e.currentTarget.scrollLeft
    const toggleElement = e.currentTarget.querySelector(".scroll-to-see-more")
    toggleScrollToSeeVisibility(scrollLeft, toggleElement)
  }

  return (
    <SelfMeterReadOverviewStyle>
      <UpdateDataButton 
        buttonText="Aggiorna i dati"
        updateFunction={() => dispatch(fetchSelfMeterReadsStart())}
      />
      {_.map(items, (item, customerCode) => (
        <div className="supply-points-group" key={customerCode}>
          <h3 className="customer-code">
            {customerCode}
          </h3>
          <div className="self-meter-list" onScroll={handleScroll}>
            {_.map(item, selfRead => (
              <SelfMeterReadItemDataContainer
                customerCode={customerCode}
                number={selfRead.number}
                key={selfRead.number}
              />
            ))}
            <ScrollToSeeMore />
          </div>
        </div>
      ))}
      {!_.size(items) &&
        <p className="mt-3 mx-3 no-data">Non hai forniture GAS attive al momento.</p>
      }
    </SelfMeterReadOverviewStyle>
  )
}

export default SelfMeterReadOverview
