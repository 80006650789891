import styled from 'styled-components'

export const UserAvatarStyle = styled.div`
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 50% 50%;
  transition:  transform .4s ease;
  border: ${({ theme }) => theme.navigationBarUserAvatarBorder};
  background-color: ${({ theme }) => theme.navigationBarUserAvatarBkgColor};

  &:hover {
    transform: scale(1.1) rotate(7deg);
  }

  .user-avatar {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: .8rem;
    display: flex;
    align-items: center;

    .nav-link {
      padding: 0;
    }
  }

  .default-user {
    font-size: 1.25rem;
    position: relative;
    z-index: 1;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.navigationBarUserAvatarDefaultUserColor};
  }
`
