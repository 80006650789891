import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'
import { appleSignInStart } from '../../../redux/user/user.actions'

const AppleSignInButton = () => {
  const dispatch = useDispatch()

  return(
    <Button
      color="primary"
      onClick={() => dispatch(appleSignInStart())}
      className="apple-btn"
    >
      <FontAwesomeIcon size="lg" icon={['fab', 'apple']} />
      Continua con Apple
    </Button>
  )
}

export default AppleSignInButton
