import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import AlertStyle from "./style"

const Alert = ({ color, otherClasses, title, description, icon }) => (
  <AlertStyle className={`${color}${otherClasses?.length ? " " + otherClasses.join(" ") : ""}`}>
    {icon &&
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
    }
    <div>
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>
  </AlertStyle>
)

export default Alert