import styled from 'styled-components'

const ResetPasswordStyle = styled.div`
  .reset-feedback {
    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .description {
      font-weight: 400;
      font-size: .85rem;
    }
  }

  .back-to-login {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
    font-size: .85rem;
  }
`

export default ResetPasswordStyle