import React from 'react'
import { useSelector } from 'react-redux'
import { UncontrolledAlert } from 'reactstrap'
import { selectUserMaintenanceState } from '../../redux/user/user.selectors'

const MaintenanceAlert = () => {
  const maintenanceState = useSelector(selectUserMaintenanceState)

  return (
    <UncontrolledAlert id="maintenance-alert" color="warning">
      <div className="content">
        { maintenanceState.maintenance_alert_text }
      </div>
    </UncontrolledAlert>
  )
}

export default MaintenanceAlert