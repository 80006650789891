import styled from 'styled-components'

const GasCharViewStyle = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;

  .recharts-legend-item-text {
    color: #5F5F5F !important;
    font-size: .75rem;
  }

  .recharts-responsive-container,
  .recharts-wrapper {
    height: auto !important;
  }

  .recharts-legend-wrapper {
    position: static !important;
  }
`

export default GasCharViewStyle