import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UpdateDataButtonStyle } from './style'

const UpdateDataButton = ({ updateFunction, buttonText }) => (
  <UpdateDataButtonStyle>
    <Button size="sm" onClick={updateFunction}>
      <FontAwesomeIcon icon={['fas', 'sync-alt']} />
      <span className="text-label">{buttonText}</span>
    </Button>
  </UpdateDataButtonStyle>
)

export default UpdateDataButton
