import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SupplyPointsOverviewContainer from '../../components/customer-area/SupplyPointsOverview/SupplyPointsOverviewContainer.component'
import { selectCustomerCodesArray } from '../../redux/user/user.selectors'
import { fetchSupplyPointsStart } from '../../redux/supplyPoints/supplyPoints.actions'

const SupplyPointsOverviewPage = () => {
  const dispatch = useDispatch()
  const customerCodes = useSelector(selectCustomerCodesArray)

  useEffect(() => {
    if(customerCodes.length) dispatch(fetchSupplyPointsStart({ customerCodes }))
  }, [customerCodes, dispatch])

  return(
    <div className="supply-points-overview-page">
      <SupplyPointsOverviewContainer />
    </div>
  )
}

export default SupplyPointsOverviewPage