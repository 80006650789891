import { connect } from 'react-redux'
import { compose } from 'redux'

import CustomersData from './CustomersData.component'
import WithSkeleton from '../../WithSkeleton/WithSkeleton.component'
import { selectCustomerCodeItemIsLoading, selectCustomerCodeItem } from '../../../redux/user/user.selectors'

const mapStateToProps = (state, ownProps) => ({
  isLoading: selectCustomerCodeItemIsLoading(ownProps.customerCode)(state),
  customerCodeItem: selectCustomerCodeItem(ownProps.customerCode)(state)
})

const CustomersDataContainer = compose(
  connect(mapStateToProps),
  WithSkeleton
)(CustomersData)

export default CustomersDataContainer
