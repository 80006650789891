import { all, call } from 'redux-saga/effects'

import { userSagas } from './user/user.sagas'
import { supplyPointsSagas } from './supplyPoints/supplyPoints.sagas'
import { billsSagas } from './bills/bills.sagas'
import { selfMeterReadSagas } from './selfMeterRead/selfMeterRead.sagas'
import { cadastralDataSagas } from './cadastralData/cadastralData.sagas'

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(supplyPointsSagas),
    call(billsSagas),
    call(selfMeterReadSagas),
    call(cadastralDataSagas),
  ])
}
