import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'

import { required, passwordFormat } from './utils'
import { selectIsSubmitting, selectSignUpEmail } from '../../../redux/user/user.selectors'
import { signUpWithEmailStart } from '../../../redux/user/user.actions'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const UserDataForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const signUpEmail = useSelector(selectSignUpEmail)
  const onSubmit = (values) => dispatch(signUpWithEmailStart(values))

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="name"
            name="name"
            type="text"
            validate={required}
            placeholder="Nome"
            focusOnMount={true}
          />
          <CustomFormField
            id="surname"
            name="surname"
            type="text"
            validate={required}
            placeholder="Cognome"
          />
          <CustomFormField
            id="password"
            name="password"
            type="password"
            validate={passwordFormat}
            placeholder="Password"
          />
          <CustomFormField
            id="userEmail"
            name="email"
            type="hidden"
            defaultValue={signUpEmail}
          />
          <CustomFormField
            id="privacy"
            name="privacy"
            type="hidden"
            defaultValue="1"
          />
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            buttonLabel="Continua"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Form>
  )
}

export default UserDataForm
