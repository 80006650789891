import styled from "styled-components"

const CadastralDataItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.itemBackgroundColor};
  box-shadow: ${({ theme }) => theme.selfMeterReadBoxShadow};
  overflow: hidden;
  min-height: 200px;
  border-radius: 10px;
  position: relative;
  margin: .75rem 0;
  margin-right: .75rem;
  min-width: calc(33.33% - .25rem);
  max-width: 33vw;

  @media (max-width: 991.9px) {
    min-width: calc(50% - .25rem);
    max-width: 50vw;
  }

  @media (max-width: 575.9px) {
    margin-right: 0;
    min-width: calc(100% - .25rem);
    max-width: 0;
  }

  header {
    display: flex;
    padding: 1rem;
    align-items: center;
    min-width: 100%;
    background: ${({ theme }) => theme.selfMeterReadItemNameBkg};
    color: ${({ theme }) => theme.selfMeterReadItemNameColor};

    .alias {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    .custom-icon {
      fill: ${({ theme }) => theme.selfMeterReadItemNameColor};
      font-size: 1.25rem;
      margin-right: .75rem;
    }
  }

  main {
    padding: 1rem;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;

    .data-list {
      margin-bottom: 1rem;
      
      .data-group {
        flex-basis: unset;
        flex-grow: unset;
        margin-bottom: 1rem;
      }
    }

    .supply-points-title {
      color: ${({ theme }) => theme.selfMeterReadItemTextColor};
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: .75rem;
    }

    .pdp-container {
      border-radius: 10px;
      overflow: hidden;
    }
    
    .supply-point-data {
      color: ${({ theme }) => theme.selfMeterReadItemTextColor};
      margin-bottom: 1rem;
      display: flex;

      &.bordered {
        padding: .75rem;
        border-radius: 10px;
        box-shadow: ${({ theme }) => theme.selfMeterReadDataBoxShadow};
        background-color: ${({ theme }) => theme.selfMeterReadDataBkg};
      }

      .alias {
        align-self: center;
      }

      .address {
        font-weight: normal;
      }

      .pdp-icon {
        font-size: 1.5rem;
        margin-right: .25rem;
        flex-grow: 0;
        flex-basis: 0;
        align-self: center;
        margin-right: 1rem;
        fill: ${({ theme }) => theme.supplyPointPreviewElectricTextColor};
      }

      .action-btns {
        flex-basis: 0;
        flex-grow: 0;

        button svg {
          margin-right: 0 !important;
        }
      }
    }

    .data-title {
      flex-basis: 100%;
      font-size: .85rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  footer {
    align-self: center;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .custom-icon {
      fill: ${({ theme }) => theme.selfMeterReadItemInsertReadNameColor};
    }

    .delete-cadastral-data {
      font-size: .85rem;
      border: ${({ theme }) => theme.deleteButtonBorder};
      color: ${({ theme }) => theme.deleteButtonColor};
      background-color: ${({ theme }) => theme.deleteButtonBkg};

      &:focus > svg path {
        fill: ${({ theme }) => theme.deleteButtonColor};
      }

      &:hover {
        background-color: ${({ theme }) => theme.deleteButtonHoverBkg};
        border: ${({ theme }) => theme.deleteButtonHoverBorder};

        > svg path {
          fill: ${({ theme }) => theme.deleteButtonColor};
        }
      }
    }
  }
`

export default CadastralDataItemStyle