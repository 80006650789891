import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import SupplyPointsActionTypes from './supplyPoints.types'
import * as supplyPointActions from './supplyPoints.actions'
import {
  getPodSupplyPointsByClientCode, getPdrSupplyPointsByClientCode, convertSupplyPointsToMap,
  getSupplyPointDetail, updateAlias, getSupplyPointCunsumptions, getSupplyPointCadastralData
} from './supplyPoints.utils'
import { selectIdToken } from '../user/user.selectors'
import _ from 'lodash'

export function* fetchSupplyPointByCustomerCode(customerCode, idToken) {
  try {
    const podAndPdrPoints = yield all([
      call(getPodSupplyPointsByClientCode, customerCode, idToken),
      call(getPdrSupplyPointsByClientCode, customerCode, idToken)
    ])
    return _.flatten(podAndPdrPoints)
  }
  catch(error) {
    yield put(supplyPointActions.fetchSupplyPointsFailure(error))
  }
}

export function* fetchSupplyPoints({ payload: { customerCodes } }) {
  try {
    const idToken = yield select(selectIdToken)
    const supplyPoints = yield all(customerCodes.map(code => call(fetchSupplyPointByCustomerCode, code, idToken)))
    const mappedSupplyPoints = yield convertSupplyPointsToMap(supplyPoints)
    yield put(supplyPointActions.fetchSupplyPointsSuccess(mappedSupplyPoints))
  }
  catch(error) {
    yield put(supplyPointActions.fetchSupplyPointsFailure(error))
  }
}

export function* onFetchSupplyPointsStart() {
  yield takeLatest(
    SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_START,
    fetchSupplyPoints
  )
}

export function* fetchSupplyPointDetail({ payload: { supplyPointId, type } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(getSupplyPointDetail, supplyPointId, type, idToken)
    let { data } = response
    data.serviceType = type === 'electrical' ? 1 : 2
    //Get consumptions
    const consumptionsResponse = yield call(getSupplyPointCunsumptions, supplyPointId, type, idToken)
    data.consumptions = consumptionsResponse.data
    //Get cadastral data
    const cadastralDataResponse = yield call(getSupplyPointCadastralData, supplyPointId, type, idToken)
    data.cadastralData = cadastralDataResponse.data
    yield put(supplyPointActions.fetchSupplyPointDetailSuccess(data))
  }
  catch(error) {
    yield put(supplyPointActions.fetchSupplyPointDetailFailure(error))
  }
}

export function* onFetchSupplyPointDetailStart() {
  yield takeLatest(
    SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_START,
    fetchSupplyPointDetail
  )
}

export function* updateSupplyPointAlias({ payload: { number, supplyPointType, alias } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(updateAlias, alias, supplyPointType, number, idToken)
    yield put(supplyPointActions.updateSupplyPointAliasSuccess(response.data.alias))
  }
  catch(error) {
    yield put(supplyPointActions.updateSupplyPointAliasFailure(error))
  }
}

export function* onUpdateSupplyPointAliasStart() {
  yield takeLatest(
    SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_START,
    updateSupplyPointAlias
  )
}

export function* supplyPointsSagas() {
  yield all([
    call(onFetchSupplyPointsStart),
    call(onFetchSupplyPointDetailStart),
    call(onUpdateSupplyPointAliasStart)
  ])
}
