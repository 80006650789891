import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { SupplyPointsOverviewStyle } from './style'
import { selectCustomerCodesArray } from '../../../redux/user/user.selectors'
import { selectSupplyPointsItems } from '../../../redux/supplyPoints/supplyPoints.selectors'
import { fetchSupplyPointsStart } from '../../../redux/supplyPoints/supplyPoints.actions'
import SupplyPointItemPreview from '../SupplyPointItemPreview/SupplyPointItemPreview.component'
import ScrollToSeeMore from '../ScrollToSeeMore/ScrollToSeeMore.component'
import { toggleScrollToSeeVisibility } from './utils'
import UpdateDataButton from '../../UpdateDataButton/UpdateDataButton.component'

const SupplyPointsOverview = () => {
  const dispatch = useDispatch()
  const supplyPoints = useSelector(selectSupplyPointsItems)
  const customerCodes = useSelector(selectCustomerCodesArray)

  const handleScroll = e => {
    const scrollLeft = e.currentTarget.scrollLeft
    const toggleElement = e.currentTarget.querySelector(".scroll-to-see-more")
    toggleScrollToSeeVisibility(scrollLeft, toggleElement)
  }

  return (
    <SupplyPointsOverviewStyle>
      <UpdateDataButton 
        buttonText="Aggiorna i dati"
        updateFunction={() => dispatch(fetchSupplyPointsStart({ customerCodes }))}
      />
      {_.map(supplyPoints, (point, customerCode) => (
        <div className="supply-points-group" key={customerCode}>
          <h3 className="customer-code">
            {customerCode}
          </h3>
          <div className="supply-points-list" onScroll={point.length > 1 ? handleScroll : null}>
            {point.map((item, i) => (
              <SupplyPointItemPreview item={item} key={i} />
            ))}
            <ScrollToSeeMore />
          </div>
        </div>
      ))}
    </SupplyPointsOverviewStyle>
  )
}

export default SupplyPointsOverview
