import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonWithLoader from '../../../ButtonWithLoader/ButtonWithLoader.component'
import { selectIsSubmittingCadastralItemForm } from '../../../../redux/cadastralData/cadastralData.selectors'
import { deleteCadastralDataItemStart } from '../../../../redux/cadastralData/cadastralData.actions'
import { getAPIUrl } from '../../../../ABapi'

const DeleteCadastralDataModal = ({ cadastralDataId }) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(state => selectIsSubmittingCadastralItemForm(`cadastralDataItemDelete-${cadastralDataId}`)(state))
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const handleDeleteConfirm = () => {
    dispatch(deleteCadastralDataItemStart({ 
      apiUrl: `${getAPIUrl()}/cadastral-data/${cadastralDataId}`,
      cadastralDataId
    }))
    toggle()
  }

  return (
    <>
      <ButtonWithLoader
        otherClasses="ab-button delete-cadastral-data mr-2"
        buttonLabel="Elimina dato catastale"
        onClick={toggle}
        isFetching={isSubmitting}
        disabled={isSubmitting}
        defaultIcon={['fas', 'trash-alt']}
      />
      <Modal isOpen={isOpen} toggle={toggle} className="delete-customer-code-modal">
        <ModalHeader toggle={toggle}>
        </ModalHeader>
        <ModalBody>
          <p>Confermi di voler eliminare questo dato catastale?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            className="ab-button cancel"
            onClick={toggle}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
            Annulla
          </Button>
          <Button
            className="ab-button"
            onClick={handleDeleteConfirm}
          >
            <FontAwesomeIcon icon={['fas', 'check']} />
            <span>Elimina</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteCadastralDataModal
