import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import AppRouter from './AppRouter.component'
import WithSpinner from '../../components/WithSpinner/WithSpinner.component'
import { selectIsCheckingUser } from '../../redux/user/user.selectors'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsCheckingUser,
  otherClasses: () => ['full'],
  showLogo: () => true
})

const AppRouterContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(AppRouter)

export default AppRouterContainer
