import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Lottie from 'react-lottie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as otpModeHelper from '../../../assets/lottie/otp-mode-help.json'
import LoginStep from '../../LoginStep/LoginStep.component'
import UserOtpTypeForm from '../UserOtpTypeForm/UserOtpTypeForm.component'

const UserOtpTypeStep = () => {
  const [ modal, setModal ] = useState(false)
  const toggle = () => setModal(!modal)
  const otpModeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: otpModeHelper.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <LoginStep>
      <h1 className="step-title">
        Completa la registrazione con:
        <span onClick={toggle} className="info-trigger">
          <FontAwesomeIcon icon={['fas', 'question-circle']} />
        </span>
      </h1>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span>Contatti per la registrazione</span>
        </ModalHeader>
        <ModalBody>
          <div className="animation-help">
            <Lottie options={otpModeAnimationOptions} />
          </div>
          <p className="mt-3">Per completare la registrazione ti invieremo un codice di conferma al contatto che hai lasciato in fase di
          sottoscrizione del contratto di fornitura.</p>
        </ModalBody>
        <ModalFooter>
          <Button className="ab-button" onClick={toggle}>
            <span>Ho capito</span>
          </Button>
        </ModalFooter>
      </Modal>
      <UserOtpTypeForm />
    </LoginStep>
  )
}

export default UserOtpTypeStep
