import { useDispatch } from "react-redux"
import { Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import PdpItemStyle from './style'
import { ReactComponent as Bolt } from '../../../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../../../assets/svg/flame.svg'
import { toggleDeletePdpToCadastralItemModal } from "../../../../../redux/cadastralData/cadastralData.actions"

const PdpItem = ({ cadastralDataItemId, alias, supplyType, number }) => {
  const dispatch = useDispatch()

  return (
    <PdpItemStyle>
      <div className="data-group pdp-icon">
        {supplyType.label === "Energia" ? (
          <Bolt title ="Punti di fornitura Energia" />
        ) : (
          <Flame title="Punto di fornitura Gas" />
        )}
      </div>
      <div className="data-group alias">
        <span className="code">
          {alias}
        </span>
      </div>
      <div className="data-group action-btns">
        <Button
          className="ab-button inverted"
          title="Disassocia questo punto di fornitura dal dato catastale"
          onClick={() => dispatch(toggleDeletePdpToCadastralItemModal({
            cadastralDataItemId,
            pdpNo: number
          }))}
        >
          <FontAwesomeIcon icon={['fas', 'circle-minus']} className="mx-0" />
        </Button>
      </div>
    </PdpItemStyle>
  )
}

export default PdpItem