import styled from 'styled-components'

export const SortableColumnHeaderStyle = styled.th`
  cursor: pointer;

  &.active-col {
    background-color: ${({ theme }) => theme.billsActiveColBkg};
  }

  > span {
    margin-right: .25rem;
  }
`
