import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import CadastralDataOverviewStyle from "./style"
import ScrollToSeeMore from "../ScrollToSeeMore/ScrollToSeeMore.component"
import Alert from "../Alert/Alert.component"
import UpdateDataButton from "../../UpdateDataButton/UpdateDataButton.component"
import CadastralDataItem from "./CadastralDataItem/CadastralDataItem.component"
import CadastralDataItemForm from './CadastralDataItemForm/CadastralDataItemForm.component'
import CadastralDataPdpForm from "./CadastralDataPdpForm/CadastralDataPdpForm.component"
import { 
  selectCadastralDataItems, selectUnmatchedPods, selectUnmatchedPdrs, selectIsFetchingCadastralData 
} from "../../../redux/cadastralData/cadastralData.selectors"
import { fetchCadastralDataStart, addCadastralDataStart } from "../../../redux/cadastralData/cadastralData.actions"
import ButtonWithLoader from "../../ButtonWithLoader/ButtonWithLoader.component"
import DeletePdpAssociationModal from "./DeletePdpAssociationModal/DeletePdpAssociationModal.component"
import PdpShortcutModal from "./CadastralDataItemForm/PdpShortcutModal/PdpShortcutModal.component"
import { toggleScrollToSeeVisibility } from './utils'
import { getAPIUrl } from "../../../ABapi"

const CadastralDataOverview = () => {
  const dispatch = useDispatch()
  const unmatchedPod = useSelector(selectUnmatchedPods)
  const unmatchedPdr = useSelector(selectUnmatchedPdrs)
  const cadastralData = useSelector(selectCadastralDataItems)
  const isFetchingPdpItems = useSelector(state => selectIsFetchingCadastralData("addCadastralData")(state))

  const handleScroll = useCallback(e => {
    const scrollLeft = e.currentTarget.scrollLeft
    const toggleElement = e.currentTarget.querySelector(".scroll-to-see-more")
    toggleScrollToSeeVisibility(scrollLeft, toggleElement)
  }, [])

  return (
    <CadastralDataOverviewStyle>
      <div className="buttons-container">
        <UpdateDataButton 
          buttonText="Aggiorna i dati"
          updateFunction={() => dispatch(fetchCadastralDataStart())}
        />
        <ButtonWithLoader
          className="ab-button ml-2"
          defaultIcon={['fas', 'plus-circle']}
          title=""
          buttonLabel="Aggiungi dato catastale"
          isFetching={isFetchingPdpItems}
          onClick={() => {
            dispatch(addCadastralDataStart({
              apiUrl: `${getAPIUrl()}/cadastral-data/association`,
            }))
          }}
        />
      </div>
      {!cadastralData.length ? (
        // No cadastral data
        <Alert 
          otherClasses={["my-3"]}
          title="Nessun dato catastale presente"
          description="Non hai ancora inserito nessun dato catastale! Aggiungine subito uno cliccando sul pulsante qui sopra."
          icon={['fas', 'triangle-exclamation']}
          color="warning"
        />
      ) : (
        //At least one cadastral data and one not associated pdp
        (unmatchedPod || unmatchedPdr) ? (
          <Alert 
            otherClasses={["my-3"]}
            title={`Hai ${unmatchedPod + unmatchedPdr} ${unmatchedPod + unmatchedPdr === 1 ? 'utenza non ancora associata' : 'utenze non ancora associate'}`}
            description={`${unmatchedPod + unmatchedPdr === 1 ? 'Aggiungila' : 'Aggiungile'} ad un dato catastale esistente o creane uno nuovo per procedere con l'associazione.`}
            icon={['fas', 'triangle-exclamation']}
            color="warning"
          />
        ) : (
          //All pdp are associated
          <Alert 
            otherClasses={["my-3"]}
            title="Complimenti!"
            description="Hai comunicato tutti i dati catastali."
            icon={['fas', 'circle-check']}
            color="success"
          />
        )
      )}
      <div className="cadastral-data-list" onScroll={handleScroll}>
        {cadastralData.map(item => 
          <CadastralDataItem 
            key={item.id}
            data={item}
          />
        )}
        <ScrollToSeeMore />
      </div>
      <CadastralDataItemForm />
      <CadastralDataPdpForm />
      <DeletePdpAssociationModal />
      <PdpShortcutModal />
    </CadastralDataOverviewStyle>
  )
}

export default CadastralDataOverview