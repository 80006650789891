import React, { useState, useEffect, useCallback } from 'react'
import { Field } from 'react-final-form'
import { FormGroup } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DropzoneInputStyle } from './style'
import { maxSize, toBase64 } from './utils'

const DropzoneInput = ({ updateFieldValue, ...props }) => {
  const [ file, setFile ] = useState(null)
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      setFile(Object.assign(file, { preview: URL.createObjectURL(file) }))
      updateFieldValue('fileName', file.name)
      return toBase64(file).then(encoded => {
        updateFieldValue('photo', encoded)
      })
    })

  }, [updateFieldValue])

  const { getRootProps, getInputProps, isDragReject, fileRejections } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: maxSize,
    multiple: false,
    onDrop: onDrop
  })
  //Check file size
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false)
  useEffect(() => {
    if(fileRejections.length > 0 && fileRejections[0].size > maxSize) {
      setIsFileTooLarge(true)
    }
    else {
      setIsFileTooLarge(false)
    }
  }, [fileRejections])

  return (
    <DropzoneInputStyle>
      <FormGroup>
        <Field {...props}>
          {({ label, input, ...otherProps }) => (
            <>
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} name={input.name} />
                <p>
                  <FontAwesomeIcon icon={['fas', 'portrait']} />
                  Clicca o trascina qui l'immagine da caricare
                </p>
                {isDragReject &&
                  <p>File type not accepted, sorry!</p>
                }
                {isFileTooLarge &&
                  <p className="text-danger">
                    Questo file è troppo grande, non deve superare i 5Mb.
                  </p>
                }
              </div>
              {file &&
                <aside className="thumb-container">
                  <div className="thumb">
                    <div className="thumb-inner">
                      <img src={file.preview} alt="Avatar profilo" />
                    </div>
                  </div>
                </aside>
              }
            </>
          )}
        </Field>
      </FormGroup>
    </DropzoneInputStyle>
  )
}

export default DropzoneInput
