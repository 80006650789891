import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label, FormText } from 'reactstrap'
import classnames from 'classnames'

import StyledExtendedSelectField from './style'

const ExtendedSelectField = ({ children, ...props }) => {
  return (
    <FormGroup>
      <Field {...props}>
        {({ isMulti = false, isClearable = false, customFilterFunction, fieldLabel, fieldDescription, noValidationMessage, input: { name, value, onChange, onBlur }, meta: { error, touched }, options, className, ...input }) => (
          <>
            {fieldLabel &&
              <Label for={name}>{fieldLabel}</Label>
            }
            <StyledExtendedSelectField
              classNamePrefix="react-select"
              className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
              value={value}
              isMulti={isMulti}
              options={options}
              onChange={value => onChange(value)}
              onBlur={onBlur}
              isClearable={isClearable}
              filterOption={customFilterFunction || undefined}  
              {...input}
            />
            {fieldDescription &&
              <FormText color="muted">
                {fieldDescription}
              </FormText>
            }
            {!noValidationMessage &&
              <FormFeedback className={classnames({ 'd-flex': error && touched })}>
                {error}
              </FormFeedback>
            }
          </>
        )}
      </Field>
      {children}
    </FormGroup>
  )
}

export default ExtendedSelectField
