import styled from "styled-components"

const CadastralDataOverviewStyle = styled.section`
  .buttons-container {
    display: flex;
    align-items: center;
  }

  .cadastral-data-list {
    display: flex;
    align-items: flex-start;
    width: 100%;
    position: relative;
    overflow-x: auto;
    padding: 0 .75rem;
    padding-bottom: .5rem;
    margin-left: -.75rem;
    margin-bottom: 2.5rem;

    .scroll-to-see-more {
      display: none;
    }

    @media (min-width: 576px) and (max-width: 991.9px) {
      > div:nth-child(n+4) {
        &.scroll-to-see-more {
          display: flex;
        }
      }
    }

    @media (min-width: 992px) {
      > div:nth-child(n+5) {
        &.scroll-to-see-more {
          display: flex;
        }
      }
    }

    @media (max-width: 575.9px) {
      flex-wrap: wrap;
      margin-left: 0;
      padding: 0 .25rem 1.5rem;

      .scroll-to-see-more {
        display: none !important;
      }
    }
  }
`

export default CadastralDataOverviewStyle