import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { selectIsFetchingSupplyPointDetail } from '../../../redux/supplyPoints/supplyPoints.selectors'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import SupplyPointItem from './SupplyPointItem.component'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingSupplyPointDetail
})

const SupplyPointItemContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(SupplyPointItem)

export default SupplyPointItemContainer
