import styled from 'styled-components'

export const DropzoneInputStyle = styled.div`
  .dropzone {
    border: 1px dashed;
    border-color: ${({ theme }) => theme.dropzoneBorderColor};
    padding: 1rem;
    font-size: .85rem;
    cursor: pointer;

    > p {
      margin-bottom: 0;
      color: ${({ theme }) => theme.inputColor};
    }

    svg {
      font-size: 1.5rem;
      margin-right: .5rem;
      vertical-align: text-bottom;
    }
  }

  aside.thumb-container {
    display: flex;
    margin: 1rem 0;

    .thumb-inner {
      overflow: hidden;
      display: flex;
      min-width: 0;
    }

    .thumb {
      display: inline-flex;
      border-radius: 5px;
      border: 1px solid;
      border-color: ${({ theme }) => theme.dropzoneBorderColor};
      width: 100px;
      height: 100px;
      padding: .15rem;

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }
`
