import styled from 'styled-components'
import { Modal } from 'reactstrap'

const DownloadAppModalStyle = styled(Modal)`
  .modal-title {
    font-weight: bold;
  }

  .modal-body {
    color: $black;

    .store-link {
      margin-top: 1.5rem;
      text-align: center;

      .ab-button {
        display: inline-flex;
      }
    }
  }
`

export default DownloadAppModalStyle