import React, { useState, useEffect } from 'react'
import { ModalBody, Button } from 'reactstrap'
import { Buffer } from 'buffer'

const AttachmentModalBody = ({ attachment }) => {
  const [ attachmentLink, setAttachmentLink ] = useState(null)
  useEffect(() => {
    const openAttachmentLink = () => {
      const byteCharacters = Buffer.from(attachment, 'base64').toString()
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], {type: 'application/pdf'})
      const blobUrl = URL.createObjectURL(blob)
      setAttachmentLink(blobUrl)
    }

    if(attachment) openAttachmentLink()
  }, [attachment])

  return (
    <>
      <ModalBody id="attachment-embed">
        <p>Il file è pronto per essere visualizzato!</p>
        <p className="light">Clicca sul pulsante per visualizzare ed eventualmente salvare il documento.</p>
        <Button
          className="ab-button"
          onClick={() => window.open(attachmentLink)}
        >
          Scarica
        </Button>{' '}
      </ModalBody>
    </>
  )
}

export default AttachmentModalBody
