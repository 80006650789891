import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { selectUserMaintenanceState } from "../../redux/user/user.selectors"
import { useSelector } from "react-redux"

const AppDismissBillsModal = () => {
    const [isVisible, setIsVisible] = useState(true)
    const toggle = () => setIsVisible(!isVisible)

    // Get the maintenance state from the redux store
    const maintenanceState = useSelector(selectUserMaintenanceState)
    const { app_url_android, app_url_ios, bills_title, bills_message,
        show_messages } = maintenanceState?.app_dismiss_alert

    return (
        <>
            {
                show_messages && (
                    <Modal isOpen={isVisible} toggle={toggle} >
                        <ModalHeader toggle={toggle}>
                            {bills_title}
                        </ModalHeader>
                        <ModalBody>
                            {bills_message}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={toggle}>
                                Non ora
                            </Button>{' '}
                            <a className="btn btn-primary" href={app_url_android ?? app_url_ios} target="_blank" rel="noreferrer">
                                Registrati
                            </a>
                        </ModalFooter>
                    </Modal>
                )
            }
        </>
    )
}

export default AppDismissBillsModal