import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomInput } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserProfileStyle } from './style'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { toggleUserTheme } from '../../../redux/user/user.actions'
import UserProfileModalForm from '../UserProfileModalForm/UserProfileModalForm.component'
import AddCustomerCodeModal from '../AddCustomerCodeModal/AddCustomerCodeModal.component'

const UserProfile = () => {
  const dispatch = useDispatch()
  const { email, name, surname, displayName, theme } = useSelector(selectCurrentUser)

  return (
    <UserProfileStyle>
      <h3 className="title px-0">Dati utente</h3>
      <div className="user-data-group">
        <div className="data-group">
          <label>Nome e cognome</label>
          <span className="code">{name && surname ? `${name} ${surname}` : <FontAwesomeIcon icon={['fas', 'question']} /> }</span>
        </div>
        <div className="data-group">
          <label>Email</label>
          <span className="code">{email ? email : <FontAwesomeIcon icon={['fas', 'question']} />}</span>
        </div>
        <div className="data-group">
          <label>Username</label>
          <span className="code">{displayName ? displayName : <FontAwesomeIcon icon={['fas', 'question']} />}</span>
        </div>
      </div>
      <div className="user-data-group">
        {/*<div className="data-group">
          <label>Telefono:</label>
          <span className="code">
            {mobileNumber ? mobileNumber : <FontAwesomeIcon icon={['fas', 'question']} />}
          </span>
        </div>*/}
        <div className="data-group">
          <label>Link utili</label>
          <span className="code">
            <a
              className="social-link"
              href="https://www.facebook.com/ABenergie/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </a>
            <a
              className="social-link"
              href="https://www.instagram.com/abenergieofficial/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </a>
            <a
              className="social-link"
              href="https://it.linkedin.com/company/abenergie"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={['fab', 'linkedin']} />
            </a>
            <a
              className="social-link"
              href="https://www.youtube.com/user/ABenergie1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon={['fab', 'youtube']} />
            </a>
          </span>
        </div>
        <div className="data-group">
          <label>Tema</label>
          <div className="dark-mode">
            <FontAwesomeIcon icon={['fas', 'sun']} />
            <CustomInput
              type="switch"
              id="darkMode"
              name="darkMode"
              defaultChecked={theme && theme === 'light' ? false : true}
              onChange={() => dispatch(toggleUserTheme())}
            />
            <FontAwesomeIcon icon={['fas', 'moon']} />
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <UserProfileModalForm />
        <AddCustomerCodeModal />
      </div>
    </UserProfileStyle>
  )
}

export default UserProfile
