import SupplyPointsActionTypes from './supplyPoints.types'

const INITIAL_STATE = {
  isLoading: false,
  isSubmitting: false,
  items: null,
  itemDetail: null,
  error: null,
  utilityModal: {
    isVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const supplyPointsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_START:
      return {
        ...state,
        isLoading: true,
        itemDetail: null
      }

    case SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      }

    case SupplyPointsActionTypes.FETCH_SUPPLY_POINTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_START:
      return {
        ...state,
        isLoading: true
      }

    case SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemDetail: action.payload
      }

    case SupplyPointsActionTypes.FETCH_SUPPLY_POINT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_START:
      return {
        ...state,
        isSubmitting: true
      }

    case SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || '',
          modalIcon: ['fas', 'comment-exclamation'],
          isVisible: true
        }
      }

    case SupplyPointsActionTypes.UPDATE_SUPPLY_POINT_ALIAS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        itemDetail: {
          ...state.itemDetail,
          alias: action.payload
        }
      }

    case SupplyPointsActionTypes.TOGGLE_SUPPLY_POINTS_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    default:
      return state
  }
}

export default supplyPointsReducer
