import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { FormText, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import { confirmResetPasswordStart } from '../../../redux/user/user.actions'
import { passwordFormat } from './utils'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const ResetPasswordForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const [ inputType, setInputType ] = useState('password')
  const togglePasswordVisibility = () => {
    if(inputType === 'password') {
      setInputType('text')
    }
    else {
      setInputType('password')
    }
  }

  const onSubmit = (values) => dispatch(confirmResetPasswordStart(values))

  return(
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="newPassword"
            name="newPassword"
            type={inputType}
            validate={passwordFormat}
            placeholder="Nuova password"
            focusOnMount={true}
          >
            <FormText color="muted">
              <Button color="link" className="show-pwd" onClick={togglePasswordVisibility}>
                {inputType === 'password' ? (
                  <>
                    <FontAwesomeIcon icon={['fas', 'eye']} />
                    <span>Mostra password</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={['fas', 'eye-slash']} />
                    <span>Nascondi password</span>
                  </>
                )}
              </Button>
            </FormText>
          </CustomFormField>
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            buttonLabel="Avanti"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Form>
  )
}

export default ResetPasswordForm
