import React from 'react'
import { Container } from 'reactstrap'

import MainContentWrapperStyles from './style'

const MainContentWrapper = ({ children }) => (
  <MainContentWrapperStyles>
    <Container fluid>
      {children}
    </Container>
  </MainContentWrapperStyles>
)

export default MainContentWrapper
