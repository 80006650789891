import { createSelector } from 'reselect'

const selfMeterRead = state => state.selfMeterRead

export const selectSelfReads = createSelector(
  [selfMeterRead],
  selfMeterRead => selfMeterRead.items
)

export const selectIsFetchingData = createSelector(
  [selfMeterRead],
  selfMeterRead => selfMeterRead.isLoading || selfMeterRead.items.length === 0
)

export const selectIsSubmittingSelfMeterRead = createSelector(
  [selfMeterRead],
  selfMeterRead => selfMeterRead.isSubmittingSelfMeterRead
)

export const selectSelfMeterReadItem = (customerCode, number) => createSelector(
  [selectSelfReads],
  items => (items && items[customerCode] ? items[customerCode][number] : null)
)

export const selectSelfMeterReadItemIsLoading = (customerCode, number) => createSelector(
  [selectSelfReads],
  items => (items && items[customerCode] ? items[customerCode][number].toFetch : null)
)

export const selectUtilityModal = createSelector(
  [selfMeterRead],
  selfMeterRead => selfMeterRead.utilityModal
)
