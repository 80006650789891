import React from 'react'
import Skeleton from 'react-loading-skeleton'

const WithTableLoader = WrappedComponent => {
  const TableLoader = ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <tr>
        <td className="loading-table-cell" colSpan="100%">
          <Skeleton className="skeleton-loader" count={15} height={50} />
        </td>
      </tr>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return TableLoader
}

export default WithTableLoader
