import { takeLatest, takeEvery, all, call, put, select, delay } from 'redux-saga/effects'

import UserActionTypes from './user.types'
import { 
  auth, getMaintenanceStatus, getMaintenanceStatusUpdates, 
  getCurrentUser, getFirebaseProvider 
} from '../../firebase/firebase-utils'
import * as userActions from './user.actions'
import {
  signUpUserWithABapi, signInUserWithABapi, setResetPasswordEmailSentModalContent,
  setConfirmResetPasswordEmailSentModalContentSuccess, setConfirmResetPasswordEmailSentModalContentError,
  credentialsUsedByAnotherProvider, setProviderErrorModalContent, getOtpMethods,
  initOtpTransaction, verifyOtp, updateUser, getCustomerData, deleteCustomerData, translateFirebaseErrorMessage
} from './user.utils'
import { selectIdToken, selectOobCode, selectOtpTransactionId } from './user.selectors'

export function* refreshUserToken() {
  try {
    const user = yield getCurrentUser()
    const idToken = yield user.getIdToken(true)
    yield put(userActions.updateUserToken({ idToken }))
  }
  catch(error) {
    yield console.log(error)
  }
}

export function* refreshUserTokenPeriodically() {
  while (true) {
    //Update user token every 30 minutes
    yield delay(1800000)
    yield call(refreshUserToken)
  }
}

export function* checkCredentials(email, providerType) {
  const signInMethods = yield auth.fetchSignInMethodsForEmail(email)
  const credentialUsedByOtherProvider = credentialsUsedByAnotherProvider(signInMethods, providerType)
  
  return credentialUsedByOtherProvider
}

export function* signInWithSocial(socialProvider) {
  try {
    const provider = getFirebaseProvider(socialProvider)
    const { additionalUserInfo: { isNewUser }, user, user: { displayName, email } } = yield auth.signInWithPopup(provider)
    const idToken = yield user.getIdToken()
    let response
    if(isNewUser === false) {
      response = yield call(signInUserWithABapi, idToken)
    }
    else {
      response = yield call(signUpUserWithABapi, { displayName, email, provider: socialProvider }, idToken)
    }
    yield put(userActions.signInSuccess({ idToken, ...response.data }))
  }
  catch(error) {
    const { email } = error
    if(email) {
      yield call(checkCredentials, email, socialProvider)
    }
    yield put(userActions.signInFailure({ error }))
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(
    UserActionTypes.GOOGLE_SIGN_IN_START,
    signInWithSocial,
    'google'
  )
}

export function* onFacebookSignInStart() {
  yield takeLatest(
    UserActionTypes.FACEBOOK_SIGN_IN_START,
    signInWithSocial,
    'facebook'
  )
}

export function* onAppleSignInStart() {
  yield takeLatest(
    UserActionTypes.APPLE_SIGN_IN_START,
    signInWithSocial,
    'apple'
  )
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    //Check if the user exists in AB Db
    const { user } = yield auth.signInWithEmailAndPassword(email, password)
    const idToken = yield user.getIdToken()
    const response = yield call(signInUserWithABapi, idToken)
    yield put(userActions.signInSuccess({ idToken, ...response.data }))
  }
  catch(error) {
    const message = translateFirebaseErrorMessage(error)
    yield put(userActions.signInFailure({ error: { message }}))
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(
    UserActionTypes.EMAIL_SIGN_IN_START,
    signInWithEmail
  )
}

export function* signUpWithEmail({ payload: { email, password, ...otherValues } }) {
  let user
  try {
    const authResponse = yield auth.createUserWithEmailAndPassword(email, password)
    user = authResponse.user
    const idToken = yield user.getIdToken()
    const response = yield call(signUpUserWithABapi, otherValues, idToken)
    yield put(userActions.signUpWithEmailSuccess({ idToken, ...response.data }))
  }
  catch(error) {
    if(user) {
      yield user.delete()
    }
    yield put(userActions.signUpWithEmailFailure(error))
  }
}

export function* onSignUpWithEmailStart() {
  yield takeLatest(
    UserActionTypes.SIGN_UP_WITH_EMAIL_START,
    signUpWithEmail
  )
}

export function* signOut() {
  try {
    yield auth.signOut()
    yield put(userActions.signOutSuccess())
  }
  catch(error) {
    yield put(userActions.signOutFailure(error))
  }
}

export function* onSignOutStart() {
  yield takeLatest(
    UserActionTypes.SIGN_OUT_START,
    signOut
  )
}

export function* getClientOtpMethods({ payload: { clientCode } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(getOtpMethods, clientCode, idToken)
    yield put(userActions.getClientOtpMethodsSuccess(response.data))
  }
  catch(error) {
    yield put(userActions.getClientOtpMethodsFailure(error))
  }
}

export function* onGetClientOtpMethodsStart() {
  yield takeLatest(
    UserActionTypes.GET_CLIENT_OTP_METHODS_START,
    getClientOtpMethods
  )
}

export function* sendOtpRequest({ payload: { type, clientCode } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(initOtpTransaction, type, clientCode, idToken)
    yield put(userActions.sendOtpRequestSuccess(response.data))
  }
  catch(error) {
    yield put(userActions.sendOtpRequestFailure(error))
  }
}

export function* onSendOtpRequestStart() {
  yield takeLatest(
    UserActionTypes.SEND_OTP_REQUEST_START,
    sendOtpRequest
  )
}

export function* verifyOtpSignature({ payload: { otpCode } }) {
  try {
    const idToken = yield select(selectIdToken)
    const transactionId = yield select(selectOtpTransactionId)
    yield call(verifyOtp, otpCode, transactionId, idToken)
    const response = yield call(signInUserWithABapi, idToken)
    const { data: { customerCodes } } = response
    yield put(userActions.verifyOtpSignatureSuccess({ customerCodes }))
  }
  catch(error) {
    if(error.code === 403) {
      yield put(userActions.verifyOtpSignatureReset(error))
    }
    else {
      yield put(userActions.verifyOtpSignatureFailure(error))
    }
  }
}

export function* onVerifyOtpSignatureStart() {
  yield takeLatest(
    UserActionTypes.VERIFY_OTP_SIGNATURE_START,
    verifyOtpSignature
  )
}

export function* isUserAuthenticated() {
  try {
    yield call(getMaintenanceStatusUpdates)
    const maintenance = yield call(getMaintenanceStatus)
    const user = yield getCurrentUser()
    if(!user) {
      yield put(userActions.checkUserSessionNoUser({ maintenanceState: maintenance }))
      return
    }
    const idToken = yield user.getIdToken()
    const response = yield call(signInUserWithABapi, idToken)
    yield put(userActions.signInSuccess({ idToken, ...response.data, maintenanceState: maintenance }))
  }
  catch(error) {
    yield put(userActions.checkUserSessionFailure(error))
  }
}

export function* onCheckUserSession() {
  yield takeLatest(
    UserActionTypes.CHECK_USER_SESSION_START,
    isUserAuthenticated
  )
}

export function* checkIfEmailExists({ payload: { email } }) {
  try {
    const credentialUsedByOtherProvider = yield call(checkCredentials, email, 'password')

    if(credentialUsedByOtherProvider === -1) {
      yield put(userActions.checkUserEmailProviderIsNewUser())
    }
    else if(credentialUsedByOtherProvider === false) {
      yield put(userActions.checkUserEmailProviderAlreadyExists())
    }
    else {
      yield put(userActions.checkUserEmailProviderUsedByOther(setProviderErrorModalContent(email)))
    }
  }
  catch(error) {
    yield put(userActions.checkUserEmailProviderFailure(error))
  }
}

export function* onCheckIfEmailExists() {
  yield takeLatest(
    UserActionTypes.CHECK_USER_EMAIL_PROVIDER_START,
    checkIfEmailExists
  )
}

export function* resetPassword({ payload: { email } }) {
  try {
    yield auth.sendPasswordResetEmail(email)
    const modalContent = yield call(setResetPasswordEmailSentModalContent, email)
    yield put(userActions.resetPasswordSuccess({ email: email, modalContent }))
  }
  catch(error) {
    yield put(userActions.resetPasswordFailure(error))
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(
    UserActionTypes.RESET_PASSWORD_START,
    resetPassword
  )
}

export function* confirmResetPassword({ payload: { newPassword } }) {
  try {
    const oobCode = yield select(selectOobCode)
    yield auth.confirmPasswordReset(oobCode, newPassword)
    yield put(userActions.confirmResetPasswordSuccess(setConfirmResetPasswordEmailSentModalContentSuccess()))
  }
  catch(error) {
    yield put(userActions.confirmResetPasswordFailure(setConfirmResetPasswordEmailSentModalContentError()))
  }
}

export function* onConfirmPasswordResetStart() {
  yield takeLatest(
    UserActionTypes.CONFIRM_RESET_PASSWORD_START,
    confirmResetPassword
  )
}

export function* updateUserProfile({ payload }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(updateUser, payload, idToken)
    yield put(userActions.updateUserProfileSuccess(response.data))
  }
  catch(error) {
    yield put(userActions.updateUserProfileFailure(error))
  }
}

export function* onUpdateUserProfileStart() {
  yield takeLatest(
    UserActionTypes.UPDATE_USER_PROFILE_START,
    updateUserProfile
  )
}

export function* fetchCustomerData({ payload: { customerCode } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(getCustomerData, customerCode, idToken)
    yield put(userActions.fetchCustomerDataSuccess(response.data))
  }
  catch(error) {
    yield put(userActions.fetchCustomerDataError({ code: customerCode }))
  }
}

export function* onFetchCustomerDataStart() {
  yield takeEvery(
    UserActionTypes.FETCH_CUSTOMER_DATA_START,
    fetchCustomerData
  )
}

export function* deleteCustomer({ payload: { customerCode } }) {
  try {
    const idToken = yield select(selectIdToken)
    yield call(deleteCustomerData, customerCode, idToken)
    const userResponse = yield call(signInUserWithABapi, idToken)
    const { data: { customerCodes } } = userResponse
    yield put(userActions.deleteCustomerDataSuccess({ customerCodes }))
  }
  catch(error) {
    yield put(userActions.deleteCustomerDataFailure({ customerCode }))
  }
}

export function* onDeleteCustomerDataStart() {
  yield takeLatest(
    UserActionTypes.DELETE_CUSTOMER_DATA_START,
    deleteCustomer
  )
}

export function* userSagas() {
  yield all([
    call(onCheckUserSession),
    call(onCheckIfEmailExists),
    call(onSignUpWithEmailStart),
    call(onEmailSignInStart),
    call(onGoogleSignInStart),
    call(onFacebookSignInStart),
    call(onAppleSignInStart),
    call(onSignOutStart),
    call(onGetClientOtpMethodsStart),
    call(onSendOtpRequestStart),
    call(onVerifyOtpSignatureStart),
    call(onResetPasswordStart),
    call(onConfirmPasswordResetStart),
    call(onUpdateUserProfileStart),
    call(onFetchCustomerDataStart),
    call(onDeleteCustomerDataStart),
    call(refreshUserTokenPeriodically)
  ])
}
