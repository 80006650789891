import styled from 'styled-components'

const UserProfilePageStyle = styled.div`
  margin: 1.5rem 0;

  .customers-data {
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    position: relative;
    overflow-x: auto;
    padding: 0 .25rem;
    padding-bottom: .5rem;
  
    .scroll-to-see-more {
      display: none;
    }
    
    > div:nth-child(n+3) {
      &.scroll-to-see-more {
        display: flex;
      }
    }
  }
`

export default UserProfilePageStyle