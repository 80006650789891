import styled from 'styled-components'

export const SupplyPointItemPreviewStyle = styled.div`
  border-color: ${({ theme }) => theme.mainBorderColor};
  box-shadow: ${({ theme }) => theme.supplyPointPreviewBoxShadow};
  background-color: ${({ theme }) => theme.itemBackgroundColor};
  overflow: hidden;
  margin: .75rem 0;
  margin-right: .5rem;
  min-width: calc(50% - .25rem);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transform-origin: 50% 50%;
  transition: transform .2s ease-in;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.01);
    z-index: 3;
  }

  &.electrical {
    color: ${({ theme }) => theme.supplyPointPreviewElectricTextColor};
    fill: ${({ theme }) => theme.supplyPointPreviewElectricTextColor};

    header {
      background: ${({ theme }) => theme.supplyPointPreviewElectricHeaderBkg};
    }
  }

  &.gas {
    color: ${({ theme }) => theme.supplyPointPreviewGasMainTextColor};
    fill: ${({ theme }) => theme.supplyPointPreviewGasMainTextColor};

    header {
      background: ${({ theme }) => theme.supplyPointPreviewGasHeaderBkg};
      color: ${({ theme }) => theme.supplyPointPreviewGasTextColor};
      fill: ${({ theme }) => theme.supplyPointPreviewGasTextColor};
    }
  }
  
  @media (max-width: 575.9px) {
    min-width: calc(100% - .25rem);
  }

  @media (min-width: 1200px) {
    min-width: calc(33.33% - .25rem);
  }

  header {
    display: flex;
    align-items: center;
    min-width: 100%;
    padding: .75rem 1rem;

    .icons {
      display: flex;
      align-items: center;

      .type-icon {
        width: 20px;
        line-height: 1;

        svg {
          width: 100%;
          height: auto;
        }
      }

      .green-option {
        margin-right: .75rem;
        width: 22px;

        svg {
          width: 100%;
        }
      }
    }
  }

  main {
    padding: .7rem 1rem;
  }

  .point-names {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    padding-right: 4rem;
  
    .alias {
      font-weight: 600;
      line-height: 1.2;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
    }
  
    .forniture-type {
      font-size: .75rem;
      margin-bottom: .15rem;
      font-weight: 600;
    }    
  }
  
  .supply-point-data {
    display: flex;

    .pdp-status,
    .address {
      font-size: .72rem;
      display: block;
    }

    .pdp-status {

      .status-label {
        display: block;
        margin-bottom: .25rem;
      }

      svg {
        font-size: 1rem;
        margin-right: .2rem;
        vertical-align: text-bottom;
      }
    }

    .offer-name {
      font-weight: 700;
      display: block;
      margin: .75rem 0;
      font-size: 1.5rem;
    }

    .code {
      font-weight: 700;
      font-size: .95rem;
      letter-spacing: 0;
    }
  }  

  .see-detail-cta {
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
    color: ${({ theme }) => theme.dataGroupTextColor};

    span {
      margin-right: .25rem;
      font-size: 1rem;
      line-height: 1.1;
      font-weight: 300;
    }
  }
`
