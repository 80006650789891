import { connect } from 'react-redux'
import { compose } from 'redux'

import { selectSelfMeterReadItemIsLoading } from '../../../redux/selfMeterRead/selfMeterRead.selectors'
import WithSkeleton from '../../WithSkeleton/WithSkeleton.component'
import SelfMeterReadItemData from './SelfMeterReadItemData.component'

const mapStateToProps = (state, ownProps) => ({
  isLoading: selectSelfMeterReadItemIsLoading(ownProps.customerCode, ownProps.number)(state)
})

const SelfMeterReadItemDataContainer = compose(
  connect(mapStateToProps),
  WithSkeleton
)(SelfMeterReadItemData)

export default SelfMeterReadItemDataContainer
