import React, { useState, useEffect } from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts'

import GasCharViewStyle from './style'
import { buildChartData } from './utils'
import CustomTooltip from '../CustomTooltip/CustomTooltip.component'

const GasChartView = ({ consumptionData }) => {
  //Chart width state
  const [ data, setData ] = useState([])
  useEffect(() => {
    setData(buildChartData(consumptionData))
  }, [consumptionData])

  return(
    <GasCharViewStyle>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Componente Variabile" fill="#009FF8" />
        </BarChart>
      </ResponsiveContainer>
    </GasCharViewStyle>
  )
}

export default GasChartView
