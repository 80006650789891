export const getPaymentStatusIcon = paymentStatus => {
  switch(parseInt(paymentStatus)) {
    case 2:
      return 'history'

    case 3:
      return 'alarm-exclamation'

    default:
      return 'check'
  }
}
