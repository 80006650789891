import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectSignUpEmail, selectIsSubmitting } from '../../../redux/user/user.selectors'
import { emailSignInStart } from '../../../redux/user/user.actions'
import { required } from './utils'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import ForgotPasswordModal from '../ForgotPasswordModal/ForgotPasswordModal.component'

const UserPasswordForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const signUpEmail = useSelector(selectSignUpEmail)

  const [ inputType, setInputType ] = useState('password')
  const togglePasswordVisibility = () => {
    if(inputType === 'password') {
      setInputType('text')
    }
    else {
      setInputType('password')
    }
  }

  const onSubmit = (values) => dispatch(emailSignInStart(values))

  return(
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="password"
            name="password"
            type={inputType}
            className="custom-form-field"
            validate={required}
            placeholder="Inserisci la password"
            focusOnMount={true}
          >
            <div className="mt-1">
              <Button color="link" className="show-pwd" onClick={togglePasswordVisibility}>
                {inputType === 'password' ? (
                  <>
                    <FontAwesomeIcon icon={['fas', 'eye']} />
                    <span>Mostra password</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={['fas', 'eye-slash']} />
                    <span>Nascondi password</span>
                  </>
                )}
              </Button>
            </div>
            <div className="mt-1">
              <ForgotPasswordModal />
            </div>
          </CustomFormField>
          <CustomFormField
            id="signUpEmail"
            name="email"
            type="hidden"
            defaultValue={signUpEmail}
          />
          <ButtonWithLoader
            type="submit"
            outline
            color="secondary"
            buttonLabel="Continua"
            onClick={handleSubmit}
            isFetching={isSubmitting}
            disabled={isSubmitting}
          />
        </form>
      )}
    </Form>
  )
}

export default UserPasswordForm
