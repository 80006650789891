const BillsActionTypes = {
  FETCH_BILLS_START: 'FETCH_BILLS_START',
  FETCH_BILLS_SUCCESS: 'FETCH_BILLS_SUCCESS',
  FETCH_BILLS_FAILURE: 'FETCH_BILLS_FAILURE',

  SET_BILLS_FILTERS: 'SET_BILLS_FILTERS',
  RESET_BILLS_FILTERS: 'RESET_BILLS_FILTERS',

  SET_BILLS_SORTING: 'SET_BILLS_SORTING',
  RESET_BILLS_SORTING: 'RESET_BILLS_SORTING',

  SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',

  FETCH_DOCUMENT_START: 'FETCH_DOCUMENT_START',
  FETCH_DOCUMENT_SUCCESS: 'FETCH_DOCUMENT_SUCCESS',
  FETCH_DOCUMENT_FAILURE: 'FETCH_DOCUMENT_FAILURE',

  TOGGLE_BILLS_UTILITY_MODAL_VISIBILITY: 'TOGGLE_BILLS_UTILITY_MODAL_VISIBILITY',
  TOGGLE_ATTACHMENT_MODAL_VISIBILITY: 'TOGGLE_ATTACHMENT_MODAL_VISIBILITY'
}

export default BillsActionTypes
