import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { 
  toggleDeletePdpToCadastralItemModal, deletePdpToCadastralItemAssociationStart 
} from "../../../../redux/cadastralData/cadastralData.actions"
import { 
  selectDeletePdpToCadastralItemModalVisibility, selectSelectedCadastralData, 
  selectSelectedPdpItemData, selectCadastralDataPdpLength, selectIsFetchingCadastralData 
} from "../../../../redux/cadastralData/cadastralData.selectors"
import ButtonWithLoader from "../../../ButtonWithLoader/ButtonWithLoader.component"
import { getAPIUrl } from "../../../../ABapi"

const DeletePdpAssociationModal = () => {
  const dispatch = useDispatch()
  const toggle = useCallback(() => dispatch(toggleDeletePdpToCadastralItemModal()), [dispatch])
  const isOpen = useSelector(selectDeletePdpToCadastralItemModalVisibility)
  const selectedCadastralData = useSelector(selectSelectedCadastralData)
  const selectedPdpItemData = useSelector(selectSelectedPdpItemData)
  const isDeletingPdp = useSelector(state => selectIsFetchingCadastralData('pdpToCadastralItem')(state))
  const pdpLength = useSelector(state => selectCadastralDataPdpLength(selectedCadastralData?.id)(state))

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={['fas', 'map-location-dot']} className="custom-icon" />
        Disassociazione di {selectedPdpItemData?.number}
      </ModalHeader>
      <ModalBody>
        <p className="mb-3">
          {pdpLength > 1 ? (
            <>
              Confermando, l'utenza verrà scollegata dai dati catastali correnti.<br />
              Potrai quindi associarla ad un altro dato catastale.
            </>
          ) : (
            <>
              Confermando, l'utenza verrà scollegata e i dati catastali  
              saranno eliminati.
            </>
          )}
        </p>
        <div className="justify-content-end d-flex mt-5">
          <Button
            className="ab-button outline mr-2"
            onClick={toggle}
          >
              Annulla
          </Button>
          <ButtonWithLoader 
            className="ab-button"
            buttonLabel="Conferma"
            defaultIcon={['fas', 'check']}
            isFetching={isDeletingPdp}
            onClick={() => dispatch(deletePdpToCadastralItemAssociationStart({
              apiUrl: `${getAPIUrl()}/cadastral-data/${selectedCadastralData?.id}/association/${selectedPdpItemData?.number}`
            }))}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeletePdpAssociationModal