import CadastralDataTypes from "./cadastralData.types";

export const resetSelectedCadastralData = () => ({
  type: CadastralDataTypes.RESET_SELECTED_CADASTRAL_DATA
})

export const toggleUtilityModalVisibility = () => ({
  type: CadastralDataTypes.TOGGLE_CADASTRAL_DATA_UTILITY_MODAL_VISIBILITY
})

export const toggleCadastralDataModal = () => ({
  type: CadastralDataTypes.TOGGLE_CADASTRAL_DATA_MODAL
})

export const toggleCadastralDataPdpListModal = () => ({
  type: CadastralDataTypes.TOGGLE_CADASTRAL_DATA_PDP_LIST_MODAL
})

export const toggleCadastralDataItemsListModal = data => ({ 
  type: CadastralDataTypes.TOGGLE_CADASTRAL_DATA_ITEMS_LIST_MODAL,
  payload: data
})

export const togglePdpShortcutModal = () => ({ 
  type: CadastralDataTypes.TOGGLE_PDP_SHORTCUT_MODAL
})

export const toggleDeletePdpToCadastralItemModal = data => ({
  type: CadastralDataTypes.TOGGLE_DELETE_PDP_TO_CADASTRAL_ITEM_MODAL,
  payload: data
})

export const fetchCadastralDataStart = () => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_START
})

export const fetchCadastralDataSuccess = data => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_SUCCESS,
  payload: data
})

export const fetchCadastralDataFailure = error => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_FAILURE,
  payload: error
})

export const fetchCadastralDataItemStart = data => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_START,
  payload: data
})

export const fetchCadastralDataItemSuccess = data => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_SUCCESS,
  payload: data
})

export const fetchCadastralDataItemFailure = error => ({
  type: CadastralDataTypes.FETCH_CADASTRAL_DATA_ITEM_FAILURE,
  payload: error
})

export const submitCadastralDataItemStart = data => ({
  type: CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_START,
  payload: data
})

export const submitCadastralDataItemSuccess = data => ({
  type: CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_SUCCESS,
  payload: data
})

export const submitCadastralDataItemFailure = error => ({
  type: CadastralDataTypes.SUBMIT_CADASTRAL_DATA_ITEM_FAILURE,
  payload: error
})

export const deleteCadastralDataItemStart = data => ({
  type: CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_START,
  payload: data
})

export const deleteCadastralDataItemSuccess = data => ({
  type: CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_SUCCESS,
  payload: data
})

export const deleteCadastralDataItemFailure = error => ({
  type: CadastralDataTypes.DELETE_CADASTRAL_DATA_ITEM_FAILURE,
  payload: error
})

export const submitCadastralItemPdpAssociationStart = data => ({ 
  type: CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_START,
  payload: data
})

export const submitCadastralItemPdpAssociationSuccess = data => ({ 
  type: CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_SUCCESS,
  payload: data
})

export const submitCadastralItemPdpAssociationFailure = error => ({ 
  type: CadastralDataTypes.SUBMIT_CADASTRAL_ITEM_PDP_ASSOCIATION_FAILURE,
  payload: error
})

export const fetchAvailablePdpStart = data => ({
  type: CadastralDataTypes.FETCH_AVAILABLE_PDP_START,
  payload: data
})

export const fetchAvailablePdpSuccess = data => ({
  type: CadastralDataTypes.FETCH_AVAILABLE_PDP_SUCCESS,
  payload: data
})

export const fetchAvailablePdpFailure = error => ({
  type: CadastralDataTypes.FETCH_AVAILABLE_PDP_FAILURE,
  payload: error
})

export const submitPdpToCadastralItemAssociationStart = data => ({
  type: CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START,
  payload: data
})

export const submitPdpToCadastralItemAssociationSuccess = data => ({
  type: CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS,
  payload: data
})

export const submitPdpToCadastralItemAssociationFailure = error => ({
  type: CadastralDataTypes.SUBMIT_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE,
  payload: error
})

export const addCadastralDataStart = data => ({
  type: CadastralDataTypes.ADD_CADASTRAL_DATA_START,
  payload: data
})

export const addCadastralDataSuccess = data => ({
  type: CadastralDataTypes.ADD_CADASTRAL_DATA_SUCCESS,
  payload: data
})

export const addCadastralDataFailure = error => ({
  type: CadastralDataTypes.ADD_CADASTRAL_DATA_FAILURE,
  payload: error
})

export const deletePdpToCadastralItemAssociationStart = data => ({
  type: CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_START,
  payload: data
})

export const deletePdpToCadastralItemAssociationSuccess = data => ({
  type: CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_SUCCESS,
  payload: data
})

export const deletePdpToCadastralItemAssociationFailure = error => ({
  type: CadastralDataTypes.DELETE_PDP_TO_CADASTRAL_ITEM_ASSOCIATION_FAILURE,
  payload: error
})