import styled from 'styled-components'

const LoginPageStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 1rem;
  background: ${({ theme }) => theme.loginPageBkg};

  .spinner-overlay .loading-icon {
    fill: #40d6d4 !important;
  }
`

export default LoginPageStyle