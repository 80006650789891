import React from 'react'

import { VerticalMenuStyle } from './style'
import BrandLogo from '../../BrandLogo/BrandLogo.component'
import NavigationMenu from '../NavigationMenu/NavigationMenu.component'

const VerticalMenu = () => (
  <VerticalMenuStyle className="vertical-menu">
    <BrandLogo />
    <NavigationMenu vertical />
  </VerticalMenuStyle>
)

export default VerticalMenu
