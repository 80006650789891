import { call, put, all, take, cancel, takeLatest, select } from 'redux-saga/effects'

import SelfMeterReadActionTypes from './selfMeterRead.types'
import { selectIdToken } from '../user/user.selectors'
import {
  fetchSelfMeterReadsSuccess, fetchSelfMeterReadsFailure,
  fetchSelfMeterReadItemSuccess, fetchSelfMeterReadItemFailure,
  submitSelfMeterReadSuccess, submitSelfMeterReadFailure, fetchSelfMeterReadItemStart
} from './selfMeterRead.actions'
import { getSelfMeterReadData, normalizeSelfMeterReads, sendSelfMeterRead, setupActiveSupplyPointsArray } from './selfMeterRead.utils'
import { selectCustomerCodesArray } from '../user/user.selectors'
import { getPdrSupplyPointsByClientCode } from '../supplyPoints/supplyPoints.utils'

export function* fetchSelfReadsByCustomerCodes() {
  try {
    const customerCodes = yield select(selectCustomerCodesArray)
    const idToken = yield select(selectIdToken)
    const supplyPoints = yield all(customerCodes.map(code => call(getPdrSupplyPointsByClientCode, code, idToken)))
    const customerCodeSelfMeters = yield call(normalizeSelfMeterReads, supplyPoints)
    yield put(fetchSelfMeterReadsSuccess(customerCodeSelfMeters))
    //Fetch every single self meter read item
    let supplyPointsArray = setupActiveSupplyPointsArray(customerCodeSelfMeters)
    yield all(supplyPointsArray.map(item => call(fetchSelfMeterReadItem, item)))
  }
  catch(error) {
    yield put(fetchSelfMeterReadsFailure(error))
  }
}

export function* onFetchSelfReadsStart() {
  while(true) {
    const task = yield takeLatest(
      SelfMeterReadActionTypes.FETCH_SELF_METER_READS_START,
      fetchSelfReadsByCustomerCodes
    )
    yield take(SelfMeterReadActionTypes.RESET_SELF_METER_READ_ITEMS)
    yield cancel(task)
  }
}

export function* fetchSelfMeterReadItem(selfMeterReadItem) {
  try {
    yield put(fetchSelfMeterReadItemStart(selfMeterReadItem))
    const { customerCode, number } = selfMeterReadItem
    const idToken = yield select(selectIdToken)
    const response = yield call(getSelfMeterReadData, number, idToken)
    if(response.error) throw response
    yield put(fetchSelfMeterReadItemSuccess({ customerCode, number, item: response.data }))
  }
  catch(error) {
    yield put(fetchSelfMeterReadItemFailure(error))
  }
}

export function* submitSelfMeterRead({ payload: { value, customerCode, number } }) {
  try {
    const idToken = yield select(selectIdToken)
    const response = yield call(sendSelfMeterRead, value, number, idToken)
    if(response.error) throw response
    yield put(submitSelfMeterReadSuccess({ customerCode, number, item: response.data }))
  }
  catch(error) {
    yield put(submitSelfMeterReadFailure(error))
  }
}

export function* onSubmitSelfMeterReadStart() {
  yield takeLatest(
    SelfMeterReadActionTypes.SUBMIT_SELF_METER_READ_START,
    submitSelfMeterRead
  )
}

export function* selfMeterReadSagas() {
  yield all([
    call(onFetchSelfReadsStart),
    call(onSubmitSelfMeterReadStart)
  ])
}
