import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectUtilityModal } from '../../../redux/bills/bills.selectors'
import { toggleUtilityModalVisibility } from '../../../redux/bills/bills.actions'

const BillsModal = ({ utilityModal: { isVisible, modalTitle, modalDescription, modalIcon }, toggle }) => {
  return(
    <Modal isOpen={isVisible} toggle={toggle} className="utility-modal">
      <ModalHeader toggle={toggle}>
        {modalIcon &&
          <FontAwesomeIcon size="lg" icon={modalIcon} className="mr-2" />
        }
        {modalTitle}
      </ModalHeader>
      <ModalBody>
        {modalDescription}
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  utilityModal: selectUtilityModal
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleUtilityModalVisibility())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillsModal)
