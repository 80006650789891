//!IMPORTANT This component presumes you're using react-final-form
import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input } from 'reactstrap'

import './style.scss'

const CustomSelectField = ({ children, ...props }) => (
  <FormGroup>
    <Field {...props}>
      {({ input, meta: { error, touched }, options, ...otherProps }) => (
        <>
          <Input invalid={error && touched ? true : false} {...input} {...otherProps}>
            <option value={null}>...</option>
            {options.map((opt, i) => (
              <option key={i} value={opt}>{opt}</option>
            ))}
          </Input>
        </>
      )}
    </Field>
    {children}
  </FormGroup>
)

export default CustomSelectField
