import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ScrollToSeeMoreStyle } from './style'

const ScrollToSeeMore = () => (
  <ScrollToSeeMoreStyle className="scroll-to-see-more">
    <span className="scroll-arrow">
      <FontAwesomeIcon icon={['fas', 'angle-right']} />
    </span>
  </ScrollToSeeMoreStyle>
)

export default ScrollToSeeMore
