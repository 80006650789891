import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { withRouter } from '../../components/AppRouter/utils'
import SupplyPointItemContainer from '../../components/customer-area/SupplyPointItem/SupplyPointItemContainer.component'
import { fetchSupplyPointDetailStart } from '../../redux/supplyPoints/supplyPoints.actions'

const SupplyPointDetailPage = ({ router: { params: { type, supplyPointId } } }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchSupplyPointDetailStart({ supplyPointId, type }))
  }, [supplyPointId, type, dispatch])

  return (
    <div className="supply-points-page">
      <SupplyPointItemContainer />
    </div>
  )
}

export default withRouter(SupplyPointDetailPage)
