import BillsActionTypes from './bills.types'

export const toggleUtilityModalVisibility = () => ({
  type: BillsActionTypes.TOGGLE_BILLS_UTILITY_MODAL_VISIBILITY
})

export const fetchBillsStart = (data = null) => ({
  type: BillsActionTypes.FETCH_BILLS_START,
  payload: data
})

export const fetchBillsSuccess = data => ({
  type: BillsActionTypes.FETCH_BILLS_SUCCESS,
  payload: data
})

export const fetchBillsFailure = error => ({
  type: BillsActionTypes.FETCH_BILLS_FAILURE,
  payload: error
})

export const setBillsFilters = data => ({
  type: BillsActionTypes.SET_BILLS_FILTERS,
  payload: data
})

export const resetBillsFilters = () => ({
  type: BillsActionTypes.RESET_BILLS_FILTERS
})

export const setBillsSorting = data => ({
  type: BillsActionTypes.SET_BILLS_SORTING,
  payload: data
})

export const resetBillsSorting = () => ({
  type: BillsActionTypes.RESET_BILLS_SORTING
})

export const setActivePage = activePage => ({
  type: BillsActionTypes.SET_ACTIVE_PAGE,
  payload: activePage
})

export const fetchDocumentStart = data => ({
  type: BillsActionTypes.FETCH_DOCUMENT_START,
  payload: data
})

export const fetchDocumentSuccess = data => ({
  type: BillsActionTypes.FETCH_DOCUMENT_SUCCESS,
  payload: data
})

export const fetchDocumentFailure = error => ({
  type: BillsActionTypes.FETCH_DOCUMENT_FAILURE,
  payload: error
})

export const toggleAttachmentModalVisibility = () => ({
  type: BillsActionTypes.TOGGLE_ATTACHMENT_MODAL_VISIBILITY
})
