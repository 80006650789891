import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { selectSupplyPointItemDetail } from '../../../redux/supplyPoints/supplyPoints.selectors'
import { SupplyPointItemStyle } from './style'
import ElectricalOfferDetails from '../ElectricalOfferDetails/ElectricalOfferDetails.component'
import GasOfferDetails from '../GasOfferDetails/GasOfferDetails.component'
import EnergyChartView from '../EnergyChartView/EnergyChartView.component'
import GasChartView from '../GasChartView/GasChartView.component'
import EditAliasModal from '../EditAliasModal/EditAliasModal.component'
import { ReactComponent as Bolt } from '../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../assets/svg/flame.svg'
import { ReactComponent as GreenOption } from '../../../assets/svg/penso-verde.svg'

const SupplyPointItem = () => {
  const supplyItemDetail = useSelector(selectSupplyPointItemDetail)
  const {
    alias, serviceType, greenOption, offerName, activationDate, consumptions,
    delivery: { address, city }, cadastralData
  } = supplyItemDetail

  return (
    <SupplyPointItemStyle>
      <NavLink to="/punti-fornitura" className="back-btn">
        <span className="link-icon">
          <FontAwesomeIcon icon={['fas', 'long-arrow-left']} />
        </span>
        <span className="link-text">Forniture</span>
      </NavLink>
      <header>
        <div className="title-container">
          {serviceType === 1 ?
            <Bolt className="custom-icon" />
            :
            <Flame className="custom-icon" />
          }
          <h1 className="alias">
            <span>{alias}</span>
            <EditAliasModal item={supplyItemDetail} />
          </h1>
        </div>
        {greenOption &&
          <div className="green-option" title="Fornitura green">
            {serviceType === 1 ?
              <span>La tua energia è green</span>
              :
              <span>Il tuo gas è green</span>
            }
            <GreenOption className="custom-icon" />
          </div>
        }
      </header>
      <main>
        <Row>
          <Col sm={6}>
            <div className={`data-box ${serviceType === 1 ? 'electrical' : 'gas'}`}>
              <h3 className={`box-title ${serviceType === 1 ? 'electrical' : 'gas'}`}>
                Dati offerta
              </h3>
              <div className="supply-point-data">
                <div className="data-group">
                  <label>Tipologia</label>
                  <span className="code">{`Offerta ${serviceType === 1 ? 'energia' : 'gas'}`}</span>
                </div>
              </div>
              <div className="supply-point-data">
                <div className="data-group">
                  <label>Nome</label>
                  <span className="code">{offerName}</span>
                </div>
              </div>
              <div className="supply-point-data">
                <div className="data-group">
                  <label>Data attivazione</label>
                  <span className="code">{moment(activationDate).format("DD/MM/YYYY")}</span>
                </div>
              </div>
              <div className="supply-point-data">
                <div className="data-group">
                  <label>Indirizzo</label>
                  <span className="code">
                    {city} - {address}
                  </span>
                </div>
              </div>
              {cadastralData ? (
                <div className="supply-point-data">
                  <div className="data-group">
                    <label>
                      Dati catastali comunicati il {moment(cadastralData.insertDate).format("DD/MM/YYYY")}
                    </label>
                  </div>
                  <div>
                    <NavLink to="/dati-catastali" className="ab-button cadastral-btn">
                      <span className="link-text">Vai ai dati catastali</span>
                    </NavLink>
                  </div>
                </div>
              ) : (
                <div className="supply-point-data">
                  <div className="data-group">
                    <label>
                      <FontAwesomeIcon icon={['fas', 'warning']} className="mr-1" /> 
                      Dati catastali mancanti
                    </label>
                    <span className="code">
                      Non hai ancora comunicato i dati catastali per questa utenza.
                    </span>
                  </div>
                  <div>
                    <NavLink to="/dati-catastali" className="ab-button cadastral-btn">
                      <span className="link-text">Aggiungi dati catastali</span>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col sm={6}>
            {serviceType === 1 ?
              <ElectricalOfferDetails {...supplyItemDetail} />
              :
              <GasOfferDetails {...supplyItemDetail} />
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="chart-title">
              Grafico consumi annuali
            </h3>
            {serviceType === 1 ?
              <EnergyChartView consumptionData={consumptions} />
            :
              <GasChartView consumptionData={consumptions} />
            }
          </Col>
        </Row>
      </main>
    </SupplyPointItemStyle>
  )
}

export default SupplyPointItem
