import React from 'react'
import { useSelector } from 'react-redux'

import { selectSignUpEmail } from '../../../redux/user/user.selectors'
import LoginStep from '../../LoginStep/LoginStep.component'
import UserPasswordForm from '../UserPasswordForm/UserPasswordForm.component'

const UserPasswordStep = () => {
  const signUpEmail = useSelector(selectSignUpEmail)

  return (
    <LoginStep>
      <h1 className="step-title password-title flex-wrap">
        <span>Bentornato,</span>&nbsp;
        <span className="signup-email">{signUpEmail}</span>
      </h1>
      <UserPasswordForm />
    </LoginStep>
  )
}

export default UserPasswordStep
