import styled from 'styled-components'

const LoginStepStyle = styled.div`
  width: 400px;
  position: static;
  border-radius: 5px;
  overflow: hidden;
  color: #666;
  box-shadow: 0 0 15px -5px #333;
  transform: translate(0, 0);
  transform-origin: 50% 50%;

  &.step-content-enter {
    opacity: 0.01;
    transform: scale(0.1) translate(0, 0);
  }

  &.step-content-enter-active {
    opacity: 1;
    transform: scale(1) translate(0, 0);
    transition: all 800ms cubic-bezier(.51,.92,.24,1.15);
  }

  &.step-content-exit {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }

  &.step-content-exit-active {
    opacity: 0;
    position: absolute;
    transform: translate(-110%, 0);
    transition: all 300ms ease;
  }

  header {
    background-color: #0065a7;
    padding: .75rem 1rem;
    display: flex;
    justify-content: space-between;
  }

  .ab-logo {
    width: 120px;
    cursor: pointer;
  }

  .close-btn {
    color: #fff;
    font-size: 1.25rem;
    transition: transform .4s ease, color .2s ease;

    &:hover {
      color: $lightGrey;
      transform: scale(1.2);
    }
  }

  main {
    padding: 3.5rem;
    background-color: #fff;
    position: relative;
  }

  .step-title {
    font-weight: 700;
    font-size: 1.15rem;
    color: #0065a7;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
      font-weight: 700;
    }
  }

  .step-icon {
    font-size: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    color: rgba(51, 122, 183, 0.7);
  }

  .form-description {
    font-size: .9rem;
    color: #666;
    font-weight: 600;

    a {
      font-weight: 600;
      text-decoration: underline;
      color: #0065a7;
    }
  }

  .form-group {
    label {
      display: none;
    }
  }

  .show-pwd {
    padding: 0;
    font-size: .75rem;
    display: flex;
    align-items: center;
    color: #0065a7;

    > svg {
      margin-right: .25rem;
    }
  }

  .signup-email {
    color: #666;
  }

  .info-trigger {
    width: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 2s ease, transform .4s ease-out;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem 1.5rem;
    z-index: 1;

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  .back-btn {
    border-color: rgba(51, 122, 183, .14);
    color: #337ab7;
    margin: auto;
    display: block;
    margin-bottom: 1rem;

    &:hover,
    &:focus {
      background: rgb(1,100,167);
      border-color: #f1f1f1;
      color: white;
    }

    > svg {
      margin-right: .5rem;
    }
  }

  .login-form {
    font-size: .85rem;

    ::placeholder {
      color: #999;
      opacity: .8;
      font-size: .85rem;
    }

    button[type="submit"] {
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      width: 100%;

      svg {
        margin-right: .5rem;
        font-size: 1.25rem;
      }
    }
  }

  .buttons-list {
    button {
      display: block;
      width: 100%;
      margin: .5rem 0;
      font-size: .85rem;
      font-weight: 600;
      border-radius: 25px;
      padding: .75rem 1.25rem;
      border: none;

      &:hover,
      &:focus {
        background-color: #5a6268;
        color: #fff;
      }

      svg {
        margin-right: .5rem
      }
    }
  }
`

export default LoginStepStyle