import confJson from './configuration.json'
import { errorMessages } from './utils'

//The API url memoized
const getRootUrl = () => {
  let cache = {}
  return (key) => {
    if (key in cache) {
      return cache[key]
    }
    else {
      const { endPoints, env } = confJson
      const { protocol, host, port, prefix, version } = endPoints[env]

      let apiUrl = protocol + '://' + host
      apiUrl = port ? `${apiUrl}:${port}` : apiUrl
      apiUrl = prefix ? `${apiUrl}/${prefix}` : apiUrl
      apiUrl = version ? `${apiUrl}/v${version}` : apiUrl
      cache[key] = apiUrl
      return apiUrl
    }
  }
}

const rootUrl = getRootUrl()
export const getAPIUrl = () => {
  const apiUrl = rootUrl()
  return apiUrl
}

export function getAPIVersion() {
  const { endPoints, env } = confJson
  const { version } = endPoints[env]
  return version
}


export function fetchData(fetchUrl, accessToken, includeBearer = true) {
  const fetchPromise = fetch(
    fetchUrl,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': includeBearer ? `Bearer ${accessToken}` : accessToken,
        'api-version': getAPIVersion()
      }
    }
  )
  .catch(error => {
    //Fetch error (network down or similar...no response given)
    throw new Error(errorMessages.INTERNET_DISCONNECTED)
  })
  .then(response => handleErrors(response, fetchUrl))
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })

  return fetchPromise
}

export function submitRequest(fetchUrl, accessToken, data = null, method = 'POST', includeBearer = true) {
  const fetchPromise = fetch(
    fetchUrl,
    {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': includeBearer ? `Bearer ${accessToken}` : accessToken,
        'api-version': getAPIVersion()
      },
      body: JSON.stringify(data)
    })
    .then(response => handleErrors(response, fetchUrl))
    .then(response => {
      const contentType = response.headers.get("content-type")
      if(contentType && contentType.includes("application/json")) {
        return response.json()
      }
      return response
    })

  return fetchPromise
}

export function handleErrors(response, fetchUrl) {
  const contentType = response.headers.get("content-type")
  if (!response.ok) {
    if(contentType && contentType.includes("application/json")) {
      const json = response.json()
      return json.then(err => {
        throw new Error(err.error?.message) || errorMessages.DEFAULT_ERROR
      })
    }
    const errorMessage = parseSystemError(response.status)
    throw new Error(`${errorMessage} - ${fetchUrl}`)
  }

  return response
}

export function parseSystemError(errorCode) {
  switch(errorCode) {
    case 404:
      return errorMessages.NOT_FOUND_ERROR

    case 401:
      return errorMessages.NOT_AUTHORIZED_ERROR

    case 500:
      return errorMessages.SERVER_ERROR

    case 503:
      return errorMessages.SERVER_UNVAILABLE

    case 504:
      return errorMessages.SERVER_TIMEOUT

    default:
      return errorMessages.DEFAULT_ERROR
  }
}

export const fetchTowns = accessToken => searchedValue => {
  if(searchedValue.length >= 2) {
    const fetchPromise = fetch(
      `${getAPIUrl()}/form-items/towns/small-info?townName=${searchedValue}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then(response => {
      const contentType = response.headers.get("content-type")
      if(contentType && contentType.includes("application/json")) {
        return response.json()
      }
      return response
    })
    .then(({ data }) => data.map(item => item))
    .catch(error => { throw new Error(error) })

    return fetchPromise
  }
}