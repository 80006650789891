//!IMPORTANT This component presumes you're using react-final-form
import React, { useEffect, useRef } from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'

const CustomFormField = ({ children, focusOnMount, ...props }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if(focusOnMount) inputRef.current.focus()
  }, [focusOnMount, inputRef])

  return (
    <FormGroup className={classnames({'m-0': props.type === 'hidden'})}>
      <Field {...props}>
        {({ label, input, meta: { error, touched, submitFailed }, ...otherProps }) => (
          <>
            {label &&
              <Label for={input.name}>{label}</Label>
            }
            <Input 
              innerRef={inputRef} 
              invalid={error && touched && submitFailed ? true : false} 
              {...input} 
              {...otherProps} 
            />
            <FormFeedback>{error}</FormFeedback>
          </>
        )}
      </Field>
      {children}
    </FormGroup>
  )
}

export default CustomFormField
