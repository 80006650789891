import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CadastralDataOverviewContainer from '../../components/customer-area/CadastralData/CadastralDataOverviewContainer.component'
import { fetchCadastralDataStart } from '../../redux/cadastralData/cadastralData.actions'

const CadastralDataPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCadastralDataStart())
  }, [dispatch])

  return (
    <CadastralDataOverviewContainer />
  )
}

export default CadastralDataPage