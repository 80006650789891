import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { Form } from 'react-final-form'

import CadastralDataPdpFormStyle from './style'
import { ReactComponent as SupplyPoints } from '../../../../assets/svg/supplies.svg'
import { ReactComponent as Bolt } from '../../../../assets/svg/bolt.svg'
import { ReactComponent as Flame } from '../../../../assets/svg/flame.svg'
import ButtonWithLoader from '../../../ButtonWithLoader/ButtonWithLoader.component'
import ExtendedSelectField from '../../../ExtendedSelectField/ExtendedSelectField.component'
import { getAPIUrl } from '../../../../ABapi'
import { 
  selectCadastralPdpListModalVisibility, selectIsSubmittingCadastralItemForm,
  selectAvailablePdpItems, selectSelectedCadastralData
} from '../../../../redux/cadastralData/cadastralData.selectors'
import { submitCadastralItemPdpAssociationStart, toggleCadastralDataPdpListModal,  } from '../../../../redux/cadastralData/cadastralData.actions'
import { requiredArray } from './utils'

const CadastralDataPdpForm = () => {
  const dispatch = useDispatch()
  const toggle = useCallback(() => dispatch(toggleCadastralDataPdpListModal()), [dispatch])
  const isOpen = useSelector(selectCadastralPdpListModalVisibility)
  const selectedCadastralData = useSelector(selectSelectedCadastralData)
  const isSubmitting = useSelector(selectIsSubmittingCadastralItemForm('cadastralDataPdpAssociation'))
  const availablePdpItems = useSelector(selectAvailablePdpItems)

  const onSubmit = values => {
    const apiUrl = `${getAPIUrl()}/cadastral-data/${selectedCadastralData.id}/association`
    dispatch(submitCadastralItemPdpAssociationStart({ apiUrl, values }))
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static" keyboard={false}>
      <CadastralDataPdpFormStyle>
        <ModalHeader toggle={toggle}>
          <SupplyPoints className="custom-icon" />
          Associa utenze
        </ModalHeader>
        <ModalBody>
          <p className="mb-3">
            Associa i punti di fornitura a questo dato catastale selezionandoli dall'elenco qui sotto.
          </p>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="cadastral-data-form">
                <div className="grouped-form">
                  <Row form>
                    <Col md={12}>
                      <ExtendedSelectField 
                        name="supplyPoints"
                        validate={requiredArray}
                        placeholder="Digita i primi caratteri del nome o il numero del punto di fornitura"
                        options={availablePdpItems || []}
                        isMulti
                        getOptionValue={(option) => `${option['alias']}: ${option['number']}`}
                        getOptionLabel={({ supplyType, ...option }) =>
                          <>
                            <span className="mr-2">{supplyType.label === 'Energia' ? <Bolt /> : <Flame />}</span>
                            <strong>{option.alias}</strong>: {option.number}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </div>
                <ButtonWithLoader
                  type="submit"
                  outline
                  color="secondary"
                  onClick={handleSubmit}
                  isFetching={isSubmitting}
                  disabled={isSubmitting}
                  buttonLabel="Continua"
                  className="ab-button"
                />
              </form>
            )}
          </Form>
        </ModalBody>
      </CadastralDataPdpFormStyle>
    </Modal>  
  )
}

export default CadastralDataPdpForm