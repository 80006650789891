import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'
import { useNavigate } from 'react-router-dom'

const PortalRedirectButton = ({ name, to = "/" }) => {
  const navigate = useNavigate()
  const isExternal = to.startsWith('http') || to.startsWith('www') || to.startsWith('https')

  const handleClick = () => {
    if (isExternal) {
      window.location.href = to
    } else {
      navigate(to)
    }
  }

  return (
    <>
      {isExternal ? (
        <a href={to} target="_blank" rel="noopener noreferrer">
          <Button color="primary" className="newportal-btn">
            <FontAwesomeIcon icon={['fab', 'arrow-up-right-from-square']} />
            {name}
          </Button>
        </a>
      ) : (
        <Button color="secondary" onClick={handleClick}>
          <FontAwesomeIcon icon={['fab', 'arrow-up-right-from-square']} />
          {name}
        </Button>
      )}
    </>
  )
}

export default PortalRedirectButton
