import styled from "styled-components"
import Select from "react-select"

const StyledExtendedSelectField = styled(Select)`
  .react-select__placeholder {
    color: ${({ theme }) => theme.inputColor};
    opacity: .3;
    font-weight: 400;
    font-size: .85rem;
  }

  .react-select__control {
    background-color: transparent;
    padding: .28rem;
    color: ${({ theme }) => theme.inputColor};
    border-color: ${({ theme }) => theme.inputBorderColor};
    box-shadow: none;

    &:hover {
      border-color: ${({ theme }) => theme.hoverInputBorderColor};
    }
  }

  .react-select__input {
    color: ${({ theme }) => theme.inputColor} !important;
    font-weight: normal !important;
    font-size: .9rem !important;
  }

  .react-select__menu-list {
    background-color: ${({ theme }) => theme.itemBackgroundColor};
  }

  .react-select__indicator-separator {
    background-color: ${({ theme }) => theme.inputBorderColor};
  }

  .react-select__dropdown-indicator {
    color: ${({ theme }) => theme.inputBorderColor};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.inputColor};
    font-size: .9rem;
    font-weight: 500;
  }

  .react-select__option {
    font-size: .85rem;
    font-weight: 400;

    &.react-select__option--is-focused {
      background-color: ${({ theme }) => theme.focusedOption};
    }

    &.react-select__option--is-selected {
      background-color: ${({ theme }) => theme.selectedOption};
    }

    svg {
      fill: ${({ theme }) => theme.inputColor};
    }
  }

  .react-select__menu-notice {
    font-weight: 400;
    font-size: .85rem;
  }
`

export default StyledExtendedSelectField