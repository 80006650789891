import { getAPIUrl, fetchData, submitRequest } from '../../ABapi'

export function convertSupplyPointsToMap(supplyPointsArray) {
  const transformedSupplyPoints = supplyPointsArray.reduce((accumulator, supplyPoint) => {
    const customerCode = supplyPoint && supplyPoint.length ? supplyPoint[0].customerCode : null
    if(customerCode) {
      supplyPoint = supplyPoint.map(item => ({ ...item, toFetch: true }))
      accumulator[customerCode.toLowerCase()] = supplyPoint
    }
    return accumulator
  }, {})
  return transformedSupplyPoints
}

export function* getPodSupplyPointsByClientCode(customerCode, idToken) {
  const { data } = yield getSupplyPointsByClientCode(customerCode, 'pod', idToken)
  const updated = data.map(item => {
    item.serviceType = 1
    return item
  })
  return updated
}

export function* getPdrSupplyPointsByClientCode(customerCode, idToken) {
  const { data } = yield getSupplyPointsByClientCode(customerCode, 'pdr', idToken)
  return data ? data.map(item => {
    item.serviceType = 2
    return item
  }) : []
}

export function getSupplyPointsByClientCode(customerCode, type, idToken) {
  return fetchData(`${getAPIUrl()}/customers/${customerCode}/${type}`, idToken)
    .then(response => response)
}

export function getSupplyPointDetail(supplyPointId, type, idToken) {
  const supplyPointType = type === 'electrical' ? 'pod' : 'pdr'
  return fetchData(`${getAPIUrl()}/${supplyPointType}/${supplyPointId}`, idToken)
    .then(response => response)
}

export function getSupplyPointCunsumptions(supplyPointId, type, idToken) {
  const supplyPointType = type === 'electrical' ? 'pod' : 'pdr'
  return fetchData(`${getAPIUrl()}/${supplyPointType}/${supplyPointId}/consumptions`, idToken)
    .then(response => response)
}

export function getSupplyPointCadastralData(supplyPointId, type, idToken) {
  const supplyPointType = type === 'electrical' ? 'pod' : 'pdr'
  return fetchData(`${getAPIUrl()}/${supplyPointType}/${supplyPointId}/cadastral-data`, idToken)
    .then(response => response)
}

export function updateAlias(alias, type, number, idToken) {
  const supplyPointType = type === 1 ? 'pod' : 'pdr'
  return submitRequest(`${getAPIUrl()}/${supplyPointType}/${number}`, idToken, { alias }, 'PATCH')
    .then(response => response)
}
