const UserLoginSteps = {
  SIGN_IN_STEP: 1,
  USER_DATA_STEP: 2,
  USER_CLIENT_CODE: 3,
  OTP_TYPE_STEP: 4,
  USER_OTP_SIGN_STEP: 5,
  USER_PASSWORD_STEP: 6
}

export default UserLoginSteps
