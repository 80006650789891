import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { NavItem, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NavigationMenuStyle } from './style'
import { signOutStart } from '../../../redux/user/user.actions'
import { ReactComponent as SupplyPoints } from '../../../assets/svg/supplies.svg'

const NavigationMenu = ({ vertical }) => {
  const dispatch = useDispatch()

  return (
    <NavigationMenuStyle vertical={vertical} className="navigation-menu">
      <NavItem>
        <NavLink to="/punti-fornitura">
          <span className="link-icon supplies">
            <SupplyPoints className="custom-icon" />
          </span>
          <span className="link-text">Forniture</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/bollette">
          <span className="link-icon">
            <FontAwesomeIcon icon={['fas', 'receipt']} />
          </span>
          <span className="link-text">Bollette</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/autoletture">
          <span className="link-icon">
            <FontAwesomeIcon icon={['fas', 'watch-calculator']} />
          </span>
          <span className="link-text">Autolettura</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/dati-catastali">
          <span className="link-icon">
            <FontAwesomeIcon icon={['fas', 'map-location-dot']} />
          </span>
          <span className="link-text">Dati catastali</span>
        </NavLink>
      </NavItem>
      <NavItem className="sign-out" onClick={e => e.stopImmediatePropagation()}>
        <Button
          color="link"
          title="Sign out"
          onClick={() => dispatch(signOutStart())}
        >
          <span className="link-icon">
            <FontAwesomeIcon icon={['fas', 'sign-out']}  />
          </span>
          <span className="link-text">Log out</span>
        </Button>
      </NavItem>
    </NavigationMenuStyle>
  )
}

export default NavigationMenu
