import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import AttachmentModalBody from './AttachmentModalBody.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import { selectAttachment, selectIsAttachmentLoading } from '../../redux/bills/bills.selectors'

const mapStateToProps = createStructuredSelector({
  attachment: selectAttachment,
  isLoading: selectIsAttachmentLoading
})

const AttachmentModalBodyContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(AttachmentModalBody)

export default AttachmentModalBodyContainer
