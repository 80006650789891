import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import { Form } from 'react-final-form'

import { selectCurrentUser, selectIsSubmitting } from '../../../redux/user/user.selectors'
import { updateUserProfileStart } from '../../../redux/user/user.actions'
import CustomFormField from '../../CustomFormField/CustomFormField.component'
import DropzoneInput from '../../DropzoneInput/DropzoneInput.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'

const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

const UserProfileModalForm = () => {
  const dispatch = useDispatch()
  const { name, surname, displayName } = useSelector(selectCurrentUser)
  const isSubmitting = useSelector(selectIsSubmitting)
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const onSubmit = (values) => {
    dispatch(updateUserProfileStart(values))
    toggle()
  }

  return (
    <>
      <ButtonWithLoader
        otherClasses="ab-button inverted"
        buttonLabel="Modifica"
        onClick={toggle}
        isFetching={isSubmitting}
        disabled={isSubmitting}
        defaultIcon={['fas', 'user-edit']}
      />
      <Modal size="lg" isOpen={isOpen} toggle={toggle} className="self-meter-read-modal-form">
        <ModalHeader toggle={toggle}>
          Modifica profilo utente
        </ModalHeader>
        <ModalBody>
          <Form mutators={{updateFieldValue}} onSubmit={onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values, submitError }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="update-user-data-form">
                <Row>
                  <Col lg={6}>
                    <CustomFormField
                      label="Nome"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Nome"
                      defaultValue={name}
                    />
                  </Col>
                  <Col lg={6}>
                    <CustomFormField
                      label="Cognome"
                      id="surname"
                      name="surname"
                      type="text"
                      placeholder="Cognome"
                      defaultValue={surname}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <CustomFormField
                      label="Username"
                      id="displayName"
                      name="displayName"
                      type="text"
                      placeholder="Username"
                      defaultValue={displayName}
                    />
                  </Col>
                  {/*<Col lg={6}>
                    <CustomFormField
                      label="Telefono"
                      id="mobileNumber"
                      name="mobileNumber"
                      type="text"
                      placeholder="Telefono"
                      defaultValue={mobileNumber}
                      validate={phoneNumberCheck}
                    />
                  </Col>*/}
                </Row>
                <Row>
                  <Col>
                    <DropzoneInput
                      name="photo"
                      updateFieldValue={form.mutators.updateFieldValue}
                    />
                    <CustomFormField
                      type="hidden"
                      name="fileName"
                    />
                  </Col>
                </Row>
                <ButtonWithLoader
                  type="submit"
                  otherClasses="ab-button"
                  buttonLabel="Salva"
                  onClick={handleSubmit}
                  isFetching={isSubmitting}
                  disabled={isSubmitting}
                />
              </form>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UserProfileModalForm
